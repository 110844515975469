import React, { useEffect, useMemo, useState } from 'react';
import { ThirdTitle, Title} from '@dspworkplace/ui';
import {Table, TableData, TableHeader, TableRow} from "../../../components/Table";
import Modal from "../../../components/Modal";
import {dialogPromise} from "../../../components/Dialog";
import Loading from "../../../components/Loading";
import {CubeProvider, useCubeQuery} from "@cubejs-client/react";
import cubeApi from "../../../api/cube";
import moment from "moment";
import {fixWeekCondition} from '../fixWeekCondition';
import Chart, {Bar, Line, Pie} from '../../../components/Charts'
import withCubeData from './withCubeData';
import WithDragAndDrop from '../../../components/dragHandler';
import styled from 'styled-components';
import DashboardItemFilter from './dashboardItemFilter';
import DashboardScorecardFilter from './dashboardScorecardFilter';


const DATE_FORMATS_BY_GRANULARITY = {
    minute: 'mm',
    hour: 'HH:mm a',
    day: 'MMMM D, YYYY',
    week: 'GG YYYY',
    month: 'MMMM YYYY',
    quarter: 'Qo YYYY',
    year: 'YYYY'
};
const tierFields = ['AMZWeekly.tier', 'CustomerDeliveryFeedback.tier'];
const empFields = ['Employee.actual_schedule_hours', 'Employee.actual_work_hours', 'Employee.total_overtime_scheduled', 
                    'Employee.total_overtime_worked', 'Employee.report_days_aci', 'Employee.total_day_in_company',
                    'Employee.send_home_aci', 'Employee.total_days_worked', 'Employee.total_scheduled_days'];
const tierRankFields = ['AMZWeekly.average_ranking'];
const getXValue = (xValues) => {
    const xValue = (xValues || []).map(item => {
        let tx = item || '∅'
        if (item && (typeof item !== 'number')) {
            if (new Date(item) != 'Invalid Date') {
                tx = moment(item).format("MM/DD/YY")
            }
        }
        return tx;
    })
    return xValue
}

const getColor = (yValues, header, isPieChart, xValues) => {
    const yValue = yValues.reduce((acc, y, k) => {
        acc += header[y] ? (isPieChart ? '' : header[y]?.title) : y;
        if (k < yValues.length - 1) {
            acc += ', ';
        }
        return acc;
    }, '')
    let str = yValue?.replaceAll?.('null', '∅')?.split(', ').filter(item => item?.trim() !== '').join(', ')
    if (isPieChart) {
        str = str + ', ' + xValues.join(', ')
    }
    return str
}

const stackedChartData = (resultSet, header, type) => {

    function checkFields(fields) {
        return fields.some(field => header[field]);
    }

    const renderData = resultSet.pivot()
    const data = renderData
        .map(({ xValues, yValuesArray }) =>
            yValuesArray.map(([yValues, m]) => ({
                x: resultSet.axisValuesString(getXValue([...xValues]), ', '),
                color: getColor(yValues, header, type === 'pie', getXValue([...xValues])),
                measure: (checkFields(tierFields) || checkFields(empFields) || checkFields(tierRankFields))
                    ? m
                    : (m && Number.parseFloat(m)),
            }))
        )
        .reduce((a, b) => a.concat(b), []);
    return data;
};

const ChartRender = ({
    tabIndex, setCacheQuery, cacheQuery, chartConfigData,thresholds,
    resultSet, updatePivotConfig, pivotConfig, query, setColumnOrder,
    forceUpdate = true, attendanceSetting, item, columnOrder,
    availableTimeDimensions, updateQuery, isDashboardFilter, showTimeDimension, isHeaderFilter
}) => {

    const { type, format } = chartConfigData;
    const { timeDimensions, measures } = resultSet?.loadResponse?.pivotQuery;
    let colorIndex = 0;
    
    const isTimeDimensionSelectedAndPieChart = timeDimensions &&
        Array.isArray(timeDimensions) && timeDimensions.length > 0 &&
        type === 'pie';

    const updatedAvailableTimeDimensions = () => {
        return (availableTimeDimensions || []).filter((t) => {
            if (t?.name?.includes(chartConfigData?.schema + '.')) return true
            else return false
        });
    }

    const header = resultSet.tableColumns(pivotConfig)
    const normalizedHeader = header.reduce((acc,h)=>{
        acc[h.key] = {title: h.shortTitle, type: h.type};
        return acc
    },{})

    const data = useMemo(() => {

        function generateRandomLightColor() {
            const colorArray = [
                `rgb(99,149,250)`, `rgb(207,223,253)`, `rgb(102,217,171)`, `rgb(208,244,230)`, `rgb(101,119,152)`,
                `rgb(209,214,224)`, `rgb(247,193,45)`, `rgb(251,236,189)`, `rgb(118,102,251)`, `rgb(213,209,252)`,
                `rgb(118,203,237)`, `rgb(212,239,250)`, `rgb(154,104,189)`, `rgb(223,210,235)`, `rgb(255,157,80)`,
                `rgb(254,226,202)`, `rgb(48,154,153)`, `rgb(191,224,223)`, `rgb(255,158,198)`, `rgb(255,226,238)`,
                `rgb(131,157,186)`, `rgb(1,189,164)`, `rgb(1,113,188)`, `rgb(124,152,182)`, `rgb(247,147,29)`
            ]
            const red = Math.floor(Math.random() * 128 + 128);
            const green = Math.floor(Math.random() * 128 + 128);
            const blue = Math.floor(Math.random() * 128 + 128);
            return colorIndex < 27 ? colorArray[colorIndex] : `rgb(${red},${green},${blue})`
        }
        const stackedData = stackedChartData(resultSet, normalizedHeader, type);

        const uniqueColors = [...new Set(stackedData.map(item => item.color))];
        const colorCodes = {};
        uniqueColors.forEach(color => {
            colorCodes[color] = generateRandomLightColor();
            colorIndex = colorIndex + 1;
        });
        (stackedData || []).forEach(e => {
            let matchingThreshold = thresholds?.find((item) => e.color.includes(item.shortTitle));
            if (!matchingThreshold) {
                const measuresList = item?.chartQuery?.measures || measures || [];
                matchingThreshold = thresholds?.find((item) => measuresList.includes(item.name));
            }
            if (matchingThreshold?.shortTitle) {
                const value = e?.measure;
                for (const threshold of matchingThreshold?.thresholdsSettings) {
                    const minValue = parseFloat(threshold.min);
                    const maxValue = parseFloat(threshold.max);
                    if (value >= minValue && value <= maxValue) {
                        e.colorCode = threshold.color
                    } else if (!e.colorCode) {
                        e.colorCode = colorCodes[e.color]
                    }
                }
            } else {
                e.colorCode = colorCodes[e.color];
            }
        });
        return stackedData;
    }, [resultSet, normalizedHeader, type, thresholds])

    if(!type){
        return <Title>No Widget type selected</Title>
    }

    if(type === 'number'){
        const data = resultSet.seriesNames().map((s) => (
            {key: s.key, value: resultSet.totalRow()[s.key]}
        ))
        return <NumberWidget data={data} header={normalizedHeader} labels={chartConfigData?.labels} />
    }

    if (type === 'table') {

        const data = resultSet.tablePivot(pivotConfig);

        return <TableRender
            setCacheQuery={setCacheQuery}
            cacheQuery={cacheQuery}
            tabIndex={tabIndex}
            pivotConfig={pivotConfig}
            updatePivotConfig={updatePivotConfig}
            data={data}
            header={header}
            format={format}
            labels={chartConfigData?.labels}
            attendanceSetting={attendanceSetting}
            item={item}
            chartConfigData={chartConfigData}
            columnOrders={columnOrder}
            setColumnOrder={setColumnOrder}
            updateQuery={updateQuery}
            resultSet={resultSet}
            query={query}
            isDashboardFilter={isDashboardFilter}
            availableTimeDimensions={updatedAvailableTimeDimensions()}
            showTimeDimension={showTimeDimension}
            isHeaderFilter={isHeaderFilter}
            thresholds={thresholds}
        />
    }

    const widget =  solveChartRender(query, isTimeDimensionSelectedAndPieChart, data, item?.title)[type]

    if(!data.length) return <ThirdTitle>No Data Found</ThirdTitle>

    return (
        <Chart
            data={data}
            forceUpdate={forceUpdate}
            thresholds={thresholds}
        >
            {widget && widget(normalizedHeader)}
        </Chart>
    )
}


const solveTableHeader = (header,keys,width,labels) => {
    let normalizedHeader = {};
    if(header){
        normalizedHeader = header.reduce((acc,h)=>{
            acc[h.key] = {title: h.shortTitle};
            return acc
        },{})
    }
    const isObjectWidth = Object.prototype.toString.call(width) === '[object Object]' ? true : false
    return keys.map((l) => (
        { label: labels && labels[l] ? labels[l] : (normalizedHeader[l]?.title || l), width: isObjectWidth ? `${width[l] || '25%'}` : `${width}` }
    ))
}

const TableRender = WithDragAndDrop(({
    tabIndex, columnOrders, disableDrag, data, header,
    timeDimensions, labels, attendanceSetting, item, setColumnOrder,
    resultSet, availableTimeDimensions, updateQuery,
    isDashboardFilter, showTimeDimension, isHeaderFilter, thresholds, query, newQuery
}) => {
    let queryValue = newQuery ? newQuery : query;
    let istimeDimensionSelected = queryValue?.timeDimensions?.length > 0;
    const isConfig = (tabIndex === 1 || tabIndex === undefined || tabIndex === 0);
    const isDetailConfig = (tabIndex === 2);
    const headData = data && data[0] ? Object.keys(data[0]) : []

    const headFilterData = React.useMemo(() => {
        if (!tabIndex && !columnOrders.tableOrder) return headData;
        const order = tabIndex === 2 ? columnOrders.detailTableOrder : columnOrders.tableOrder;
        if (!order) return headData;
        const newOrder = order.filter(item => headData.includes(item));
        headData.forEach(item => {
            if (!newOrder.includes(item)) {
                newOrder.push(item);
            }
        });
        return newOrder;
    }, [columnOrders]);

    const getKeys = () => {
        let columns = [];
        if (isConfig) {
            columns = columnOrders?.tableOrder?.length > 0 ? columnOrders?.tableOrder : headData
        } else if (isDetailConfig) {
            columns = columnOrders?.detailTableOrder?.length > 0 ? columnOrders?.detailTableOrder : headData
        }
        return isHeaderFilter ? headFilterData : (columns?.length > 0 ? columns : headData);
    }
    const [keys, setKeys] = useState(getKeys());
    const diffWidth = ((100 / keys.length) > 33) ? `${100 / keys.length}%` : '160px';
    const [width, setWidth] = useState(diffWidth);
    const [thead, setThead] = useState(solveTableHeader(header, keys, width, labels));

    const updateKeys = (keyData) => {
        setKeys(keyData.filter((k) => {
            let isTimeDimension = false;
            (availableTimeDimensions || []).forEach(td => {
                if (!isTimeDimension && k.includes(td?.name)) {
                    isTimeDimension = true
                }
            })
            if (timeDimensions && timeDimensions[k]) {
                const dateField = k.split('.')

                if (!dateField[2]) {
                    return false;
                }
            }
            if (isTimeDimension) {
                const timeDimensionArray = isDetailConfig ? (showTimeDimension?.detailTimeDimension ?? []) :
                    (showTimeDimension?.basicTimeDimension ?? []);
                return timeDimensionArray.includes(k)
            }
            return true
        }));
    }

    const removeDuplicates = (arr) => {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    useEffect(() => {
        updateKeys(keys)
    }, [data, header, labels]);

    useEffect(() => {
        const newKeys = getKeys()
        updateKeys(newKeys)
    }, [tabIndex, columnOrders, showTimeDimension])

    const updateColumns = (isMount) => {
        const tempColumns = columnOrders ? { ...columnOrders } : {};
        let tableOrder = tempColumns?.tableOrder ?? [];
        let detailTableOrder = tempColumns?.detailTableOrder ?? [];
        if (isConfig && columnOrders?.tableOrder?.length > 0) {
            tableOrder = [...tableOrder, ...headData]
            tableOrder = removeDuplicates(tableOrder)
            tempColumns.tableOrder = tableOrder
            if (isMount && headData.length > 0) {
                let newOrder = [...tableOrder]
                tableOrder.forEach((order, index) => {
                    const removeIndex = headData.findIndex((o) => order === o);
                    if (removeIndex < 0) {
                        newOrder = newOrder.filter(nItem => nItem !== order)
                    }
                });
                tempColumns.tableOrder = newOrder
            }
            setColumnOrder && setColumnOrder(tempColumns)
        } else if (isDetailConfig && columnOrders?.detailTableOrder?.length > 0) {
            detailTableOrder = [...detailTableOrder, ...headData]
            detailTableOrder = removeDuplicates(detailTableOrder)
            tempColumns.detailTableOrder = detailTableOrder
            if (isMount && headData.length > 0) {
                let newOrder = [...detailTableOrder]
                detailTableOrder.forEach((order, index) => {
                    const removeIndex = headData.findIndex((o) => order === o);
                    if (removeIndex < 0) {
                        newOrder = newOrder.filter(nItem => nItem !== order)
                    }
                });
                tempColumns.detailTableOrder = newOrder
            }
            setColumnOrder && setColumnOrder(tempColumns)
        }
    }

    useEffect(() => {
        updateColumns()
    }, [tabIndex])

    useEffect(() => {
        updateColumns(true)
    }, [])

    useEffect(() => {
        let diffWid = diffWidth
        const widthArray = [];
        data.forEach(item => {
            const keys = Object.keys(item);
            if (keys.length === 2) {
                keys.forEach((key, index) => {
                    const value = item[key];
                    if (value && typeof value === 'string' && value.length > 150) {
                        if (Object.keys(diffWid).includes(key)) {
                            return;
                        }
                        if (widthArray.includes('75%') && !Object.keys(diffWid).includes(key)) {
                            diffWid = diffWidth
                        } else {
                            widthArray[index] = '75%'
                            diffWid = typeof diffWid === 'string' ? { [key]: "75%" } : { ...diffWid, [key]: "75%" }
                        }
                    } else {
                        diffWid = diffWid
                    }
                });
            }
        });
        setWidth(diffWid);
        setTimeout(() => setThead(solveTableHeader(header, keys, diffWid, labels)), 1);
    }, [keys]);

    const moveMember = (draggedIndex, targetIndex) => {
        const newColumnOrder = [...keys];
        const tempColumns = columnOrders ? { ...columnOrders } : {};
    
        if (tabIndex === 1) {
            tempColumns.tableOrder = newColumnOrder;
        } else if (tabIndex === 2) {
            tempColumns.detailTableOrder = newColumnOrder;
        }

        newColumnOrder.splice(targetIndex, 0, newColumnOrder.splice(draggedIndex, 1)[0]);
        setTimeout(() => {
            setKeys(newColumnOrder);
            setColumnOrder && setColumnOrder(tempColumns)
        }, 1);
    };

    const DropArea = styled.div`
    max-width:300px;
    width:100%;
    padding:12px;
    `
    const isObjectWidth = Object.prototype.toString.call(width) === '[object Object]' ? true : false
    const parseValue = (value = '') => {
        return value && value.match ? value?.match(/{(.*?)}/g)
            .map(pair => pair.slice(1, -1).split(':'))
            .reduce((acc, [key, val]) => {
                acc[key] = parseFloat(val);
                return acc;
            }, {}) : value;
    };

    const getTransformedValue = (key, empTransformValue, istimeDimensionSelected) => {
        const mapping = {
            'Employee.actual_schedule_hours': istimeDimensionSelected ? 'actual_schedule_hours' : 'actual_schedule_hours_aci',
            'Employee.actual_work_hours': istimeDimensionSelected ? 'actual_work_hours' : 'actual_work_hours_aci',
            'Employee.total_overtime_worked': istimeDimensionSelected ? 'overtime_work_hours' : 'overtime_work_hours_aci',
            'Employee.total_overtime_scheduled': istimeDimensionSelected ? 'overtime_schedule_hours' : 'overtime_schedule_hours_aci',
            'Employee.report_days_aci': istimeDimensionSelected ? 'report_days' : 'report_days_aci',
            'Employee.total_day_in_company': istimeDimensionSelected ? 'total_day_in_company' : 'total_day_in_company_aci',
            'Employee.send_home_aci': istimeDimensionSelected ? 'send_home' : 'send_home_aci',
            'Employee.total_days_worked': istimeDimensionSelected ? 'total_days_worked' : 'total_days_worked_aci',
            'Employee.total_scheduled_days': istimeDimensionSelected ? 'total_scheduled_days' : 'total_scheduled_days_aci'
        };
        return empTransformValue?.[mapping[key]] ?? null;
    };
    return (
        <div style={{width:"100%"}}>
            {isDashboardFilter && (
                <DashboardItemFilter item={item} resultSet={resultSet} updateQuery={updateQuery}/>
            )}
        <DropArea style={{ maxWidth: '100%', paddingLeft: 0 }}>
        <Table width={'100%'} style={{ maxHeight: '100%', overflowX: 'scroll', overflowY: 'hidden' }} margin={"auto"} minWidth={"100%"}>

            <TableHeader
                headers={thead ? thead : [{ label: 'Empty', width: '100%' }]}
                moveMember={moveMember}
                isDraggable={disableDrag ? false : (tabIndex === 1 || tabIndex === 2)}
            />
                {data && Array.isArray(data) && data?.length > 0 ?
                    data.map((d, k) => {
                return (
                    <TableRow key={k}>
                        {keys.map((key)=> {
                            let value = d[key];
                            let metadata = header.find(m => m.key === key)?.meta;
                            if(timeDimensions && timeDimensions[key]){
                                const dateField = key.split('.')
                                if(!dateField[2]){
                                    return null;
                                }
                                value = moment(value).format(DATE_FORMATS_BY_GRANULARITY[dateField[2]])
                            }
                            let style = {};
                            let empTransformValue;
                            if (empFields.includes(key)) {
                                empTransformValue = parseValue(value);
                                value = getTransformedValue(key, empTransformValue, istimeDimensionSelected);
                            }
                            if (tierRankFields.includes(key)) {
                                empTransformValue = parseValue(value);
                                value = queryValue?.timeDimensions && queryValue?.timeDimensions.length > 0 ?
                                    (empTransformValue?.avg_rank?.toFixed(1) || 'N/A') : (empTransformValue?.avg_rank_six_week?.toFixed(1) || 'N/A')
                            }
                            if(key == 'Employee.attendance_percentage' || key == 'Employee.name'){
                                let percentage = parseValue(d['Employee.attendance_percentage']);
                                percentage = istimeDimensionSelected ? percentage?.attendance_percentage : percentage?.attendance_percentage_aci
                                if((percentage >= attendanceSetting?.minLevel1  && percentage <= attendanceSetting?.maxLevel1) || percentage < 0){
                                    style = {
                                        color: attendanceSetting?.colorLevel1
                                    }
                                } else if(percentage >= attendanceSetting?.minLevel2  && percentage <= attendanceSetting?.maxLevel2){
                                    style = {
                                        color: attendanceSetting?.colorLevel2
                                    }
                                } else if(percentage >= attendanceSetting?.minLevel3  && percentage <= attendanceSetting?.maxLevel3){
                                    style = {
                                        color: attendanceSetting?.colorLevel3
                                    }
                                } else {
                                    const matchingThreshold = thresholds?.find((item) => item.name === key);
                                    if (matchingThreshold) {
                                        for (const threshold of matchingThreshold?.thresholdsSettings) {
                                            if (value == threshold?.value) {
                                                style = {
                                                    color: threshold.color
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                const matchingThreshold = thresholds?.find((item) => item.name === key);
                                if (key === matchingThreshold?.name) {
                                    let value = matchingThreshold?.type === "string" ?
                                        d[matchingThreshold?.name] :
                                        parseFloat(d[matchingThreshold?.name]);
                                    if (tierFields.includes(matchingThreshold?.name)) {
                                        value = d[matchingThreshold?.name];
                                        const tiers = value.split(', ');

                                        const styledValue = tiers.map(tier => {
                                            for (const threshold of matchingThreshold?.thresholdsSettings) {
                                                if (tier.includes(threshold?.value.trim())) {
                                                    return `<span style="color: ${threshold.color};">${tier}</span>`;
                                                }
                                            }
                                            return tier;
                                        });
                    
                                        return (
                                            <TableData
                                                key={`${k}-${key}`}
                                                width={isObjectWidth ? `${width[key] || '25%'}` : `${width}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: styledValue.length > 0 ? styledValue.join(", ") : 'N/A'
                                                }}
                                            />
                                        );
                                    }
                                    if (empFields.includes(key)) {
                                        value = getTransformedValue(key, empTransformValue, istimeDimensionSelected);
                                    }
                                    if (tierRankFields.includes(key)) {
                                        value = queryValue?.timeDimensions && queryValue?.timeDimensions.length > 0 ?
                                            (empTransformValue?.avg_rank?.toFixed(1) || 'N/A') : (empTransformValue?.avg_rank_six_week?.toFixed(1) || 'N/A')
                                    }
                                    for (const threshold of matchingThreshold?.thresholdsSettings) {
                                        if (value == threshold?.value) {
                                            style = {
                                                color: threshold.color
                                            }
                                        }
                                        const minValue = parseFloat(threshold.min);
                                        const maxValue = parseFloat(threshold.max);
                                        if (value >= minValue && value <= maxValue) {
                                            style = {
                                                color: threshold.color
                                            }
                                        }
                                    }
                                }
                            }

                            if(metadata?.isTime && metadata?.format){
                                return (<TableData key={`${k}-${key}`} width={isObjectWidth ? `${width[key] || '25%'}` : `${width}`}>
                                    {value && moment(value).format("DD-MM-YYYY") != "Invalid date" ? (
                                        moment(value).format(metadata?.format)
                                    ) : (
                                        "N/A"
                                    )}
                                </TableData>);
                            }

                            if (metadata?.isPercentage) {
                                let value = parseValue(d['Employee.attendance_percentage'])
                                if (value !== null && value !== undefined) {
                                    const content = istimeDimensionSelected ? value?.attendance_percentage : value?.attendance_percentage_aci;
                                    return (
                                        <TableData key={`${k}-${key}`} width={isObjectWidth ? `${width[key] || '25%'}` : `${width}`} style={style}>
                                            {metadata?.isPercentage ? `${content?.toFixed(metadata?.noOfDecimals || 0)}%` : content}
                                        </TableData>
                                    );
                                }
                            }

                            if (metadata?.noOfDecimals || metadata?.noOfDecimals == 0) {
                                const content = value !== null && value !== undefined && !isNaN(value)
                                    ? value.toFixed(metadata.noOfDecimals || 0) : 'N/A'

                                return (
                                    <TableData key={`${k}-${key}`} width={isObjectWidth ? `${width[key] || '25%'}` : `${width}`} style={style}>
                                        {content}
                                    </TableData>
                                );
                            }

                              return (
                                  <TableData key={`${k}-${key}`} width={isObjectWidth ? `${width[key] || '25%'}` : `${width}`} style={style}>
                                  {value !== null && value !== undefined && value !== "" ? value : 'N/A'}
                                </TableData>
                              );
                        })}
                    </TableRow>
                )
                }) :
                    <div style={{ marginTop: "50px", textAlign: "center" }}>
                        <ThirdTitle>No data found</ThirdTitle>
                    </div>}
        </Table>
        </DropArea>
        </div>
    )
})

const defaultDetailsConfig = {
    dateFormat: 'YYYY-MM-DD hh:mma'
};

const TableRenderBody = withCubeData(({ item, chartQuery, cancel, attendanceSetting, tabIndex,
    isDashboardFilter, filterDashboardItemData, updateQuery, isScorecardFilter, 
    availableMeasures, api, isHeaderFilter, setIsHeaderFilter, ...cubeData }) => {
    const detailsQuery = item?.chartDetailQuery || {};
    const detailsConfig = item?.chartConfig?.details || {};
    const labels = item?.chartConfig?.labels || null;

    let newQuery = chartQuery;
    const isFilter = isScorecardFilter || isDashboardFilter
    if(detailsQuery){
        newQuery = {
            ...chartQuery,
            ...detailsQuery
        };
    }

    const tableQuery = fixWeekCondition(newQuery);
    if(!tableQuery.limit){
        tableQuery.limit = 1000;
    }

    const { resultSet, isLoading, error } = useCubeQuery(tableQuery, { subscribe: detailsConfig.realtime });

    const header = filterDashboardItemData?.tableColumns() || resultSet?.tableColumns();
    const data = resultSet?.loadResponse?.results[0]?.data || [];
    const timeDimensions = resultSet?.loadResponse?.results[0]?.annotation?.timeDimensions || {};

    let headers = [];
    (header || []).map((e) => {
        headers.push({"key": e.key, "label": e.title});
    });

    return (
        <Modal
            width={'880px'}
            visible={true}
            closeOnOverlay={false}
            title={item.title}
            setVisible={cancel}
            exportBtn={true}
            headers={headers}
            data={filterDashboardItemData?.loadResponses[0]?.data || data}
            filename={`${item?.title?.replaceAll(/\s/g, '')}.csv`}
            cancelLabel={"Close"}
        >
            <DetailErrorHandler
                error={error}
                isLoading={isLoading || !resultSet}
                onSuccess={()=>(
                    <div style={{ overflow: 'scroll' }}>
                        {isScorecardFilter &&
                            <DashboardScorecardFilter
                                availableMeasures={availableMeasures}
                                item={item}
                                updateQuery={updateQuery}
                                schema={item?.chartConfig.schema}
                                resultSet={resultSet}
                                api={api} 
                                setIsHeaderFilter={setIsHeaderFilter}
                                isHeaderFilter={isHeaderFilter}
                                tabIndex={tabIndex}/>}
                        <TableRender
                            data={filterDashboardItemData?.loadResponses[0]?.data || data}
                            header={header}
                            timeDimensions={timeDimensions}
                            labels={labels}
                            detailsConfig={{
                                ...defaultDetailsConfig,
                                ...detailsConfig
                            }}
                            attendanceSetting={attendanceSetting}
                            item={item}
                            tabIndex={tabIndex}
                            columnOrders={{
                                tableOrder: item?.chartConfig?.pivotConfig?.tableOrder,
                                detailTableOrder: item?.chartConfig?.pivotConfig?.detailTableOrder
                            }}
                            showTimeDimension={{
                                basicTimeDimension: item?.chartConfig?.pivotConfig?.basicTimeDimension,
                                detailTimeDimension: item?.chartConfig?.pivotConfig?.detailTimeDimension
                            }}
                            thresholds={item?.chartConfig?.pivotConfig?.thresholds || []}
                            disableDrag={true}
                            isDashboardFilter={isDashboardFilter}
                            resultSet={isFilter? (filterDashboardItemData || resultSet) : resultSet}
                            updateQuery={updateQuery}
                            isHeaderFilter={isHeaderFilter}
                            newQuery={newQuery}
                            {...cubeData}
                        />
                    </div>
                )}
            />
        </Modal>
    )
})

const DetailErrorHandler = ({error,isLoading,onSuccess}) => {
    if (error) {
        return <ThirdTitle>This chart contains errors, please review the configuration</ThirdTitle>
    }

    if(isLoading){
        return <Loading style={{height:50,display:'block',margin:'0 auto'}} />
    }

    return onSuccess()
}

const openTableRender = (props) => {
    return dialogPromise((success, cancel)=>{
        return(
            <CubeProvider cubejsApi={cubeApi}>
                <TableRenderBody
                    cancel={cancel}
                    {...props}
                />
            </CubeProvider>
        )
    })
}

const NumberWidget = ({data,header,labels}) => {
    const total =  data.length -1;

    return (
        <div style={{display:'grid',gridTemplateColumns:`repeat(${data.length},1fr)`,gridAutoFlow:'column'}}>
            {data.map((n ,k)=>(
                <React.Fragment key={k}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <ThirdTitle style={{textAlign:'center'}}>{header[n.key] ? (labels && labels[n.key] ? labels[n.key] : header[n.key].title) : n.key}</ThirdTitle>
                        <Title  style={{textAlign:'center',fontSize:26}}>{n ? n.value : 0}</Title>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

const solveChartRender = (query, isTimeDimensionSelectedAndPieChart, data, heading) => {
    const tier = query?.measures && query?.measures[0];
    data.forEach(item => {
        let value = "";
        if (empFields.includes(tier)) {
            if (item?.color === "Actual Scheduled Hours") {
                const match = String(item?.measure)?.match(/actual_schedule_hours:(\d+(\.\d+)?)/);
                if (match) {
                    value = match[1];
                }
            } else if (item.color == "Actual work Hours") {
                const match = String(item?.measure)?.match(/actual_work_hours:(\d+(\.\d+)?)/);
                if (match) {
                    value = match[1];
                }
            } else if (item.color == "Total Overtime Scheduled") {
                const match = String(item?.measure)?.match(/overtime_schedule_hours:(\d+(\.\d+)?)/);
                if (match) {
                    value = match[1];
                }
            } else if (item.color == "Total Overtime Worked") {
                const match = String(item?.measure)?.match(/overtime_work_hours:(\d+(\.\d+)?)/);
                if (match) {
                    value = match[1];
                }
            } else if (item.color) {
                const match = String(item?.measure)?.match(/(actual|overtime)_(schedule|work)_hours:(\d+(\.\d+)?)/);
                if (match) {
                    value = match[0];
                }
            }
            item.measure = value;
        } else if (tierRankFields.includes(tier)) {
            if (item?.color === "Average Rank") {
                if (typeof item?.measure === 'string') {
                    const match = item?.measure?.match(/avg_rank:\s*(\d+)/);
                    value = match ? Number(match[1]) : null;
                } else {
                    value = null;
                }
            } else if (item?.measure) {
                if (typeof item?.measure === 'string') {
                    const match = item?.measure?.match(/avg_rank:\s*(\d+)/);
                    value = match ? Number(match[1]) : null;
                } else {
                    value = null;
                }
            }
            item.measure = value;
        }
    });
    const axisConfig = {
        x: {
            text: ''
        },
    }
    return {
        bar: () => {

            return <Bar
                x={'x'}
                y={'measure'}
                groupBy={'color'}
                title={axisConfig}
                data={data}
            />
        },
        line: () => {
            return <Line x={'x'} y={'measure'} splitBy={'color'} title={axisConfig} />
        },
        pie: () => {
            return <Pie
                x={isTimeDimensionSelectedAndPieChart ? 'color' : 'x'}
                y={'measure'}
                title={axisConfig}
                data={data}
                heading={heading}
            />
        }
    }

}

export {openTableRender}
export default ChartRender;