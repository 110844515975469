import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { useForm } from "react-hook-form";
import {
    Alert,
    Button,
    CustomDropdown,
    Dropdown,
    Icon,
    Input,
    Spacer,
    SubTitle,
    Text,
    Theme,
    // TimePicker,
    RadioBox,
    Toggle,
    CheckBox,
} from "@dspworkplace/ui";
import { Label } from "../../../../components/UI";
import Loading, { LoadingWrapper } from "../../../../components/Loading";
import { ScrollyModalStyle, ScrollyOverlayStyle } from "../../../../components/Modal";
import {
    asDatabaseTime,
    calculateDuration,
    databaseToMilliseconds,
    intUtcToLocal,
    stringToMilliseconds,
} from "../../../../components/TimeFormat";
import People from "../../../../components/People";
import { dialogPromise } from "../../../../components/Dialog";
import TimePicker from "../../../../components/TimePicker";
import moment from "moment";
import ComboBox from "../../../../components/ComboBox";
import { Popup, solvePosition } from "../../../../components/ShiftBlock";

const ShiftPopupOverlay = styled(ScrollyOverlayStyle)`
    background: none;
`;

const PortalComponent = ({ children, containerClass, position }) => children
    ? ReactDOM.createPortal(
        <ShiftPopupOverlay>
            <Popup
                className={`popup ${position?.direction}`}
                hasContainer={containerClass ? true : false}
                left={position?.left}
                top={position?.top}
                style={{
                    width: '358px',
                    position: position ? 'fixed' : 'relative',
                    ...!position && {
                        margin: 'auto'
                    }
                }}
            >
                <span className='popup-arrow' />
                {children}
            </Popup>
        </ShiftPopupOverlay>,
        document.getElementById('root')
    ) : null;

const ShiftBlockForm = ({
    success,
    cancel,
    shift = {},
    driver,
    date,
    api,
    options = {},
    title,
    fields,
    isTimeoff = true,
    timeZoneShortName = null,
    currentPopRef
}) => {

    if (timeZoneShortName == null) {
        timeZoneShortName = 'ET';
    }

    const [state, setState] = useState({
        shiftTypes: [],
        shiftInvoiceTypes: [],
        stations: [],
        loaded: false,
        ...options,
    });
    const [routeStatus, setRouteStatus] = useState(false);
    const [backupStatus, setBackupStatus] = useState(false);
    const [isOT, setIsOT] = useState(false);
    const [isDutyEdit, setIsDutyEdit] = useState(false);
    const [isTraineeEdit, setIsTraineeEdit] = useState(true);
    const initialPosition = { left: 0, top: 0, direction: 'right' };
    const [position, setPosition] = useState(initialPosition);
    // let canceler,
    let getShiftOptions;
    let getStations;
    let isVoluntary;
    if (api) {
        // canceler = api.canceler;
        getShiftOptions = api.getShiftOptions;
        getStations = api.getStations;
    }
    const loadOptions = async (driver) => {
        getShiftOptions({
            shift,
            driver,
        }).then(
            (response) => {
                response &&
                    setState({
                        shiftTypes: isTimeoff
                            ? response.data.data.shift
                            : response.data.data.shift.filter((s) => s.category != 8),
                        shiftInvoiceTypes: response.data.data.invoiceTypes,
                        loaded: true,
                    });
            },
            (error) => console.log(error)
        );
        getStations({
            shift,
            driver,
        }).then(
            (response) => {
                response &&
                    setState((prevState) => ({
                        ...prevState,
                        stations: response.data.data.station.map((s) => ({ name: s.code, value: s.id })),
                    }));
            },
            (error) => console.log(error)
        );
    };

    let defaultValues = {
        qty: 1,
        shiftInvoiceType: options.diffshiftInvoiceType ? 'mismatch' : shift.invoiceTypeId ? shift.invoiceTypeId.toString() : "0",
        routeStatus: shift.routeStatus,
        isVoluntary: shift.isVoluntary,
        backupStatus: shift.backupStatus,
        onCall: shift.onCall
    };

    if (shift.typeId) defaultValues.shiftType = shift.typeId.toString();

    if (shift.isPerformanceRelated) defaultValues.isPerformanceRelated = shift.isPerformanceRelated;

    if (shift.currentNote) defaultValues.note = shift.currentNote;

    if (shift.newNote) defaultValues.newNote = shift.newNote;

    if ((shift.utcStartTime || shift.utcStartTime === 0) && (shift.utcStartTime != null || shift.utcStartTime !== false))
        defaultValues.startTime = parseFloat(shift.utcStartTime).toFixed(2).replace(".", ":");

    if (shift.utcEndTime != null || shift.utcEndTime !== false)
        defaultValues.endTime = parseFloat(shift.utcEndTime).toFixed(2).replace(".", ":");

    if (shift.dateTimeEnded) {
        defaultValues.endTime = parseFloat(shift.dateTimeEnded).toFixed(2).replace(".", ":");
    }

    if (
        typeof shift.utcEndTimeOfPriorShift != "undefined" &&
        (shift.utcEndTimeOfPriorShift != null || shift.utcEndTimeOfPriorShift != false)
    )
        defaultValues.endTimeOfPriorShift = shift.utcEndTimeOfPriorShift.toFixed(2).replace(".", ":");

    if (shift.autoApproveDriverRequest) defaultValues.autoApproveDriverRequest = shift.autoApproveDriverRequest;
    if (shift.manualApproveDriverRequest) defaultValues.manualApproveDriverRequest = (shift.autoApproveDriverRequest == true) ? shift.manualApproveDriverRequest : false;    
    const { register, handleSubmit, getValues, errors, reset, watch } = useForm({
        defaultValues: defaultValues,
    });

    const onSubmit = (data) => {
        data.extra = state.shiftTypes.find((opt) => opt.id.toString() === getValues().shiftType.toString());
        data.utcStartTime = data.startTime.replace(":", ".");
        data.utcEndTime = data.endTime.replace(":", ".");
        data.onCall = (data.onCall == 'true' || data.onCall == true || data.onCall == 1) ? true : false;
        if (data.autoApproveDriverRequest !== undefined) data.autoApproveDriverRequest = data.autoApproveDriverRequest === "true";
        if (data.manualApproveDriverRequest !== undefined) {
            data.manualApproveDriverRequest = data.manualApproveDriverRequest === "true"
        } else if (data.manualApproveDriverRequest == undefined && data.autoApproveDriverRequest !== undefined && data.autoApproveDriverRequest == false) {
            data.manualApproveDriverRequest = false;
        }
        data.isOT = isOT;
        success(data);
    };

    const isAutoApprove = watch("autoApproveDriverRequest") != undefined ? watch("autoApproveDriverRequest") : false;
    const durationRef = useRef();
    const [overtimeWarning, setOvertimeWarning] = useState(false);
    const updateDuration = (startTime, endTime) => {
        let st = startTime;
        if (st === null) {
            st = getValues().startTime;
        } else {
            if (stringToMilliseconds(startTime) !== 0) st = asDatabaseTime(stringToMilliseconds(startTime));
        }
        let et = endTime;
        if (et === null) et = getValues().endTime;
        else if (stringToMilliseconds(endTime) !== 0) et = asDatabaseTime(stringToMilliseconds(endTime));

        let duration = calculateDuration(st, et);
        durationRef.current.innerHTML = duration;

        let shiftDuration = 0;
        if (shift && defaultValues.startTime && shift.utcEndTime) {
            let sst = defaultValues.startTime;
            if (stringToMilliseconds(defaultValues.startTime) !== 0)
                sst = asDatabaseTime(stringToMilliseconds(defaultValues.startTime));

            let set = defaultValues.endTime;
            if (stringToMilliseconds(defaultValues.endTime) !== 0)
                set = asDatabaseTime(stringToMilliseconds(defaultValues.endTime));

            shiftDuration = calculateDuration(sst, set);
        }

        let dur = parseFloat(duration);
        let estimatedWorkHours =
            shift && shift.driver
                ? shift.driver.estimatedWorkHours
                    ? shift.driver.estimatedWorkHours
                    : shift.driver.expectedWorkHours
                        ? shift.driver.expectedWorkHours
                        : 0
                : driver && driver.estimatedWorkHours
                    ? driver.estimatedWorkHours
                    : 0;

        if (parseFloat(estimatedWorkHours) - shiftDuration + dur > 40)
            setOvertimeWarning((parseFloat(estimatedWorkHours) - shiftDuration + dur - 40).toFixed(2));
        else {
            setOvertimeWarning(false);
        }
    };
    useEffect(() => {
        if (!shift) {
            updateDuration("0:00", "0:00");
        }
    }, [shift]);

    const updateStartTime = (endTimeOfPriorShift) => {
        // don't update in the first run
        if (defaultValues.endTimeOfPriorShift === endTimeOfPriorShift) return;

        let startTime = asDatabaseTime(databaseToMilliseconds(endTimeOfPriorShift) + 1000 * 60);
        reset({
            startTime: startTime,
            isPerformanceRelated: shift.isPerformanceRelated,
        });
    };

    let currentShiftDate = moment().format("YYYY-MM-DD");
    if (typeof date != "undefined") {
        currentShiftDate = date.format("YYYY-MM-DD");
    } else {
        if (typeof shift.shiftDate != "undefined") {
            currentShiftDate = shift.shiftDate;
        } else if (typeof shift.dateCreated != "undefined") {
            currentShiftDate = shift.dateCreated;
        }
    }
    if (moment(currentShiftDate).day() === 0) {
        currentShiftDate = moment(currentShiftDate).add(1, 'days').format('YYYY-MM-DD');
    }

    const setTimes = (value) => {
        let isDST = moment(currentShiftDate).isDST();
        const shiftType = state.shiftTypes.find((s) => value.toString() === s.id.toString());
        const startTime = isDST
            ? asDatabaseTime(shiftType.startTimeOnDst.timestamp * 1000)
            : asDatabaseTime(shiftType.startTime.timestamp * 1000);
        const endTime = isDST
            ? asDatabaseTime(shiftType.endTimeOnDst.timestamp * 1000)
            : asDatabaseTime(shiftType.endTime.timestamp * 1000);
        if (shiftType.category == 8) {
            setRouteStatus(true);
            setBackupStatus(false);
        } else {
            setRouteStatus(false);
        }

        if (shiftType.category == 6) {
            setIsDutyEdit(false);
        } else {
            setIsDutyEdit(true);
        }

        if (shiftType.category == 5) {
            setIsTraineeEdit(true);
        } else {
            setIsTraineeEdit(false);
        }
        if (shiftType.category == 2 && shiftType.isOT == true) {
            setIsOT(true);
            setBackupStatus(true);
            setRouteStatus(false);
        } else {
            setBackupStatus(false);
            setIsOT(false);
        }
        // if(shift && shift.isSecondShift == true){
        //
        // let diffTime = shiftType.endTime.timestamp - shiftType.startTime.timestamp;
        // let min = diffTime/60;
        // let tempDate = moment().utc().add(min, "minutes");
        //
        // reset({
        //     qty: getValues().qty,
        //     shiftType: value,
        //     endTime: asDatabaseTime(tempDate),
        //     shiftInvoiceType: (shiftType.invoiceType) ? shiftType.invoiceType.id : 'null' || 0,
        // });
        // } else {
        reset({
            qty: getValues().qty,
            autoApproveDriverRequest: getValues().autoApproveDriverRequest ?? false,
            manualApproveDriverRequest: getValues().manualApproveDriverRequest ?? false,
            shiftType: value,
            startTime: startTime,
            endTime: endTime,
            shiftInvoiceType: shiftType.invoiceType ? shiftType.invoiceType.id : "null" || 0,
        });
        // }
        updateDuration(startTime, endTime);
    };

    const values = getValues();
    useEffect(() => {
        if (currentPopRef) {
            const popupPosition = solvePosition(currentPopRef, 'popup', document.getElementById('root'), undefined);
            setPosition(popupPosition);
        }
        document.dispatchEvent(new Event("mouseup"));
        let driverObj = {};
        if (shift.category == 8) {
            setRouteStatus(true);
        }
        if (shift.category == 2 && shift.isOT === true) {
            setBackupStatus(true);
            setIsOT(true);
        }
        if (driver) {
            driverObj = driver;
        }

        if (!state.loaded) loadOptions(driverObj);

        // return ()=> canceler && canceler.current();
    }, []);

    useEffect(() => {
        if (state.loaded) {
            if (currentPopRef) {
                const popupPosition = solvePosition(currentPopRef, 'popup', document.getElementById('root'), undefined);
                setPosition(popupPosition);
            }
        }
    }, [state.loaded])

    return (
        <PortalComponent position={currentPopRef ? position : null}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!state.loaded ? (
                    <Spacer all={5}>
                        <LoadingWrapper>
                            <Loading />
                        </LoadingWrapper>
                    </Spacer>
                ) : (
                    <div>
                        <header style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '20px'
                        }}>
                            {title && <SubTitle>{title}</SubTitle>}
                            {!title && <SubTitle>{shift ? "Edit" : "Add"} Shift</SubTitle>}
                            <Button type="cancel" onClick={cancel}>
                                Cancel
                            </Button>
                        </header>
                        {driver && driver.name && (
                            <Spacer bottom={3}>
                                <Label>Driver</Label>
                                <People
                                    name={driver.name}
                                    img={driver.img}
                                    jobTitle={
                                        <span style={{ display: "flex", alignItems: "center", marginTop: "" }}>
                                            <Icon.Clock size="12px" color={Theme.colors.neutrals.medium} />
                                            <span>{driver.workHours}</span>/ <span>{driver.estimatedWorkHours}</span>
                                        </span>
                                    }
                                />
                            </Spacer>
                        )}
                        {date && (
                            <Spacer bottom={3}>
                                <Label>Date</Label>
                                <Text>{date.format("ddd DD, MMM YYYY")}</Text>
                            </Spacer>
                        )}
                        {(!driver || driver.id === "0.0.0") && !shift.routeId && (
                            <Spacer bottom={3}>
                                <Input ref={register} name="qty" label="Quantity" size="extraSmall" />
                            </Spacer>
                        )}
                        {(shift?.isOpenShift || driver?.id === "0.0.0") && (
                            <Spacer bottom={3}>
                                <CheckBox
                                    ref={register}
                                    name="autoApproveDriverRequest"
                                    options={[{ value: true, label: "Auto-approve driver request" }]}
                                    defaultValues={[defaultValues.autoApproveDriverRequest]}
                                />
                                {isAutoApprove && <CheckBox
                                    ref={register}
                                    name="manualApproveDriverRequest"
                                    options={[{ value: true, label: "Manual-approve if over 40 hrs" }]}
                                    defaultValues={[defaultValues.manualApproveDriverRequest]}
                                />}
                            </Spacer>
                        )}
                        <ComboBox
                            ref={register({
                                required: true,
                                validate: (value) => {
                                    return value === 'mismatch' ? 'Please select a valid shift type' : true;
                                }
                            })}
                            Required={true}
                            name="shiftType"
                            label="Shift Type"
                            type="shiftIcon"
                            openOnLoad={Object.keys(shift).length === 0 ? true : false}
                            input={false}
                            options={state.shiftTypes.map((s) => {
                                let name = s.name + " (" + s.station.name + ")"
                                name = name.length > 38 ? `${name.substring(0, 39)}...` : name
                                return {
                                    name: name,
                                    value: s.id.toString(),
                                    color: s.color,
                                    invoiceType: s.invoiceType ? s.invoiceType.id : null,
                                    category: s.category,
                                    isOT: s.isOT
                                }
                            })}
                            valid={values.shiftType && values.shiftType.length}
                            error={errors.shiftType && "Please select a shift type"}
                            onChange={setTimes}
                            visibleOptionsQty={6}
                        />

                        {shift && shift.isRescue === true && shift.category != 7 && (
                            <Spacer top={3}>
                                <TimePicker
                                    ref={register({
                                        required: "Required field",
                                        validate: (value) => {
                                            const selected = intUtcToLocal(parseFloat(value.replace(":", ".")));
                                            return (
                                                (selected > intUtcToLocal(shift.utcPriorShiftStartTime) && // after start time of first shift
                                                    selected <
                                                    intUtcToLocal(
                                                        parseFloat(watch("endTime").replace(":", "."))
                                                    )) || // before end time
                                                "Must be after shift start and before selected end time"
                                            );
                                        },
                                    })}
                                    Required={true}
                                    name="endTimeOfPriorShift"
                                    label="End Time of Prior Shift"
                                    // size='small'
                                    // disabled={(shift.isPunchedIn || shift.isPunchedOut || shift.isBreakPunchIn || shift.isBreakPunchOut) ? 'disabled' : ''}
                                    onChange={(v) => updateStartTime(v)}
                                    timeZoneShortName={timeZoneShortName}
                                    valid={
                                        values.endTimeOfPriorShift &&
                                        values.endTimeOfPriorShift.length &&
                                        !errors.endTimeOfPriorShift
                                    }
                                    dateForTimePicker={currentShiftDate}
                                    error={errors.endTimeOfPriorShift && errors.endTimeOfPriorShift?.message}
                                />
                            </Spacer>
                        )}

                        <Spacer top={3} />
                        <TimePicker
                            ref={register({
                                required: true,
                                validate: (value) => {
                                    return value === 'mismatch' ? "Required Start Time" : true;
                                }
                            })}
                            Required={true}
                            name="startTime"
                            label="Start Time"
                            size="small"
                            diffTime={options.diffTime}
                            timeZoneShortName={timeZoneShortName}
                            disabled={shift.isPunchedIn}
                            onChange={(v) => updateDuration(v, null)}
                            valid={values.startTime && values.startTime.length}
                            dateForTimePicker={currentShiftDate}
                            error={errors.startTime && "Invalid format"}
                        />
                        <Spacer right={5} inline />
                        <TimePicker
                            ref={register({
                                required: true,
                                validate: (value) => {
                                    return value === 'mismatch' ? "Required End Time" : true;
                                }
                            })}
                            Required={true}
                            name="endTime"
                            label="End Time"
                            size="small"
                            diffTime={options.diffTime}
                            timeZoneShortName={timeZoneShortName}
                            disabled={shift.isPunchedOut}
                            onChange={(v) => updateDuration(null, v)}
                            valid={values.endTime && values.endTime.length}
                            dateForTimePicker={currentShiftDate}
                            error={errors.endTime && "Invalid format"}
                        />

                        <Spacer top={3} />
                        <Label>Duration</Label>
                        <Text ref={durationRef}>–</Text>
                        {overtimeWarning && (
                            <Spacer top={3}>
                                <Alert.Warning
                                    title="Overtime warning"
                                    content={`This driver will be in overtime by ${overtimeWarning} hours`}
                                />
                            </Spacer>
                        )}
                        {routeStatus ? (
                            <Spacer top={3}>
                                <Label>Shift Status</Label>
                                <RadioBox
                                    ref={register({ required: "Required Shift Status" })}
                                    name="routeStatus"
                                    options={[
                                        { label: "Paid", value: "1" },
                                        { label: "Unpaid", value: "2" },
                                        { label: "Holiday", value: "3" },
                                    ]}
                                    Required={true}
                                    valid={values.routeStatus && !errors.routeStatus}
                                    error={errors.routeStatus && errors.routeStatus.message}
                                />
                                <Spacer top={3}></Spacer>
                                <CheckBox
                                    ref={register()}
                                    name="isVoluntary"
                                    options={[{ value: true, label: "Voluntary" }]}
                                />
                            </Spacer>
                        ) : (
                            <Input type="hidden" defaultValue="0" name="routeStatus" ref={register} />
                        )}
                        {backupStatus ? (
                            <Spacer top={3}>
                                <Label>Shift Status</Label>
                                <RadioBox
                                    ref={register({ required: "Required Shift Status" })}
                                    name="backupStatus"
                                    options={[
                                        { label: "Rostered", value: "1" },
                                        { label: "Rescuer", value: "2" }
                                    ]}
                                    Required={true}
                                    valid={values.backupStatus && !errors.backupStatus}
                                    error={errors.backupStatus && errors.backupStatus.message}
                                //defaultValue={[""]}
                                />
                                <Spacer top={3}></Spacer>
                                <CheckBox
                                    ref={register()}
                                    name="onCall"
                                    options={[{ value: true, label: "onCall" }]}
                                />
                            </Spacer>

                        ) : (
                            <Input type="hidden" defaultValue="false" name="onCall" ref={register} />
                        )}
                        {shift && shift.isRescue === true && (
                            <Spacer top={3}>
                                <CheckBox
                                    name="isPerformanceRelated"
                                    options={[{ value: true, label: "Performance Related Rescue" }]}
                                    ref={register}
                                />
                            </Spacer>
                        )}
                        {((shift && (isDutyEdit == true || shift.isDuty == false || shift.isRescue == true)) ||
                            Object.entries(shift).length == 0 ||
                            shift.isAdd == true) && (
                                <>
                                    <Spacer top={3}>
                                        <ComboBox
                                            ref={register}
                                            name="shiftInvoiceType"
                                            label="Invoice Type"
                                            placeholder={options.diffshiftInvoiceType ? "(multiple values selected)" : "Select Invoice Type"}
                                            options={[
                                                {
                                                    value: "null",
                                                    name: "Select Invoice Type",
                                                },
                                            ].concat(
                                                state.shiftInvoiceTypes.map((type, index) => {
                                                    let name = type.name + " (" + type.station.name + ")"
                                                    name = name.length > 38 ? `${name.substring(0, 39)}...` : name
                                                    return {
                                                        name: name,
                                                        value: type.id,
                                                    }
                                                })
                                            )}
                                            openOnLoad={false}
                                            input={false}
                                            error={errors.shiftInvoiceType}
                                            visibleOptionsQty={3}
                                        />
                                        {/* <Dropdown
                                            ref={register}
                                            name="shiftInvoiceType"
                                            label="Invoice Type"
                                            placeholder={options.diffshiftInvoiceType ? "(multiple values selected)" : "Select Invoice Type"}
                                            options={[
                                                {
                                                    value: "null",
                                                    name: "Select Invoice Type",
                                                },
                                            ].concat(
                                                state.shiftInvoiceTypes.map((type, index) => {
                                                    let name = type.name + " (" + type.station.name + ")"
                                                    name = name.length > 38 ? `${name.substring(0, 39)}...` : name
                                                    return {
                                                        name: name,
                                                        value: type.id,
                                                    }
                                                })
                                            )}
                                        /> */}
                                    </Spacer>
                                </>
                            )}
                        {/* { shift && shift.isTrain == true && <>
                        <Spacer top={3}>
                            <Dropdown
                                ref={register}
                                name='shiftInvoiceType'
                                label='Invoice Type'
                                placeholder={'Select Invoice Type'}
                                options={
                                    state.shiftInvoiceTypes.map((type,index) => {
                                        if(index == 0)
                                            return {'value':'null','name':'Select Invoice Type'};
                                        return {
                                            name: type.name+' ('+type.station.name+')', value: type.id
                                        }
                                    })}
                            />
                        </Spacer></>} */}
                        {/*<Spacer top={3} inline>
                                <Input
                                    name='newNote'
                                    label="Dispatcher's Shift Note"
                                    size='big'
                                    style={{height: 76,width: 300}}
                                    as='textarea'
                                    defaultValue={shift.newNote}
                                    ref={register}
                                />
                        </Spacer>*/}
                        {isTraineeEdit == true &&
                            fields &&
                            fields.length &&
                            fields.map((item, k) => (
                                <Spacer key={k} top={3}>
                                    {React.createElement(item.component, {
                                        ...item.props,
                                        ref: register({
                                            required: item.props.Required,
                                        }),
                                        valid: values[item.props.name],
                                        error: errors[item.props.name] && "Invalid",
                                    })}
                                </Spacer>
                            ))}

                        {shift && shift.isRescuerEdit ? (
                            <Spacer top={3}>
                                <Label>Route Being Rescued</Label>
                                <Spacer bottom={1} />
                                <Text>
                                    {Array.isArray(shift.isRescuerEdit) ? shift.isRescuerEdit.join(", ") : null}
                                </Text>

                                <Spacer top={3}>
                                    <Input
                                        ref={register({ required: "Packages is required" })}
                                        name="numberOfPackage"
                                        label="Number of Packages"
                                        defaultValue={shift.numberOfPackage}
                                        Required={true}
                                    />
                                </Spacer>
                            </Spacer>
                        ) : (
                            ""
                        )}

                        <Spacer top={5} style={{ textAlign: "right" }}>
                            <Button type="primary">
                                {shift && shift.isRescuer ? "Confirm and post changes" : "Save"}
                            </Button>
                        </Spacer>
                    </div>
                )}
            </form>
        </PortalComponent>
    );
};

const showEditShiftBlockForm = async (opts) => {
    let { api, shift } = opts || {};
    return dialogPromise((success, cancel) => (
        <ShiftBlockForm success={success} cancel={cancel} api={api} shift={shift} />
    ));
};

const showAddShiftBlock = async (opts) => {
    let { api, driver, date } = opts || {};

    return dialogPromise((success, cancel) => (
        <ShiftBlockForm
            success={success}
            cancel={cancel}
            api={api}
            shift={{
                shiftDate: date.format("YYYY-MM-DD"),
                stationId: driver.stationId,
                driverId: driver.id,
                driver,
            }}
        />
    ));
};

const showFlexibleShiftBlockForm = async (opts) => {
    return dialogPromise((success, cancel) => <ShiftBlockForm success={success} cancel={cancel} {...opts} />);
};

export { showEditShiftBlockForm, showAddShiftBlock, showFlexibleShiftBlockForm };
