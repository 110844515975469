import React,{useState, useRef, useEffect,forwardRef} from 'react';
import styled from 'styled-components';
// import Theme from '../Theme';
// import {Label, InputBase, RequiredText} from '../Common/input'
// import Icon from '../Icons';
import { Icon, Theme } from '@dspworkplace/ui';
import Ex from '../../assets/Ex.svg'
// import Help from '../Common/tooltip'
// import mergeRefs from '../Common/MergeRefs';
const mergeRefs = (refs) => {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref != null) {
                ref.current = value;
            }
        });
    };
};

// import Theme from '../Theme';
// import Invalid from '../../assets/Invalid.svg';
// import Ex from '../../assets/Ex.svg'
// import Check from '../../assets/Check.svg'


const handleSize = sizeType => {
  switch(sizeType){
    case 'extraSmall':
      return '80px';
    case 'small':
      return '140px';
    case 'big':
      return '460px';
    default:
      return '300px';
  }
}

const handlerColor = typeColor => {
  switch(typeColor){
    case 'error':
      return Theme.colors.error.text;
    case 'valid':
      return Theme.colors.success.text;
    default:
      return Theme.colors.neutrals.silver;
  }
}

const Label = styled.label`
  font-size:${Theme.font.medium.size};
  font-weight:normal;
  font-family:Circe-Rounded;
  display:block;
  padding-bottom:2px;
  color:#707070;

  >.requiredField {
    color:${Theme.colors.error.text};
  }

`;

const RequiredText = () => <span className='requiredField'>*</span>;

const borderDefault = `
    border-width:1px;
    border-style:solid;
`;

const InputBase = styled.input`
    background:${Theme.colors.neutrals.white};
    border-color:${props => handlerColor(props.validation)};
    border-width:1px;
    border-style:solid;
    border-radius:${Theme.form.input.radius};
    font-size:${Theme.font.small.size};
    line-height:${Theme.font.small.lineHeight};
    height:40px;
    padding:${Theme.form.input.padding};
    color:${Theme.colors.neutrals.gray};
    width:${({size}) => handleSize(size) };

    &.hasChildren {
      padding-right:40px;
    }

    &.hasChildren.childrenLeft {
      padding-right:${Theme.form.input.padding};
      padding-left:40px;
    }

    &:disabled {
        background: ${Theme.colors.extra.whiteSmoke};
        ${borderDefault};
        border-color: ${Theme.colors.neutrals.silver};
        color: ${Theme.colors.neutrals.medium};
    }

    &:disabled::placeholder {
        color:#eee;
    }

   &:hover {
      ${borderDefault} ;
      border-color:${props => handlerColor(props.validation)};
   }

   &:active {
      ${borderDefault};
      border-color:${props => handlerColor(props.validation)};
   }

   &:focus {
    ${borderDefault};
    box-shadow:0px 0px 6px ${Theme.colors.action};
    border-color:${Theme.colors.secondary};
   }

   &::placeholder {
      color:${Theme.colors.neutrals.silver};
      font-size:${Theme.font.small};
   }

   &:read-only {
    background:${Theme.colors.info.bg};
    border-color:${Theme.colors.neutrals.silver};
    color:#707070;
   }
`;

const Help = styled.span`
  height:14px;
  width:14px;
  border-radius: 100%;
  background: ${Theme.colors.info.bg}; 
  border: 1px solid ${Theme.colors.info.border};
  color:${Theme.colors.info.text};
  font-family:Circe-Rounded;
  display: inline-block;
  font-size: 10px;
  text-align:center;
  font-weight:bold;
  position:relative;
  top:-2px;
  left:5px;
  
   >div {
      display:none;
      background:white;
      padding:7px;
      position:absolute;
      top:-40px;
      left:-4px;
      white-space: nowrap;
      font-weight:300;
      color:#707070;  
      box-shadow:0 0 6px #ccc;
    
      &::before {
        content: " ";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        position: absolute;
        bottom: -5px;
      }
  }
  
  &:hover {
    div {
      display:inline-block;
    }  
  }
`;

const Frag = styled.div`
    display:inline-block;
    vertical-align: top;
`;

const Span = styled.span`
  font-size:${Theme.font.extraSmall.size};
  font-weight:normal;
  font-family:Circe-Rounded;
  display:block;
  color:#516F90;
  margin-top:4px;
`;

const SpanError = styled(Span)`
  color:${Theme.colors.error.text}
`;

const Select = styled(InputBase)`
  background:${Theme.colors.neutrals.white};
  box-sizing:border-box;
  cursor:pointer;
  position:relative;
  box-sizing: border-box;
  padding:0;

  height:auto;
  min-height:40px;


  &:focus-within {
   box-shadow:0px 0px 6px ${Theme.colors.action}
    border-color:${Theme.colors.secondary}
  }

  &.error {
    border-color:${Theme.colors.error.border};
  }
`;

const maxOptions = 5;
const optionsSize = 32;
const Options = styled.ul`
  padding:0;
  margin:4px 0;
  background:white;
  border:1px solid ${Theme.colors.neutrals.silver};
  box-shadow:0 0 6px ${Theme.colors.neutrals.silver};
  border-radius:2px;
  list-style:none;
  width:auto;
  display:inline-block;
  position: absolute;
  top: 100%;
  ${props => props.openTo === 'top' && `
    top:unset;
    bottom:100%;
  `}
  left: -1px;
  z-index:10;
  max-height: ${props => props.visibleOptionsQty ? props.visibleOptionsQty * optionsSize : maxOptions * optionsSize}px;
  overflow-x:hidden;
  overflow-y:scroll;

  li {
    font-size:${Theme.font.small.size};
    color:#707070;
    min-height:32px;
    cursor:pointer;
    padding: 8px;
    position:relative;
    box-sizing: border-box;

    >ul {
      display:none;
    }

    &.active,
    &:hover {
      background:${Theme.colors.info.bg};

      >ul {
        display:inline-block;
      }
    }

  }
`;

const TagsValues = styled.ul`
  list-style:none;
  padding:0 5px 3px;
  margin:0;
  display:flex;
  max-width: 300px;
  flex-wrap: wrap;

  &.small {
    max-width:140px;

    >li:not(.input) {
      white-space: pre-wrap;
    }
  }

  >li:not(.input) {
    border:1px solid #7C98B6;
    color:#516F90;
    background:#EAF0F6;
    padding:5px;
    margin-right:5px;
    border-radius:2px;
    font-size:12px;
    font-weight:500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;


    >svg {
      padding-left:8px;
      padding-right:3px;
    }

  }

  >li {
    margin-top:5px;
    input {
      display: inline-block;
      height: 30px;
      width:100%;
      min-width:80px;
      color:#333;
      font-size:14px;

      &::placeholder {
        color:#ccc;
      }
    }

    &.input {
      flex:1;
    }


  }
`;

const SelectHidden = styled.select`
    position: absolute;
    height: 1px;
    width: 1px;
    z-index: -1;
    opacity: 0;
`;

const TagInput = forwardRef(({
    name = '',
    options,
    error,
    valid,
    help,
    label,
    tooltip,
    canCreate,
    size,
    onChange,
    placeholder,
    visibleOptionsQty,
    openTo,
    ...props
},ref) => {

    if( !options || !options[0] )
        options = [];

    const randId = Math.random() * new Date().getTime();
    const safeName = ('tag' + name + randId.toString()).replace(/\W/gi, '');

    const [search, setSearch] = useState('');
    const [open, setOpen]                     = useState(!!props.autoFocus);
    const [newOptions, setNewOption]          = useState([]);
    const [defaultOptions, setDefaultOptions] = useState( options );
    const [selectedOpt, setSelected]          = useState([]);


    const containerRef = useRef();
    const searchRef    = useRef();
    const valueRef     = useRef();


    const isSelected = (value) => selectedOpt.some(key => key == value);

    let filter = React.useMemo(() => {
        return options.map((item,key)=>{
            if(isSelected(key))
                return null;

            if(item.name.toLowerCase().indexOf(search.toLowerCase()) === -1)
                return null;

            return key;
        }).filter(e => e != null);
    }, [options, selectedOpt, search]);


    if ( JSON.stringify(options) !== JSON.stringify(defaultOptions) )
        setDefaultOptions(options);

    options = defaultOptions.concat(newOptions);

    let timer;
    const handleChange = e => {
        clearTimeout(timer);
        const s = e.target.value;
        timer = setTimeout(() => setSearch(s), 150);
    }

    const handleSelect = (key) => {
        let selected = props.singleOption ? [key] : [...selectedOpt,key];

        setSelected( selected );

        searchRef.current.value = '';
        setSearch('');

        if ( typeof onChange === 'function' ) {
            let values = options.filter( (opt, k) => selected.indexOf( k ) !== -1 );
            onChange( values );
        }
    };

    const handleRemove = (index) => {
        if( !selectedOpt.length )
            return;

        selectedOpt.splice(selectedOpt.indexOf(index),1);
        let selected = [...selectedOpt];
        setSelected(selected);
        setTimeout(()=>{
            searchRef.current.focus();
        });

        if ( typeof onChange === 'function' ) {
            let values = options.filter( (opt, k) => selected.indexOf( k ) !== -1 );
            onChange( values );
        }
    }

    const handleClick = e => {
        let elm = e.target;

        if (containerRef.current.contains(elm))
            return;

        setOpen(false);
    };

    const handleFocus = e => {
        if (e.target.value)
            handleChange(e);

        setOpen(true);
    }

    const handleBlur = e => {
        if (document.activeElement !== document.body && !containerRef.current.contains(document.activeElement))
            setOpen(false);
    }

    const handleKey = e =>{
        let key = e.key.toLowerCase();

        switch ( key ) {
            case 'escape':
                setOpen(false);
                return;

            case 'backspace':
                return !searchRef.current.value.length && selectedOpt.length && handleRemove(selectedOpt[selectedOpt.length-1]);

            case 'arrowup':
            case 'arrowdown':
                if ( !open )
                    setOpen(true);

                let list = document.querySelectorAll( `#${safeName}-options li[value]` );

                if ( !list || !list.length )
                    return;

                let arr = Array.from(list);
                let activeIndex = arr.findIndex(elm => elm.classList.contains('active') );

                if ( activeIndex < 0 )
                    return arr[ key === 'arrowdown' ? 0 : arr.length - 1 ].classList.add( 'active' );

                let active = arr[activeIndex];
                active.classList.remove( 'active' );

                let toBeActive = ( key === 'arrowdown' )
                    ? ( arr[activeIndex + 1] )
                        ? arr[activeIndex + 1]
                        : arr[0]
                    : ( arr[activeIndex - 1] )
                        ? arr[activeIndex - 1]
                        : arr[arr.length - 1]
                ;

                toBeActive.classList.add('active');
                toBeActive.scrollIntoView( { block: 'center' } );

            case 'arrowright':
            case 'arrowleft':
                return;

            case 'tab':
                if (!!!searchRef.current.value)
                    return setTimeout(()=>!containerRef.current.contains(document.activeElement) && setOpen(false), 5);

            case 'enter':
                let option = document.querySelector( `#${safeName}-options .active` );
                if ( option ) {
                    e.preventDefault();
                    option.click();
                    setTimeout(() => searchRef.current.focus(), 10 );
                }
                return;

            default:
                setOpen(true);
                return;
        }
    }

    const addNewOption = (newObj) => {
        newObj = {
            ...newObj,
            value: newObj.name,
            new: true
        };

        setNewOption([...newOptions,newObj]);
        options.push(newObj);

        handleSelect(options.length - 1);
    }

    useEffect(()=>{

        let selected = options.map(function (item, key) {
            if (item.selected) return key;
        }).filter(function (e) {
            return e != null;
        });

        if (props.defaultValues && options.length > 0) {
            props.defaultValues.map( v => {
                let i = options.findIndex( item => item?.value?.toString() === v.toString() );
                if ( i !== -1 )
                    selected.push( i );
            });
        }

        if ( valueRef.current && valueRef.current.value ) {
            let fromHookFrom = Array.from( valueRef.current.children )
                .map( ( opt, k ) => opt.selected == true ? k : false )
                .filter( v => v !== false );

            selected = selected.concat( fromHookFrom );
        }

        setSelected( selected );

    },[defaultOptions]);

    useEffect(()=>{
        if ( !valueRef.current )
            return;

        Array.from(valueRef.current.children)
            .map((opt, k) => opt.selected = selectedOpt.indexOf(k) !== -1);
        valueRef.current.dispatchEvent(new Event('blur'));
    }, [selectedOpt]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    // integrate hook-form reset
    useEffect(()=>{
        const keys = Array.from(valueRef.current.children)
            .map((opt, k) => opt.selected ? k : false)
            .filter(k => k !== false);

        if ( selectedOpt.length !== keys.length
            || !selectedOpt.every((value, index) => value === keys[index]) ) {
            setSelected(keys);
        }
    });

    return(
        <Frag ref={containerRef}>
            {label && <Label htmlFor={name}>{label} {tooltip && <Help text={tooltip}/>} {props.Required && <RequiredText />}</Label>}
            <Select as='div' size={size} className={[open && 'open', error && 'error', valid && 'valid', 'tagEventPrevent']} onClick={()=>{searchRef.current.focus()}}>
                <TagsValues className={size}>
                    {selectedOpt.map((key)=> {
                        if (options[key]) {
                            if (options[key]?.disabled == true) {
                                return (<li key={key}>{options[key].name} </li>)
                            } else {
                                return (<li key={key}>{options[key].name} <Icon.TimesNarrow size='8px' color='#516F90' onClick={() => { handleRemove(key) }} /></li>)
                            }
                        }
                    })}
                    <li className='input'>
                        <input
                            type='text'
                            placeholder={placeholder}
                            ref={searchRef}
                            onChange={handleChange}
                            onKeyDown={handleKey}
                            autoFocus={!!props.autoFocus}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </li>
                </TagsValues>
                {open &&
                    <TagOptions
                        name={safeName}
                        filter={filter}
                        handleSelect={handleSelect}
                        options={options}
                        searchRef={searchRef}
                        addNewOption={addNewOption}
                        canCreate={canCreate}
                        visibleOptionsQty={visibleOptionsQty}
                        openTo={openTo}
                    />
                }
            </Select>
            {help && <Span>{help}</Span>}
            {error && <SpanError><img src={Ex} style={{marginRight:'6px'}} /> {error}</SpanError>}
            <SelectHidden name={name} multiple ref={mergeRefs([valueRef, ref])} tabIndex={-1}>
                {options.map((opt, key)=>(
                    <option value={opt.value} key={key}>{opt.name}</option>
                ))}
            </SelectHidden>
        </Frag>
    );
})

const TagOptions = ({name,filter,handleSelect,options,searchRef,addNewOption,canCreate,visibleOptionsQty,openTo}) => {
    let CreateOption;
    let underscored;
    if(searchRef.current && searchRef.current.value.length > 0){
        if ( canCreate && !options.find(opt => opt.name === searchRef.current.value))
            CreateOption = (<li className='active' onClick={()=>{addNewOption({name:searchRef.current?.value})}} value={searchRef.current.value}>Create option "<u className='tagEventPrevent'>{searchRef.current.value}</u>"</li>);

        const searchToUnderscore = searchRef.current ? searchRef.current.value.replaceAll('(','\\(').replaceAll(')','\\)') : '';
        underscored = new RegExp('('+searchToUnderscore+')','giu');
    }

    let noDataOption = '';
    if (!options.length || (filter.filter(i => options[i].value !== undefined).length < 1 && !CreateOption)) {
        let msg = searchRef.current && searchRef.current.value.length < 1
            ? 'No options left'
            : 'No match for ';
        noDataOption = (<li
                className='tagEventPrevent'
                dangerouslySetInnerHTML={{
                    __html: `${msg} <u>${searchRef.current?.value}</u>`
                }}/>
        );
    }

    return (
        <Options id={`${name}-options`} visibleOptionsQty={visibleOptionsQty} openTo={openTo}>
            {
                options.length > 0 ? filter.map((key, index)=> {
                    if (options[key].value === undefined)
                        return !noDataOption ? (
                            <li
                                key={key}
                                className='tagEventPrevent'
                            >
                                <small><strong>{options[key].name}</strong></small>
                            </li>
                        ) : null;
                    else
                        return(
                            <li
                                className={`tagEventPrevent ${index === 0 ? 'active' : ''}`}
                                value={options[key].value}
                                key={key}
                                onClick={() => {
                                    handleSelect(key)
                                }}>
                                {underscored ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: options[key].name.replace(underscored, '<u>$1</u>')
                                        }}
                                    />
                                ) : options[key].name }
                            </li>
                        );
                }) : null
            }
            {CreateOption}
            {noDataOption}
        </Options>
    );
}


export default TagInput;