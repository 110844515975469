import React, {useState, useContext, useEffect, useCallback, useRef} from "react";
import {useForm} from "react-hook-form";
import IncidentsContext from "../context";
import {Button, CustomDropdown, Dropdown, Icon, Input, Spacer, Theme} from "@dspworkplace/ui";
import Day from "../../../components/Calendar/dayNew";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import API from "../api";
import {toast} from "../../../components/Toasts";
import FilterServerResolver from "../../../components/Filter/filterResolver.server";
import DatePicker from '../../../components/Calendar/picker';
import moment from 'moment';

let searchTimeout;

export const matchingConfig = {
    station:'number',
    search:'like',
    dateRange:'date',
    incidentType:'number',
    userStatus:'number'
}

const IncidentsFilter = ({ initialLoad = false }) => {
    const [state, dispatch] = useContext(IncidentsContext);
    const [selectedStation] = useSelectedStation();
    let resetDueTimes = () => {};
    const {register, reset,watch} = useForm();
    const dateRef = useRef(null);

    const defaultFilters = { incidentType: "0", userStatus: "5", dueTimes: [], dateRange: false, search: "" };
    const incidentFilters = JSON.parse(sessionStorage.getItem('incidentFilters')) || defaultFilters;
    const [customFilter, setCustomFilter] = useState(incidentFilters);

    const { dateRange, dueTimes, incidentType, userStatus, search } = incidentFilters;

    const loadFiltersFromSession = () => {
        const storedFilters = sessionStorage.getItem('incidentFilters');
        return storedFilters ? JSON.parse(storedFilters) : defaultFilters;
    };
    const saveFiltersToSession = (filters = {}) => {
        const existingFilters = loadFiltersFromSession();
        const updatedFilters = { ...existingFilters, ...filters };
        sessionStorage.setItem('incidentFilters', JSON.stringify(updatedFilters));
    };

    const loadList = useCallback((filters,savedFilters) => {
        dispatch({
            type: 'SET_LOADED',
            payload: false
        })
        return API.fetchList(filters).then(
            response => {
                const data = response?.data?.data?.incident.result;
                const total = response?.data?.data?.incident.total || 0;
                dispatch({
                    type: 'SET_INCIDENTS',
                    payload: {
                        incident : data,
                        totalIncidents:total,
                        page:0,
                        filter : savedFilters,
                        incidentData: data
                    }
                })
            },
            error => {
                if ( error.response )
                    toast({
                        type: 'error',
                        title: 'Error',
                        content: error.response.data.message
                    })
            }
        );
    }, [dispatch, selectedStation]);

    const setFilter = (field, value) => {
        let p = {...state.filters};
        p[field] = value;

        const filterObj = new FilterServerResolver(p,matchingConfig)
        // if (filterObj.dateRange) {
        //     filterObj.dateRange.between[0] = moment(filterObj.dateRange.between[0]+' 00:00:00').utc().format('Y-MM-DD HH:mm:ss');
        //     filterObj.dateRange.between[1] = moment(filterObj.dateRange.between[1]+' 23:59:59').utc().format('Y-MM-DD HH:mm:ss');
        // }
        loadList(filterObj,p)
        saveFiltersToSession({ [field]: value });
    };

    const stationChange = (value) => {
        reset({
            search: "",
            dueTimes: "",
            dateRange: "reset",
            station: selectedStation,
            userStatus: "5",
            incidentType: "0",
        });
        resetDueTimes();
        dateRef.current.clear();
        setFilter("station",setFilter)
        sessionStorage.removeItem('incidentFilters');
    };

    const resetFilter = () => {
        sessionStorage.removeItem('incidentFilters');
        const f = {'station':selectedStation,'userStatus':'5','incidentType':'0'};
        const filterObj = new FilterServerResolver(f,matchingConfig)
        loadList(filterObj,f)
    };

    const setSearch = evt => {
        const value = evt.target.value;
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=>setFilter('search', value), 600);
    };

    const resetFilters = evt => {
        reset({'search':'','dueTimes':'','dateRange':'reset','station':selectedStation,'userStatus':'5',incidentType:'0'})
        resetDueTimes();
        resetFilter();
        if (customFilter["dateRange"] !== undefined) {
            delete customFilter["dateRange"];
        }
        dateRef.current.clear();
    };

    useEffect(()=>{
        reset(state.filters);
    }, [state.currentView]);

    useEffect(() => {
        if (initialLoad) {
            filtersIncidents()
        }
    }, [initialLoad])

    const userStatusOpt = [{'name':'All ','value':'5'},{'name':'Active','value':'2'},{'name':'Inactive ','value':'0'},{'name':'Terminated ','value':'-2'},{'name':'Offboarded ','value':'-1'},{'name':'Onboarding ','value':'1'}];

    const getDueStatus = (due, isCompleted, isDraft) => {
        if (isCompleted) return false;     
        if (isDraft == "1") return 'draft';
        let diff = due - new Date().getTime();
        let dur = moment.duration(diff);
        const error = diff < 0;
        let status = 'late';
        if (diff >= 0 && dur.asMinutes() <= 30) {
            status = "now";
        } else if (diff > 0 && dur.asHours() >= 1 && dur.asHours() <= 24) {
            status = "24h";
        } else if (!error) {
            status = "later";
        } else {
            if (error) {
                status = "late";
            }
        }
        return status;
    };

    const filtersIncidents = async (field, value) => {
        let filters = await loadFiltersFromSession();
        if (field) {
            filters[field] = value;
        }
        saveFiltersToSession(filters);
        setCustomFilter(filters);
        dispatch({
            type: "SET_LOADED",
            payload: false,
        });

        let len = state.incidentData;
        let data = [];
        if ((filters["dueTimes"] && filters["search"]) && (filters["dueTimes"] === '' && filters["search"] === '')) {
            data = state.incidentData;
        } else {
            let results = [];
            for (let i = 0; i < len.length; i++) {
                let incidentTypePass = false;
                let dueTimesPass = false;
                let incidentDatePass = false;
                let searchPass = false;

                if (filters["incidentType"] !== undefined && filters["incidentType"] != "") {
                    if (filters["incidentType"] == 0) {
                        incidentTypePass = true;
                    } else if (len[i]["incidentType"].id == filters["incidentType"]) {
                        incidentTypePass = true;
                    } else {
                        incidentTypePass = false;
                    }
                } else {
                    incidentTypePass = true;
                }

                if (filters["dueTimes"] !== undefined && filters["dueTimes"].length > 0) {
                    let status = getDueStatus(len[i].nextDueDate.timestamp * 1000, len[i].isCompleted, len[i].isDraft);
                    if (filters["dueTimes"].includes("now") && status == "now") {
                        dueTimesPass = true;
                    } else {
                        if (filters["dueTimes"].includes("24h") && status == "24h") {
                            dueTimesPass = true;
                        } else {
                            if (filters["dueTimes"].includes("later") && status == "later") {
                                dueTimesPass = true;
                            } else {
                                if (filters["dueTimes"].includes("late") && status == "late") {
                                    dueTimesPass = true;
                                } else {
                                    if (filters["dueTimes"].includes("draft") && status == "draft") {
                                        dueTimesPass = true;
                                    } else {
                                        dueTimesPass = false;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    dueTimesPass = true;
                }

                if (filters["dateRange"] !== undefined && filters["dateRange"] != "") {
                    let dateRange = filters["dateRange"].split(" – ");
                    let startDate = new Date(dateRange[0]);
                    let endDate = new Date(dateRange[1]);
                    let dateTime = new Date(len[i].incidentDate.timestamp * 1000);
                    incidentDatePass = (dateTime >= startDate && dateTime <= endDate) ? true : false;
                } else {
                    incidentDatePass = true;
                }

                if (customFilter["search"] !== undefined && filters["search"] != "") {
                    if (
                        len[i]["driver"].fullName != null &&
                        len[i]["driver"].fullName.toString().toLowerCase().includes(filters["search"].toString().toLowerCase())
                    ) {
                        searchPass = true;
                    } else {
                        if (
                            len[i]["driver"].jobTitle != null &&
                            len[i]["driver"].jobTitle
                                .toString()
                                .toLowerCase()
                                .includes(filters["search"].toString().toLowerCase())
                        ) {
                            searchPass = true;
                        } else {
                            if (
                                len[i]["assignTo"].name != null &&
                                len[i]["assignTo"].name
                                    .toString()
                                    .toLowerCase()
                                    .toString()
                                    .includes(filters["search"].toLowerCase())
                            ) {
                                searchPass = true;
                            } else {
                                if (
                                    len[i]["assignTo"].jobTitle != null &&
                                    len[i]["assignTo"].jobTitle
                                        .toString()
                                        .toLowerCase()
                                        .toString()
                                        .includes(filters["search"].toLowerCase())
                                ) {
                                    searchPass = true;
                                } else {
                                    if (
                                        len[i].category_name != null &&
                                        len[i].category_name
                                            .toString()
                                            .toLowerCase()
                                            .includes(filters["search"].toString().toLowerCase())
                                    ) {
                                        searchPass = true;
                                    } else {
                                        searchPass = false;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    searchPass = true;
                }

                if (incidentTypePass && dueTimesPass && incidentDatePass && searchPass) {
                    results.push(len[i]);
                }
            }
            data = results;
        }

        dispatch({
            type: "SET_INCIDENTS",
            payload: {
                filter: state.filters,
                incident: data,
                totalIncidents: data.length,
                page: 0,
                incidentData: state.incidentData,
                canDelete: state.canDelete
            },
        });
    }

    return (
        <div>
            <Dropdown
                ref={register}
                size={'extraSmall'}
                name='userStatus'
                label='Driver Status'
                options={userStatusOpt}
                onChange={(item) => setFilter('userStatus',item.value)}
                defaultValue={userStatus}
            />
            <Spacer inline right={3}/>
            <StationSelector
                ref={register}
                name='station'
                label='Station'
                size='extraSmall'
                onChange={v => stationChange(v)}
            />
            <Spacer inline right={3}/>
            <Dropdown
                ref={register}
                name='incidentType'
                label='Incident Type'
                size='small'
                options={state.filterData.incidentTypes}
                defaultValue={incidentType}
                onChange={(v) => filtersIncidents("incidentType", v.value.toString())}
            />
            <Spacer inline right={3}/>

            <CustomDropdown
                ref={register}
                size={'small'}
                name='dueTimes'
                label='Due Time'
                options={state.filterData.dueTimes}
                multiple
                onChange={(v) => filtersIncidents("dueTimes", v)}
                defaultValue={dueTimes}
                resetCallback={(fn) => {
                    resetDueTimes = fn;
                }}
            />
            <Spacer inline right={3} />

            <DatePicker
                //ref={register}
                name="dateRange"
                label="Incident Date  (two values required)"
                range="date"
                placeholder="MM/DD/YYYY - MM/DD/YYYY"
                onChange={v => {
                    v ? filtersIncidents("dateRange", v) : filtersIncidents("dateRange", "");
                }}
                defaultRangeValue={dateRange ? [new Date(dateRange.split(" ")[0]), new Date(dateRange.split(" ")[2])] : false}
                ref={dateRef}
            />
            <Spacer inline right={3}/>

            <Input
                ref={register}
                label="Search"
                name="search"
                placeholder="Filter drivers by name, email, phone number, etc"
                onChange={(event) => {
                    filtersIncidents("search", event.target.value);
                }}
                defaultValue={search}
            >
                <Icon.Search color={Theme.colors.info.border} size={'24px'}/>
            </Input>
            <Spacer inline right={5}/>

            <Button style={{verticalAlign: 'bottom'}} onClick={resetFilters}>Reset</Button>
        </div>
    )
}

export default IncidentsFilter;
