import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {Icon, Theme,Input} from "@dspworkplace/ui";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

import moment from "moment";


const Container = styled.div`
    position:absolute;
    left:0;
    top:40;
    background:#FFFF;
    z-index: 10000;
    
    >.dayPicker {
         border:1px solid ${Theme.colors.neutrals.silver};
         box-shadow: 0px 3px 6px ${Theme.colors.neutrals.gray}19;
         font-family:circe-rounded;
        .DayPicker-Caption {
            text-align:center;
        }
    }
`


const handleDateFormat = (date,format) => {

    const utc = moment.utc(date).format();
    const formatted = moment(date).format(format);
    return {utc:utc,original:formatted};
}

const mergeRefs = refs => {
    return value => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(value)
            } else if (ref != null) {
                ref.current = value
            }
        })
    }
};


const NavBarContainer = styled.div`
    position:absolute;
    display: flex;
    align-content: left;
    justify-content: space-between;
    width: 100%;
    top: 1.3rem;
    padding: 0 1rem;

    >svg {
        cursor:pointer;
    }
`;
const NavBar = ({onPreviousClick,onNextClick,className}) => {

    return (
        <div className={className}>
            <NavBarContainer>
                <Icon.ArrowLeft size='18px' color={Theme.colors.info.border} onClick={() => onPreviousClick()} />
                <Icon.ArrowRight size='18px' color={Theme.colors.info.border} onClick={() => onNextClick()} />
            </NavBarContainer>
        </div>
    )
}

const InputDate = React.forwardRef(({ name, defaultValue, defaultOpen = false, disabled, ...props }, ref) => {
    const [date,setDate] = useState({utc:'',original:''});
    const [error,setError] = useState('');
    const [open,setOpen] = useState(false);
    const innerRef = useRef();
    const inputRef = useRef();

    const innerFormat = 'MM/DD/YYYY';
    const separator = '/';
    const format = 'YYYY-MM-DD';

    const handleDayClick = (day,{selected}) => {
        if(!selected){
            const date = handleDateFormat(day,innerFormat);
            setDate(date);
            setError('')
            setOpen(false)

            if(props.onChange){
                props.onChange(date);
            }
        }
    }

    const dateMask = (e) => {

        if (open) setOpen(false);

        const v = e.target.value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, `$1${separator}$2`)
            .replace(/(\d{2})(\d)/, `$1${separator}$2`)
            .replace(/(\d{4})\d+?$/, '$1');
        setDate({ ...date, original: v })
    }

    const handleBlur = (e) => {
        if(!e.target.value){
            return
        }

        const date = moment(e.target.value,innerFormat,true);
        const isValid = date.isValid();

        if(!isValid) setError('Wrong format '+innerFormat)
        if(isValid) {
            setError('');
            const newDate = handleDateFormat(date.format(innerFormat),innerFormat);
            setDate(newDate);
        }

    }

    const handlePopup = (e) => {
        if(e.target && (e.target.attributes.disabled || e.target.attributes.d)){
            return false
        }
        if ( !innerRef.current.contains( e.target ) ){
            setOpen( false );
        }else{
            if(!open) setOpen(true);
        }
    }

    const solveInitialData = (inputValue) => {
        if(!inputValue) {return};

        if(inputValue){
            const originalDate= moment(inputValue,format,true).add(12, 'hours');
            if(originalDate.isValid()){

                const newDate = handleDateFormat(originalDate,innerFormat);
                if(newDate.utc != date.utc){
                    setDate(newDate);
                    setError('')
                }
            }else{
                setError('Invalid default date')
            }
        }
    }

    useEffect(()=>{
        if(date.utc.length > 0) return;
        const inputValue = inputRef.current.value  ? inputRef.current.value : defaultValue ;
        if(inputValue) solveInitialData(inputValue)
    })

    useEffect(() => {
        if (defaultOpen) {
            setOpen(true)
        }
        document.addEventListener('mouseup', handlePopup, false);

        return () => document.removeEventListener('mouseup', handlePopup, false);
    }, [])
    
    const addLeadingZero = (event) => {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text/plain');
        const dateParts = pastedText.split('/');
        const formattedDate = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]).toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        handleBlur({ target: { value: formattedDate } });
        setDate({ ...date, original: moment(formattedDate).format("MM/DD/YYYY") })
        if (open) setOpen(false);
    }; 

    const originalDateIsValid = moment(date.original,innerFormat,true).isValid();
    return (
        <div ref={innerRef} style={{display: 'inline-block'}}>
            <div style={{position:'relative'}}>
                <Input
                    {...props}
                    value={date.original}
                    onChange={dateMask}
                    onBlur={handleBlur}
                    error={error ? error : props.error ? props.error : ''}
                    disabled={disabled  ? true : false}
                    onPaste={(event)=>{addLeadingZero(event)}}
                >
                    <Icon.Calendar size='24px' style={{position:'absolute', right:0}}  disabled={disabled  ? true : false} />
                </Input>
                {open &&
                <Container>
                    <DayPicker
                        selectedDays={originalDateIsValid ? new Date(date.original) : null}
                        month={originalDateIsValid ? new Date(date.original) : null}
                        onDayClick={handleDayClick}
                        showWeekNumbers={true}
                        navbarElement={NavBar}
                        className='dayPicker'
                    />
                </Container>
                }
            </div>
            <input type='hidden' name={name} ref={mergeRefs([inputRef,ref])} defaultValue={error ? '' : date.utc} />
        </div>
    )
})


export default InputDate;
