import {fullDateFromUTC} from "../../components/TimeFormat";

const process = (state, action) => {
    const {type, payload, callback = ()=>{}} = action;
    let filteredIncidents;
    let ret;

    const sortAndFilter = () => {
        return sortBy(state.incidents, state.sortBy);
    };

    const gotoPhase = phase => {
        let currentPhase = state.incident.phases.findIndex(p => p.current === true);
        let goto = typeof phase === 'number'
            ? phase
            : phase === 'next'
                ? currentPhase + 1
                : phase === 'prev'
                    ? currentPhase - 1
                    : null;

        if ( goto === null )
            return;

        const phases = state.incident.phases;

        if ( phases[goto] === undefined )
            return;

        phases[currentPhase].current = false;
        phases[currentPhase].canEdit = false;
        phases[goto].current = true;
        phases[goto].enabled = true;

        return true;
    };

    switch (type) {
        // list page
        case 'SET_INCIDENTS':
            if(payload.page === 0){
                state.filters = payload.filter;
                state.incidents = payload.incident;
                state.incidentData = payload.incidentData;
            }else{
                state.incidents = [...state.incidents,...payload.incident];
            }
            state.page = payload.page
            state.totalIncidents = payload.totalIncidents
            state.canDelete = payload.canDelete

            if(payload.incidentType){
                state.filterData.incidentTypes = [{value:'0', name:'All'},...payload.incidentType.map((t)=>({name:t.name,value:t.id.toString()}))];
            }

            ret = {
                ...state,
                filtered: sortAndFilter(),
                filterData: { ...state.filterData},
                loaded: true
            };
            break;

        case "SET_SORT_BY":

            let index = state.sortBy.findIndex(
                (criteria) => criteria.field == payload.field
            );

            if (index >= 0) {
                let criteria = state.sortBy[index];

                if (criteria.direction == 0) criteria.direction = 1;
                else if (criteria.direction == 1)  criteria.direction = 0;
            } else {
                state.sortBy = [];
                state.sortBy.push({
                    field: payload.field,
                    direction: 0,
                });
            }

            filteredIncidents = sortBy(state.filtered, state.sortBy);
            return { ...state, filtered: filteredIncidents };

        case 'REMOVE_INCIDENT':
            const incident = state.incidents.findIndex(obj => obj.id === payload.id);
            state.incidents.splice(incident,1);
            ret = {
                ...state,
                filtered: sortAndFilter(),
                loaded: true
            };
            break;

        case 'SET_FILTER_DATA':
            ret = {...state, filterData: {...state.filterData, ...payload}};
            break;

        case 'SET_VIEW':
            let currentView = state.views.find(v => v.id === payload.id);
            ret = {...state, currentView, filters: currentView.data};
            break;

        case 'SET_FILTERS':
            state.filters = payload;
            state.filtered = sortAndFilter();
            ret = {...state};
            break;

        case 'RESET_FILTERS':
            state.filters = payload;
            state.filtered = sortAndFilter();
            ret = {...state};
            break;

        // form page
        case 'SET_INCIDENT':
            ret = {...state, incident: payload, loaded: true};
            break;

        case 'MERGE_VALUES':
            let [current, data] = payload;

            current.steps.forEach((step, k) => {
                step.fields.forEach((field, j) => {
                    if (data.steps[step.id].fields[field.id] instanceof FileList == false) {
                        field.props.defaultValue = data.steps[step.id].fields[field.id];
                    }
                });
            });

            ret = {...state};
            break;

        case 'GOTO_PHASE':
            gotoPhase(payload);
            ret = {...state, loaded: true};
            break;

        case 'GOTO_NEXT_PHASE':
            gotoPhase('next');
            ret = {...state, loaded: true};
            break;

        case 'CHANGE_OPTION_VALUES':
            state.incident.options[payload.fieldId] = payload.options
            ret = {...state};
            break;

        case 'CHANGE_INCIDENT_DATETIME_VALUE':
            // state.incident.datetime = payload
            state.incident.utctime = payload
            ret = {...state};
            break;

      // common
        case 'SET_LOADED':
            ret = {...state, loaded: payload};
            break;
        case 'SET_PAGE':

            return {...state,page:payload.page}
        default:
            ret = {...state};
            break;
    }

    callback(ret);

    return ret;
}

const DevicesReducer = (state, action) => {
    if ( !Array.isArray(action) )
        action = [action];

    action.forEach(a => {
        state = process(state, a);
    })

    return {...state};
};

const sortBy = (collection, criterias) => {
   criterias.reverse().map((c) => {
    let d = c.direction;
         collection.sort((f, s) => {
                switch (c.field) {
                    case "driver":
                        return d === 0
                            ? f.driver?.fullName?.trim().localeCompare(s.driver?.fullName?.trim())
                            : s.driver?.fullName?.trim().localeCompare(f.driver?.fullName?.trim());
                    case "assignTo":
                            return d === 0
                                ?  f.assignTo?.name?.trim().localeCompare(s.assignTo?.name?.trim())
                                : s.assignTo?.name?.trim().localeCompare(f.assignTo?.name?.trim());
                    case "due":
                        return d === 0
                            ? (f.nextDueDate.timestamp * 1000).toString().localeCompare((s.nextDueDate.timestamp * 1000).toString(),undefined,{numeric: true})
                            : (s.nextDueDate.timestamp * 1000).toString().localeCompare((f.nextDueDate.timestamp * 1000).toString(),undefined,{numeric: true});
                    case "dateTime":
                        return d === 0
                            ? fullDateFromUTC(f.dateTime.timestamp * 1000).localeCompare(fullDateFromUTC(s.dateTime.timestamp * 1000),undefined,{numeric: true})
                            : fullDateFromUTC(s.dateTime.timestamp * 1000).localeCompare(fullDateFromUTC(f.dateTime.timestamp * 1000), undefined, { numeric: true });
                    case "progress":
                        return d === 0
                            ? f.progress - s.progress
                            : s.progress - f.progress;
                    default:
                        return d === 0
                            ? f[c.field].localeCompare(s[c.field])
                            : s[c.field].localeCompare(f[c.field]);
                }
            });
    });

    return collection;
};

export default DevicesReducer;
