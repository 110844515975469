import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Spacer, SubTitle, Icon, Dropdown, Theme, Toggle, Alert, CheckBox } from "@dspworkplace/ui";
import TagInput from "../../../components/TagInput";
import { Label } from "../../../components/UI";
import { getCompanyId, useAuth, upload } from "../../../Auth";
import SettingsContext from "../context";
import { toast } from "../../../components/Toasts";
import styled from "styled-components";
import { Table, TableData, TableFooter, TableHeader, TableRow } from "../../../components/Table";
import Empty from "../../../components/Empty";
import { confirm } from "../../../components/Confirm";
import Modal from "../../../components/Modal";
import { dialogPromise, portalPromise, loadingDialog } from "../../../components/Dialog";
import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import { showToast } from "../../../Utilities";
import "./setting.css";
import TimePicker from "../../../components/TimePicker";
import moment from "moment";
import { asDatabaseTime, databaseToMilliseconds } from "../../../components/TimeFormat";
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";
import { TabList } from "../../../components/Tab";
import { DropUploader } from "../../../components/DropUploader";
import {IsGranted, AccessCheck} from "../../../security";
import { canEditCompanySetting } from "../../Scheduler/Common/AccessCheck";
import { mimeTypes } from "../../../patterns";
import { alert } from "../../../components/Alert";
import CKeditorComponent, { CKeditorModalComponent } from "../../../components/CkEditor/CKeditorComponent";

const Container = styled.div`
    display: grid;
    width: 100%;
`;

const SpanTag = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-family: Circe-Rounded;
    display: block;
    color: rgb(81, 111, 144);
    margin-top: 4px;
`;

const Description = styled.div`
    border: 1px gray dashed;
    padding-left: 10px;
    padding-bottom: 5px;
`;

const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${(props) => (props.columnGap ? props.columnGap : "8px")};
    width: ${(props) => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${(props) => (props.width ? props.width : "612px")};
    }

    div {
        width: ${(props) => (props.width ? props.width : "612px")};
    }

    div.tagEventPrevent {
        width: ${(props) => (props.width ? props.width : "612px")};
    }

    ul {
        max-width: ${(props) => (props.width ? props.width : "612px")};
    }

    ul.big {
        max-width: unset;
        width: auto;
    }

    input {
        width: 100%;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const AlertDiv = styled.div`
    width: 50%;
`;

export default function IncidentSettings() {
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit, errors, getValues, reset, formState } = useForm();
    const { register: register1, errors: errors1, handleSubmit: handleSubmit1, formState: formState1 } = useForm();
    const [incidentSettings, setIncidentSettings] = useState([]);
    const [managers, setManagers] = useState([]);
    const [disabledUsers, setDisabledUsers] = useState([]);
    const [authoriseCompleteIncident, setAuthoriseCompleteIncident] = useState([]);
    const [selectedStation] = useSelectedStation();
    const allValues = getValues();
    const incidentType = { 1: "Corrective Action", 2: "Coaching", 3: "Thank You", 4: "Employee Note" };
    let { incidentSummeryTime } = allValues;
    useEffect(() => reset(state.company), [state.company]);

    const getIncidentSettings = async () => {
        const params = {
            actions: {
                response: {
                    IncidentHrSettings: {
                        custom: {
                            functionName: "getIncidentSettings",
                            get: "incidentSettings",
                            criteria: {
                                companyId: localStorage.getItem("company"),
                            },
                        },
                    },
                },
            },
        };
        let incidentSettingsRes = await api.post("/api/lazy/manage/data", params);
        setIncidentSettings(incidentSettingsRes.data.data.incidentSettings);
        state.incidentSetting = incidentSettingsRes.data.data.incidentSettings;
    };
    useEffect(() => {
        getIncidentSettings();
        fetchManager(selectedStation);
    }, []);

    let summeryMonthArr = [{ name: "All-time", value: "0" }];
    for (let month = 1; month <= 12; month++) {
        summeryMonthArr.push({ name: month.toString(), value: month.toString() });
    }
    const handleCompanyIncidentSummerySetting = (data, evt) => {
        let form = evt.target;
        form.querySelector("button").disabled = true;
        let param = {
            actions: {
                update: {
                    Company: {
                        update_company: {
                            findBy: {
                                id: getCompanyId(),
                            },
                            updateRecord: {
                                incidentSummeryTime: data.incidentSummeryTime,
                            },
                        },
                    },
                },
            },
        };
        state.company.incidentSummeryTime = data.incidentSummeryTime;
        api.post("/api/lazy/manage/data", param).then(() => {
            form.querySelector("button").disabled = false;
            toast({
                type: "success",
                title: "Incident",
                content: "Incident Summary Time Updated.",
            });
        });
    };

    const deleteIncidentSetting = async (incidentSetting) => {
        const confirmAlertVar = await confirm({
            icon: true,
            text: "Are you sure you want to delete incident setting?",
        });
        if (!confirmAlertVar) {
            return false;
        } else {
            let param = {
                actions: {
                    delete: {
                        IncidentHrSettings: {
                            delete_incidenthrsetting: {
                                findOneBy: {
                                    id: incidentSetting.id,
                                },
                            },
                        },
                    },
                },
            };
            api.post("/api/lazy/manage/data", param).then(() => {
                dispatch({
                    type: "DELETE_INCIDENT_SETTING",
                    payload: {
                        data: incidentSetting,
                    },
                });
                toast({
                    type: "success",
                    title: "Incident",
                    content: "Incident Hr setting deleted.",
                });
            });
        }
    };

    const editIncidentSettingForm = (incidentSetting) => {
        return dialogPromise((success, cancel) => {
            return (
                <AddEditIncidentSettingForm
                    success={success}
                    cancel={cancel}
                    api={api}
                    incidentSetting={incidentSetting}
                    edit={true}
                    dispatch={dispatch}
                    state={state}
                    incidentType={incidentType}
                />
            );
        });
    };

    const addIncidentSettingForm = () => {
        return dialogPromise((success, cancel) => {
            return (
                <AddEditIncidentSettingForm
                    success={success}
                    cancel={cancel}
                    api={api}
                    dispatch={dispatch}
                    state={state}
                    incidentSetting={[]}
                    edit={false}
                    incidentType={incidentType}
                />
            );
        });
    };

    const openSlidePreview = (incidentSetting) => {
        return dialogPromise((success, cancel) => {
            return <TemplateView success={success} cancel={cancel} incidentSetting={incidentSetting} />;
        });
    };

    const onSubmit = async (data, evt) => {
        try {
            let selectedUser = data.authoriseCompleteIncident.filter(
                (u) => !(disabledUsers.includes(parseInt(u)) || disabledUsers.includes(u.toString()))
            );
            let param = {
                actions: {
                    response: {
                        Station: {
                            custom: {
                                functionName: "updateAuthoriseCompleteIncident",
                                get: "updateAuthoriseCompleteIncident",
                                criteria: {
                                    stationId: selectedStation,
                                    authoriseCompleteIncident: selectedUser,
                                },
                            },
                        },
                    },
                },
            };
            await api.post("/api/lazy/manage/data", param);

            await showToast({
                type: "success",
                title: "Authority to Complete and Delete Supervisory Incident Forms",
                content: "Updates completed",
            });
        } catch (e) {
            // await showToast({ type: 'error', title: 'Default Auto Users Chat', content: JSON.stringify(e) });
            // form.querySelector('button').disabled = false;
            // setLoadingSave(false);
        }
    };

    function onStationChange(data) {
        fetchManager(data);
    }

    function formatForTagInputForUser(list, defaultUsers, user) {
        if (list) {
            if (list.includes(parseInt(user.id)) || list.includes(user.id.toString())) {
                if (defaultUsers.includes(parseInt(user.id)) || defaultUsers.includes(user.id.toString())) {
                    return {
                        name: user.firstName + " " + user.lastName,
                        value: user.id,
                        selected: true,
                        disabled: true,
                    };
                } else {
                    return { name: user.firstName + " " + user.lastName, value: user.id, selected: true };
                }
            } else {
                return { name: user.firstName + " " + user.lastName, value: user.id };
            }
        }
    }

    async function fetchManager(stationId) {
        let users = [];
        if (managers && managers.length == 0) {
            let param = {
                actions: {
                    response: {
                        User: {
                            custom: {
                                functionName: "findManagers",
                                get: "managers",
                                criteria: {
                                    company: getCompanyId(),
                                    extraRole: "ROLE_CENTRAL_DISPATCHER",
                                },
                            },
                        },
                    },
                },
            };
            let res = await api.post("/api/lazy/manage/data", param);
            users = await res.data.data.managers;
            setManagers(users);
        } else {
            users = managers;
        }

        let completeIncindetParam = {
            actions: {
                response: {
                    Station: {
                        custom: {
                            functionName: "getAuthoriseCompleteIncident",
                            get: "completeIncident",
                            criteria: {
                                company: getCompanyId(),
                                stationId: stationId,
                            },
                        },
                    },
                },
            },
        };
        let response = await api.post("/api/lazy/manage/data", completeIncindetParam);

        let selectedUsers = response.data.data.completeIncident.all;
        let defaultUsers = response.data.data.completeIncident.defaultUser;
        setDisabledUsers(defaultUsers);
        let tags = [];
        let user;
        setAuthoriseCompleteIncident([]);
        for (let i = 0; i < users.length; i++) {
            user = users[i];
            tags.push(formatForTagInputForUser(selectedUsers, defaultUsers, user));
        }

        setAuthoriseCompleteIncident(tags);
        return true;
    }

    const values = getValues();
    return (
        <Container>
            <section>
                <SubTitle>Corrective Action Summary</SubTitle>
                <div style={{ width: 560 }}>
                    <form
                        key="corrective_action_form"
                        name="corrective_action_form"
                        onSubmit={handleSubmit1(handleCompanyIncidentSummerySetting)}
                    >
                        <Spacer bottom={3} />
                        <Dropdown
                            name="incidentSummeryTime"
                            placeholder="Select Summary Month"
                            label="Corrective Action Summary Time"
                            options={summeryMonthArr}
                            defaultValue={incidentSummeryTime}
                            ref={register1({
                                required: {
                                    value: true,
                                },
                            })}
                            Required={true}
                            valid={!errors1.incidentSummeryTime && values.incidentSummeryTime}
                            error={errors1 && errors1.incidentSummeryTime && "Required incident summery time field"}
                        />
                        <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                            <Spacer bottom={3} />
                            <Button type="primary">Save</Button>
                        </IsGranted>
                    </form>
                </div>
            </section>
            <Spacer bottom={15} />
            <div>
                <SubTitle style={{ float: "left", width: "50%" }}>List Category & Template</SubTitle>
                <Button disabled={canEditCompanySetting() ? false : true} onClick={addIncidentSettingForm}>Add Category & Template</Button>
            </div>
            <section>
                <Spacer bottom={5} />
                <Table width={"780px"}>
                    <TableHeader
                        headers={[
                            { label: "Incident Type", width: "30%" },
                            { label: "Category", width: "30%" },
                            { label: "Template", width: "20%" },
                            { label: "Action", width: "20%" },
                        ]}
                    />
                    {incidentSettings && incidentSettings.length ? (
                        incidentSettings.map((incidentSetting, k) => (
                            <TableRow key={k} style={{ flexWrap: "wrap" }}>
                                <TableData width="30%">{incidentType[incidentSetting.type]}</TableData>
                                <TableData width="30%">{incidentSetting.category}</TableData>
                                <TableData width="20%">
                                    <Icon.Eye
                                        size="20px"
                                        color={Theme.colors.info.border}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            openSlidePreview(incidentSetting);
                                        }}
                                    />
                                </TableData>
                                <TableData width="20%">
                                    <Spacer inline right={3}>
                                        <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                                        <Icon.Edit
                                            size="20px"
                                            color="#7C98B6"
                                            style={{ position: "relative", top: "3px" }}
                                            onClick={() => {
                                                editIncidentSettingForm(incidentSetting);
                                            }}
                                        />
                                        </IsGranted>
                                    </Spacer>
                                    <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.D]}>
                                    <Icon.Times
                                        size="20px"
                                        color="#7C98B6"
                                        onClick={() => {
                                            deleteIncidentSetting(incidentSetting);
                                        }}
                                    />
                                    </IsGranted>
                                </TableData>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow style={{ flexWrap: "wrap" }}>
                            <TableData width="100%" style={{ justifyContent: "center" }}>
                                <Empty />
                            </TableData>
                        </TableRow>
                    )}
                </Table>
            </section>
            <Spacer bottom={15} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <SubTitle>Authority to Complete and Delete Supervisory Incident Forms</SubTitle>
                <Spacer top={5} bottom={3}>
                    <StationSelector
                        ref={register}
                        name={"station"}
                        onChange={(e) => {
                            onStationChange(e);
                        }}
                    />
                </Spacer>
                <Spacer bottom={4} />
                <RowContainer>
                    <TagInputContainer>
                        <TagInput
                            label={""}
                            options={authoriseCompleteIncident}
                            size="big"
                            name={"authoriseCompleteIncident"}
                            ref={register}
                            // @ts-ignore
                            placeholder={"Select User..."}
                        />
                    </TagInputContainer>
                </RowContainer>
                <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type="primary">{"Save"}</Button>
                </IsGranted>
            </form>
            <Spacer bottom={10} />
            <IsGranted feature={AccessCheck.addons.AUTOMATED_COACHING}>
                <AutomatedScorecardCoaching />
            </IsGranted>
            <Spacer bottom={4} />
        </Container>
    );
}
const TemplateView = (props) => {
    let incidentSetting = props.incidentSetting;
    return (
        <Modal width={"450px"} visible={true} title={"Template View"} closeOnOverlay={false} setVisible={props.cancel}>
            <div style={{ display: "flex" }}>
                <CKeditorModalComponent
                    content={incidentSetting.template}
                    readOnly={true}
                />
            </div>
            {incidentSetting.type == "1" && (
                <>
                    <Spacer bottom={3} />
                    <Label>Performance Improvement Section template.</Label>
                    <div style={{ display: "flex" }}>
                        <CKeditorModalComponent
                            content={incidentSetting.performanceTemplate}
                            readOnly={true}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
};

const AddEditIncidentSettingForm = (props) => {
    const { register, handleSubmit, errors, getValues, reset, formState } = useForm();
    const api = props.api;
    const incidentSetting = props.incidentSetting;
    const cancel = props.cancel;
    const edit = props.edit;
    const dispatch = props.dispatch;
    const state = props.state;
    const incidentType = props.incidentType;
    // const editorRef = useRef(null);
    // const editorRef2 = useRef(null);
    const [typeName, setTypeName] = useState("");
    const [showDescription, setShowDescription] = useState(false);
    const [contentTemplateData, setContentTemplateData] = useState(incidentSetting.template);
    const [contentPerformanceTemplateData, setContentPerformanceTemplateData] = useState(incidentSetting.performanceTemplate);

    useEffect(() => {
        const handler = (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        };
        document.addEventListener("focusin", handler);
        return () => document.removeEventListener("focusin", handler);
    }, []);

    const handleCompanyIncidentCatTemplate = (data, evt) => {
        const hide = loadingDialog();
        let form = evt.target;
        form.querySelector("button").disabled = true;
        let templateData = contentTemplateData;
        let performanceTemplateData = data.type == "1" ? contentPerformanceTemplateData : "";
        let isRequireComplete = data.isRequireComplete === "true" ? true : false
        if (edit) {
            let params = {
                actions: {
                    response: {
                        IncidentHrSettings: {
                            custom: {
                                functionName: "updateIncidentSettings",
                                get: "incidentSettings",
                                criteria: {
                                    id: incidentSetting.id,
                                    type: data.type,
                                    category: data.category,
                                    template: templateData,
                                    performanceTemplateData: performanceTemplateData,
                                    isRequireComplete: isRequireComplete
                                },
                            },
                        },
                    },
                },
            };
            api.post("/api/lazy/manage/data", params).then(() => {
                form.querySelector("button").disabled = false;
                toast({
                    type: "success",
                    title: "Incident",
                    content: "Incident Cat/Template Updated Successfully.",
                });
                data.performanceTemplate = performanceTemplateData;
                data.template = templateData;
                data.id = incidentSetting.id;
                data.incidentTypeName = incidentType[data.type];
                dispatch({
                    type: "UPDATE_INCIDENT_SETTING",
                    payload: {
                        data: data,
                    },
                });
                dispatch({
                    type: "SORTING_INCIDENT_SETTING",
                });
                hide();
            });
        } else {
            let param = {
                actions: {
                    create: {
                        IncidentHrSettings: {
                            incidentHrSettings_create: {
                                type: data.type,
                                category: data.category,
                                template: templateData,
                                isRequireComplete: isRequireComplete,
                                company: getCompanyId(),
                            },
                        },
                    },
                },
            };
            api.post("/api/lazy/manage/data", param).then((response) => {
                form.querySelector("button").disabled = false;
                reset({ type: "", category: "", template: "" });
                toast({
                    type: "success",
                    title: "Incident",
                    content: "Incident Cat/Template Save Successfully.",
                });
                data.performanceTemplate = performanceTemplateData;
                data.template = templateData;
                data.id = response.data.data.responseId;
                data.incidentTypeName = incidentType[data.type];
                dispatch({
                    type: "ADD_INCIDENT_SETTING",
                    payload: data,
                });
                dispatch({
                    type: "SORTING_INCIDENT_SETTING",
                });
                hide();
            });
        }
    };
    const values = getValues();

    useEffect(() => {
        changeType({ value: incidentSetting.type });
    }, [incidentSetting.type]);
    const changeType = (type) => {
        setTypeName(type.value);
    };

    return (
        <Modal
            width={"450px"}
            visible={true}
            title={edit ? "Edit Category & Template" : "Add Category & Template"}
            closeOnOverlay={false}
            setVisible={cancel}
            tabindex={-1}
        >
            <form
                key="incident_cat_form"
                name="incident_cat_form"
                onSubmit={handleSubmit(handleCompanyIncidentCatTemplate)}
            >
                <Dropdown
                    name="type"
                    placeholder="Select incident type"
                    label="Incident type"
                    ref={register({
                        required: {
                            value: true,
                        },
                    })}
                    Required={true}
                    options={[
                        { name: "Corrective Action", value: 1 },
                        { name: "Coaching", value: 2 },
                        { name: "Thank You", value: 3 },
                        { name: "Employee Note", value: 4 },
                    ]}
                    valid={!errors.type && values.type}
                    error={errors && errors.type && "Required type field"}
                    defaultValue={typeName}
                    style={{ width: "405px" }}
                    onChange={(e) => {
                        changeType(e);
                    }}
                />
                <Spacer bottom={3} />
                <Input
                    ref={register({
                        required: {
                            value: true,
                        },
                    })}
                    name="category"
                    label="Category"
                    Required={true}
                    valid={!errors.category && values.category}
                    error={errors && errors.category && "Required category field"}
                    defaultValue={incidentSetting.category}
                    style={{ width: "405px" }}
                />
                <Spacer bottom={3} />
                <Label>Template</Label>
                <div style={{ display: "flex" }}>
                    <CKeditorModalComponent
                        content={contentTemplateData}
                        onChange={(evt) => {
                            var content = evt.editor.getData();
                            setContentTemplateData(content);
                        }}
                    />
                </div>
                <div>
                    <div
                        style={{ width: "100%", cursor: "pointer" }}
                        onClick={() => {
                            setShowDescription(!showDescription);
                        }}
                    >
                        <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                            <Icon.Info
                                size="16px"
                                color={Theme.colors.neutrals.medium}
                                style={{
                                    verticalAlign: "middle",
                                    position: "relative",
                                    top: -1,
                                }}
                            />{" "}
                            Template Variable
                        </SpanTag>
                    </div>
                </div>
                <div>
                    {showDescription && (
                        <Description>
                            <SpanTag>
                                <span>[DRIVER_NAME] = "Driver name of Incident"</span>
                            </SpanTag>
                            <SpanTag>
                                <span>[DATE] = "Date of Incident"</span>
                            </SpanTag>
                            <SpanTag>
                                <span>[STATION] = "Station of Incident"</span>
                            </SpanTag>
                        </Description>
                    )}
                </div>
                {typeName == "1" && (
                    <>
                        <Spacer bottom={5} />
                        <Label>Performance Improvement Section Template.</Label>
                        <div style={{ display: "flex" }}>
                            <CKeditorModalComponent
                                content={contentPerformanceTemplateData}
                                onChange={(evt) => {
                                    var content = evt.editor.getData();
                                    setContentPerformanceTemplateData(content);
                                }}
                            />
                        </div>
                    </>
                )}
                <Spacer bottom={5} />
                {(typeName == 1 || typeName == 2) &&
                    <CheckBox
                        name="isRequireComplete"
                        title={""}
                        ref={register({ required: false })}
                        options={[{ value: true, label: "Require driver to complete incident before next Load out." }]}
                        defaultValues={[incidentSetting.isRequireComplete == 1 || incidentSetting.isRequireComplete == "true"]}
                    />}
                <Spacer bottom={3} />
                <Button type="primary">Save</Button>
            </form>
            <Spacer bottom={20} />
        </Modal>
    );
};

const Panel = ({ children }) => {
    const isActive = usePanelState();

    return isActive ? <div>{children}</div> : <div style={{ display: "none" }}>{children}</div>;
};
const Tab = ({ children }) => {
    const { onClick, isActive } = useTabState();
    return (
        <h6 onClick={onClick} className={[isActive && "tabActive"]}>
            {children}
        </h6>
    );
};

const AutomatedScorecardCoaching = () => {
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit, errors, watch, getValues, reset, formState } = useForm();
    // const editorCoachingTemplateRef = useRef(null);
    // const editorFactsOfIncidentStep2Ref = useRef(null);
    // const editorCorrectiveActionNeededStep2Ref = useRef(null);
    // const editorPerformanceImprovementPlanStep2Ref = useRef(null);
    // const editorFactsOfIncidentStep3Ref = useRef(null);
    // const editorCorrectiveActionNeededStep3Ref = useRef(null);
    // const editorPerformanceImprovementPlanStep3Ref = useRef(null);
    // const editorEmployeeTemplateRef = useRef(null);
    let {
        enableAutomatedScorecardCoaching,
        rolingWeekPeriod,
        sendDay,
        sendTime,
        defaultCreateCoachingPerDayPerDriver,
        coachingTemplate,
        factsOfIncidentStep2,
        correctiveActionNeededStep2,
        performanceImprovementPlanStep2,
        factsOfIncidentStep3,
        correctiveActionNeededStep3,
        performanceImprovementPlanStep3,
        employeeTemplate,
        ownerSign,
        requireWatchVideoForm1,
        requireWatchVideoForm2,
        requireWatchVideoForm3,
        requireCompleteIncidentForm1,
        requireCompleteIncidentForm2,
        requireCompleteIncidentForm3,
    } = state.companySetting;
    const [enableAutomatedScorecardCoachingToggle, setEnableAutomatedScorecardCoachingToggle] = useState(
        state.companySetting.enableAutomatedScorecardCoaching
    );
    const [showDescription, setShowDescription] = useState(false);
    const [showDescription1, setShowDescription1] = useState(false);
    const [showDescription2, setShowDescription2] = useState(false);
    const [showDescription3, setShowDescription3] = useState(false);
    const [showDescription4, setShowDescription4] = useState(false);
    const [showDescription5, setShowDescription5] = useState(false);
    const [showDescription6, setShowDescription6] = useState(false);
    const [showDescription7, setShowDescription7] = useState(false);
    const [showDescription8, setShowDescription8] = useState(false);

    let weekArr = [];
    let driverDayArr = [];
    weekArr.push({ name: 'All time', value: 'all-time' });
    for (let week = 12; week <= 52; week++) {
        weekArr.push({ name: week.toString(), value: week.toString() });
    }
    for (let item = 1; item <= 10; item++) {
        driverDayArr.push({ name: item.toString(), value: item.toString() });
    }
    let daysOfWeek = [
        { name: "Day After", value: "nextDay" },
        { name: "2 Days After", value: "2DayAfter" },
    ];
    const [contentCoachingTemplate, setContentCoachingTemplate] = useState(coachingTemplate);
    const [contentEmployeeTemplate, setEmployeeTemplate] = useState(employeeTemplate);
    const [contentFactsOfIncidentStep2, setContentFactsOfIncidentStep2] = useState(factsOfIncidentStep2);
    const [contentCorrectiveActionNeededStep2, setContentCorrectiveActionNeededStep2] = useState(correctiveActionNeededStep2);
    const [contentPerformanceImprovementPlanStep2, setContentPerformanceImprovementPlanStep2] = useState(performanceImprovementPlanStep2);
    const [contentFactsOfIncidentStep3, setContentFactsOfIncidentStep3] = useState(factsOfIncidentStep3);
    const [contentPerformanceImprovementPlanStep3, setContentPerformanceImprovementPlanStep3] = useState(performanceImprovementPlanStep3);
    const [contentCorrectiveActionNeededStep3, setContentCorrectiveActionNeededStep3] = useState(correctiveActionNeededStep3);

    useEffect(() => {
        if (state.companySetting) {
            setEnableAutomatedScorecardCoachingToggle(enableAutomatedScorecardCoaching);
            setContentCoachingTemplate(coachingTemplate);
            setEmployeeTemplate(employeeTemplate);
            setContentFactsOfIncidentStep2(factsOfIncidentStep2);
            setContentCorrectiveActionNeededStep2(correctiveActionNeededStep2);
            setContentPerformanceImprovementPlanStep2(performanceImprovementPlanStep2);
            setContentCorrectiveActionNeededStep3(correctiveActionNeededStep3);
            setContentFactsOfIncidentStep3(factsOfIncidentStep3);
            setContentPerformanceImprovementPlanStep3(performanceImprovementPlanStep3);
            setContentCorrectiveActionNeededStep3(correctiveActionNeededStep3);
        }
    }, [state.companySetting]);
    useEffect(() => {
        if (state.companySetting && state.companySetting.enableAutomatedScorecardCoaching == true) {
            if (ownerSign == null || ownerSign == "") {
                generateSignatureImage();
            }
        }
    }, [ownerSign]);

    const changeAutomatedScorecardCoaching = (tempEnableAutomatedScorecardCoaching) => {
        let param = {
            actions: {
                update: {
                    CompanySetting: {
                        update_company_setting: {
                            findBy: {
                                company: getCompanyId(),
                            },
                            updateRecord: {
                                enableAutomatedScorecardCoaching: tempEnableAutomatedScorecardCoaching,
                            },
                        },
                    },
                },
            },
        };

        let companySetting = state.companySetting;
        companySetting.enableAutomatedScorecardCoaching = tempEnableAutomatedScorecardCoaching;
        dispatch({
            type: "UPDATE_COMPNY_SETTING",
            payload: companySetting,
        });

        api.post("/api/lazy/manage/data", param).then(() => {
            toast({
                type: "success",
                title: "Forced Coaching",
                content: "Updates completed",
            });
            localStorage.setItem("enableAutomatedScorecardCoachingMetric", tempEnableAutomatedScorecardCoaching);
        });
    };
    const handleAutomatedScorecardCoaching = async (data, evt) => {
        let form = evt.target;
        form.querySelector("button").disabled = true;
        if (data.sign.length > 0) {
            if(mimeTypes["images"].includes(data.sign[0]?.type)){
                let formData = new FormData();
                formData.append("image", data.sign[0]);
                formData.append("name", data.sign[0].name);
                let res = await upload(formData).catch((err) => console.log(err));
                data.ownerSign = res.data.s3URL;
            } else {
                alert({ text: "Failed to upload sign image.! Upload jpg, png image format.", btnText: "Cancel" });
                return false;
            }
        }

        let param = {
            actions: {
                update: {
                    CompanySetting: {
                        update_company_setting: {
                            findBy: {
                                company: getCompanyId(),
                            },
                            updateRecord: {
                                enableAutomatedScorecardCoaching: data.enableAutomatedScorecardCoaching,
                                rolingWeekPeriod: data.rolingWeekPeriod,
                                sendDay: data.sendDay,
                                sendTime: data.sendTime,
                                defaultCreateCoachingPerDayPerDriver: data.defaultCreateCoachingPerDayPerDriver,
                                ownerSign: data.ownerSign,
                            },
                        },
                    },
                },
            },
        };

        let companySetting = state.companySetting;
        companySetting.enableAutomatedScorecardCoaching = data.enableAutomatedScorecardCoaching;
        if (data.enableAutomatedScorecardCoaching === true) {
            companySetting.rolingWeekPeriod = data.rolingWeekPeriod;
            companySetting.sendDay = data.sendDay;
            companySetting.sendTime = data.sendTime;
            companySetting.defaultCreateCoachingPerDayPerDriver = data.defaultCreateCoachingPerDayPerDriver;
        }
        dispatch({
            type: "UPDATE_COMPNY_SETTING",
            payload: companySetting,
        });

        api.post("/api/lazy/manage/data", param).then(() => {
            form.querySelector("button").disabled = false;
            toast({
                type: "success",
                title: "Forced Coaching",
                content: "Updates completed",
            });
            localStorage.setItem("enableAutomatedScorecardCoachingMetric", data.enableAutomatedScorecardCoaching);
        });
    };
    const handleCoachingForm = (data, evt) => {
        let form = evt.target;
        form.querySelector("button").disabled = true;
        let dataCoachingTemplate = contentCoachingTemplate;

        let param = {
            actions: {
                update: {
                    CompanySetting: {
                        update_company_setting: {
                            findBy: {
                                company: getCompanyId(),
                            },
                            updateRecord: {
                                coachingTemplate: dataCoachingTemplate,
                                requireWatchVideoForm1: data.requireWatchVideoForm1,
                                requireCompleteIncidentForm1: data.requireCompleteIncidentForm1
                            },
                        },
                    },
                },
            },
        };

        let companySetting = state.companySetting;
        companySetting.coachingTemplate = dataCoachingTemplate;
        companySetting.requireWatchVideoForm1 = data.requireWatchVideoForm1;
        companySetting.requireCompleteIncidentForm1 = data.requireCompleteIncidentForm1;
        dispatch({
            type: "UPDATE_COMPNY_SETTING",
            payload: companySetting,
        });

        api.post("/api/lazy/manage/data", param).then(() => {
            form.querySelector("button").disabled = false;
            toast({
                type: "success",
                title: "Forced Coaching",
                content: "Updates completed",
            });
        });
    };
    const handleCorrectiveActionInformalWarning = (data, evt) => {
        let form = evt.target;
        form.querySelector("button").disabled = true;
        let dataFactsOfIncidentStep2 = contentFactsOfIncidentStep2;
        let dataCorrectiveActionNeededStep2 = contentCorrectiveActionNeededStep2;
        let dataPerformanceImprovementPlanStep2 = contentPerformanceImprovementPlanStep2;
        let param = {
            actions: {
                update: {
                    CompanySetting: {
                        update_company_setting: {
                            findBy: {
                                company: getCompanyId(),
                            },
                            updateRecord: {
                                factsOfIncidentStep2: dataFactsOfIncidentStep2,
                                correctiveActionNeededStep2: dataCorrectiveActionNeededStep2,
                                performanceImprovementPlanStep2: dataPerformanceImprovementPlanStep2,
                                requireWatchVideoForm2: data.requireWatchVideoForm2,
                                requireCompleteIncidentForm2: data.requireCompleteIncidentForm2
                            },
                        },
                    },
                },
            },
        };

        let companySetting = state.companySetting;
        companySetting.factsOfIncidentStep2 = dataFactsOfIncidentStep2;
        companySetting.correctiveActionNeededStep2 = dataCorrectiveActionNeededStep2;
        companySetting.performanceImprovementPlanStep2 = dataPerformanceImprovementPlanStep2;
        companySetting.requireWatchVideoForm2 = data.requireWatchVideoForm2;
        companySetting.requireCompleteIncidentForm2 = data.requireCompleteIncidentForm2;
        dispatch({
            type: "UPDATE_COMPNY_SETTING",
            payload: companySetting,
        });

        api.post("/api/lazy/manage/data", param).then(() => {
            form.querySelector("button").disabled = false;
            toast({
                type: "success",
                title: "Forced Coaching",
                content: "Updates completed",
            });
        });
    };
    const handleCorrectiveActionFormalReprivement = (data, evt) => {
        let form = evt.target;
        form.querySelector("button").disabled = true;
        let dataFactsOfIncidentStep3 = contentFactsOfIncidentStep3;
        let dataCorrectiveActionNeededStep3 = contentPerformanceImprovementPlanStep3;
        let dataPerformanceImprovementPlanStep3 = contentCorrectiveActionNeededStep3;
        let param = {
            actions: {
                update: {
                    CompanySetting: {
                        update_company_setting: {
                            findBy: {
                                company: getCompanyId(),
                            },
                            updateRecord: {
                                factsOfIncidentStep3: dataFactsOfIncidentStep3,
                                correctiveActionNeededStep2: dataCorrectiveActionNeededStep3,
                                performanceImprovementPlanStep2: dataPerformanceImprovementPlanStep3,
                                requireWatchVideoForm3: data.requireWatchVideoForm3,
                                requireCompleteIncidentForm3: data.requireCompleteIncidentForm3
                            },
                        },
                    },
                },
            },
        };

        let companySetting = state.companySetting;
        companySetting.factsOfIncidentStep3 = dataFactsOfIncidentStep3;
        companySetting.correctiveActionNeededStep3 = dataCorrectiveActionNeededStep3;
        companySetting.performanceImprovementPlanStep3 = dataPerformanceImprovementPlanStep3;
        companySetting.requireWatchVideoForm3 = data.requireWatchVideoForm3;
        companySetting.requireCompleteIncidentForm3 = data.requireCompleteIncidentForm3;
        dispatch({
            type: "UPDATE_COMPNY_SETTING",
            payload: companySetting,
        });

        api.post("/api/lazy/manage/data", param).then(() => {
            form.querySelector("button").disabled = false;
            toast({
                type: "success",
                title: "Forced Coaching",
                content: "Updates completed",
            });
        });
    };
    const handleEmployeeTemplate = (data, evt) => {
        let form = evt.target;
        form.querySelector("button").disabled = true;
        let dataEmployeeTemplate = contentEmployeeTemplate;
        let param = {
            actions: {
                update: {
                    CompanySetting: {
                        update_company_setting: {
                            findBy: {
                                company: getCompanyId(),
                            },
                            updateRecord: {
                                employeeTemplate: dataEmployeeTemplate,
                            },
                        },
                    },
                },
            },
        };

        let companySetting = state.companySetting;
        companySetting.employeeTemplate = dataEmployeeTemplate;
        dispatch({
            type: "UPDATE_COMPNY_SETTING",
            payload: companySetting,
        });

        api.post("/api/lazy/manage/data", param).then(() => {
            form.querySelector("button").disabled = false;
            toast({
                type: "success",
                title: "Forced Coaching",
                content: "Updates completed",
            });
        });
    };

    async function generateSignatureImage() {
        let params = {
            actions: {
                response: {
                    Company: {
                        custom: {
                            functionName: "getCompanyOwnerName",
                            get: "name",
                            excludes: [],
                            criteria: {
                                companyId: getCompanyId(),
                            },
                        },
                    },
                },
            },
        };
        let response = await api.post("/api/lazy/manage/data", params);
        const protocol = window.location.protocol; // "http:" or "https:"
        const hostname = window.location.hostname; // "example.com"
        const port = window.location.port; // "3000" or ""
        const domainWithPort = port ? `${hostname}:${port}` : hostname;
        const fullUrl = `${protocol}//${domainWithPort}`;
        const fontUrl = `${fullUrl}/Pacifico-Regular.ttf`;
        const cursiveFont = new FontFace("Pacifico-Regular", `url(${fontUrl})`);
        cursiveFont.load().then(async function (loadedFont) {
            document.fonts.add(loadedFont);
            // Call the function to generate the signature image
            var canvas = document.getElementById("signatureCanvas");
            var ctx = canvas.getContext("2d");

            var inputText = response.data.data.name.replace(/\s/g, ""); // Get the input text from a text input or other source

            // Clear the canvas
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw the text on the canvas
            ctx.font = "20px Pacifico-Regular";
            ctx.fillStyle = "black";
            ctx.fillText(inputText, 25, 50);

            // Convert the canvas to a data URL
            var dataURL = await canvas.toDataURL();

            await api
                .post("/api/dsp/uploadBase64ToS3", {
                    file: dataURL,
                })
                .then(async (response) => {
                    let param = {
                        actions: {
                            update: {
                                CompanySetting: {
                                    update_company_setting: {
                                        findBy: {
                                            company: getCompanyId(),
                                        },
                                        updateRecord: {
                                            ownerSign: response.data.s3URL,
                                        },
                                    },
                                },
                            },
                        },
                    };

                    let companySetting = state.companySetting;
                    companySetting.ownerSign = response.data.s3URL;
                    dispatch({
                        type: "UPDATE_COMPNY_SETTING",
                        payload: companySetting,
                    });
                    await api.post("/api/lazy/manage/data", param);
                });

            return dataURL;
        });
    }

    return (
        <>
            <SubTitle>Automated Coaching</SubTitle>
            <canvas id="signatureCanvas" width="200" height="100" style={{ display: "none" }}></canvas>
            {state.companySetting && (
                <>
                    <form onSubmit={handleSubmit(handleAutomatedScorecardCoaching)}>
                        <Spacer top={5} bottom={3}>
                            <Toggle
                                ref={register}
                                on={enableAutomatedScorecardCoaching}
                                name="enableAutomatedScorecardCoaching"
                                singleText="Turn On/Off"
                                onChange={(e) => {
                                    setEnableAutomatedScorecardCoachingToggle(e.target.checked);
                                    changeAutomatedScorecardCoaching(e.target.checked);
                                }}
                            />
                        </Spacer>
                    </form>
                    <Spacer bottom={3} />
                    {enableAutomatedScorecardCoachingToggle && (
                        <>
                            <Spacer bottom={5} />
                            <Tabs>
                                <TabList>
                                    <Tab>All</Tab>
                                    <Tab>Coaching</Tab>
                                    <Tab>Corrective Action - Informal Warning</Tab>
                                    <Tab>Corrective Action - Formal Reprimand</Tab>
                                    <Tab>Employee Note</Tab>
                                </TabList>
                                <Panel>
                                    {/* All */}
                                    <form onSubmit={handleSubmit(handleAutomatedScorecardCoaching)}>
                                        <Spacer bottom={3} />
                                        <Dropdown
                                            name="rolingWeekPeriod"
                                            placeholder="Select number of Weeks"
                                            ref={register()}
                                            label="Rolling Period In Weeks"
                                            options={weekArr}
                                            defaultValue={rolingWeekPeriod}
                                            size={"small"}
                                        />
                                        <Spacer bottom={3} />
                                        <Label>Send Day and Time</Label>
                                        <Spacer inline right={7}>
                                            <Dropdown
                                                name="sendDay"
                                                placeholder="Select Day of Week"
                                                ref={register()}
                                                label=""
                                                options={daysOfWeek}
                                                defaultValue={sendDay}
                                                size={"small"}
                                            />
                                        </Spacer>
                                        <Spacer inline right={7}>
                                            <TimePicker
                                                ref={register()}
                                                name="sendTime"
                                                label=""
                                                size="small"
                                                defaultValue={sendTime}
                                                timeZoneShortName={"Custom"}
                                            />
                                        </Spacer>
                                        <Spacer bottom={3} />
                                        <Label>Owner Sign</Label>
                                        {state.companySetting.ownerSign != "" && (
                                            <DropUploader
                                                ref={register}
                                                name="sign"
                                                accept={"image/jpeg,image/png"}
                                                multiple={false}
                                                hideAfterFileSelect={false}
                                                placeholder={[
                                                    { src: state.companySetting.ownerSign, type: "image", id: 1 },
                                                ]}
                                                onDelete={async (key, file) => {
                                                    // if (documents[key]) {
                                                    //     let nDocuments = [...documents];
                                                    //     nDocuments.splice(key, 1);
                                                    //     setDocuments(nDocuments);
                                                    // }
                                                }}
                                            />
                                        )}
                                        <Spacer bottom={3} />
                                        <Dropdown
                                            name="defaultCreateCoachingPerDayPerDriver"
                                            placeholder="Select Number of Incident"
                                            ref={register()}
                                            label="Maximum Number of Automated Coaching Per Day Per Driver"
                                            options={driverDayArr}
                                            defaultValue={defaultCreateCoachingPerDayPerDriver}
                                            size={"small"}
                                            // onChange={(o)=>setTempDefaultCreateCoachingPerDayPerDriver(o.name) }
                                        />
                                        <Spacer bottom={3} />
                                        <AlertDiv>
                                            <Alert.Info
                                                title="How it works"
                                                content={
                                                    <>
                                                        <p>
                                                            Within {defaultCreateCoachingPerDayPerDriver} hours of uploading the weekly scorecard, "draft"
                                                            automated coaching Incidents will be created for your
                                                            drivers and displayed on the Incidents page. These will
                                                            remain in draft form until the following morning at which
                                                            point the system will send them to the drivers per the time
                                                            you set.
                                                        </p>
                                                        <br />
                                                        <br />
                                                        <p>
                                                            For example, if the scorecard is uploaded on Wednesday at
                                                            10:00am, by { sendTime } the drafts will be present and available
                                                            for modification. On Thursday morning, at the time you set,
                                                            they will be sent to the drivers.
                                                        </p>
                                                        <br />
                                                        <br />
                                                        <p>
                                                            You also can set the maximum number of Incidents the system
                                                            will send to a driver. If set to 2, and the driver has 6 bad
                                                            scores that week, she will receive 2 on Thursday, 2 more on
                                                            Friday, and the final 2 on Saturday.
                                                        </p>
                                                    </>
                                                }
                                                useIcon={true}
                                            />
                                        </AlertDiv>
                                        <Spacer bottom={3} />
                                        <Button type="primary">{"Save"}</Button>
                                    </form>
                                </Panel>
                                <Panel>
                                    {/* Coaching */}
                                    <form onSubmit={handleSubmit(handleCoachingForm)}>
                                        <Spacer bottom={3} />
                                        <Label>Coaching Provided Template</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentCoachingTemplate}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setContentCoachingTemplate(content);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription(!showDescription);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[METRIC] = "Metric"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[WEEK] = "Detail of Week"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div>
                                        <Spacer bottom={3} />
                                        <CheckBox
                                            name="requireCompleteIncidentForm1"
                                            title={""}
                                            ref={register({ required: false })}
                                            options={[{ value: true, label: "Require driver to complete incident before next Load out." }]}
                                            defaultValues={[requireCompleteIncidentForm1]}
                                        />
                                        <Spacer bottom={3} />
                                        <CheckBox
                                            name="requireWatchVideoForm1"
                                            title={""}
                                            ref={register({ required: false })}
                                            options={[{ value: true, label: "Require driver to watch entire video." }]}
                                            defaultValues={[requireWatchVideoForm1]}
                                        />
                                        <Spacer bottom={3} />
                                        <Button type="primary">{"Save"}</Button>
                                    </form>
                                </Panel>
                                <Panel>
                                    {/* Corrective Action- Informal Warning */}
                                    <form onSubmit={handleSubmit(handleCorrectiveActionInformalWarning)}>
                                        <Spacer bottom={3} />
                                        <Label>Facts Of Incident</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentFactsOfIncidentStep2}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setContentFactsOfIncidentStep2(content);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription1(!showDescription1);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription1 && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[METRIC] = "Metric"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[WEEK] = "Detail of Week"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div>
                                        <Spacer bottom={3} />
                                        <Label>Corrective Action Needed</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentCorrectiveActionNeededStep2}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setContentCorrectiveActionNeededStep2(content);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription3(!showDescription3);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription3 && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[METRIC] = "Metric"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div>
                                        <Spacer bottom={3} />
                                        <Label>Performance Improvement Plan</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentPerformanceImprovementPlanStep2}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setContentPerformanceImprovementPlanStep2(content);
                                                }}
                                            />
                                        </div>
                                        {/* <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription4(!showDescription4);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription4 && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[DRIVER_NAME] = "Driver name of Incident"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[DATE] = "Date of Incident"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[STATION] = "Station of Incident"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div> */}
                                        <Spacer bottom={3} />
                                        <CheckBox
                                            name="requireCompleteIncidentForm2"
                                            title={""}
                                            ref={register({ required: false })}
                                            options={[{ value: true, label: "Require driver to complete incident before next Load out." }]}
                                            defaultValues={[requireCompleteIncidentForm2]}
                                        />
                                        <Spacer bottom={3} />
                                        <CheckBox
                                            name="requireWatchVideoForm2"
                                            title={""}
                                            ref={register({ required: false })}
                                            options={[{ value: true, label: "Require driver to watch entire video." }]}
                                            defaultValues={[requireWatchVideoForm2]}
                                        />
                                        <Spacer bottom={3} />
                                        <Button type="primary">{"Save"}</Button>
                                    </form>
                                </Panel>
                                <Panel>
                                    {/* Corrective Action- Formal Reprimand */}
                                    <form onSubmit={handleSubmit(handleCorrectiveActionFormalReprivement)}>
                                        <Spacer bottom={3} />
                                        <Label>Facts Of Incident</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentFactsOfIncidentStep3}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setContentFactsOfIncidentStep3(content);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription5(!showDescription5);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription5 && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[METRIC] = "Metric"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[WEEK] = "Detail of Week"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div>
                                        <Spacer bottom={3} />
                                        <Label>Corrective Action Needed</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentPerformanceImprovementPlanStep3}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setContentPerformanceImprovementPlanStep3(content);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription6(!showDescription6);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription6 && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[METRIC] = "Metric"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div>
                                        <Spacer bottom={3} />
                                        <Label>Performance Improvement Plan</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentCorrectiveActionNeededStep3}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setContentCorrectiveActionNeededStep3(content);
                                                }}
                                            />
                                        </div>
                                        {/* <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription7(!showDescription7);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription7 && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[DRIVER_NAME] = "Driver name of Incident"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[DATE] = "Date of Incident"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[STATION] = "Station of Incident"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div> */}
                                        <Spacer bottom={3} />
                                        <CheckBox
                                            name="requireCompleteIncidentForm3"
                                            title={""}
                                            ref={register({ required: false })}
                                            options={[{ value: true, label: "Require driver to complete incident before next Load out." }]}
                                            defaultValues={[requireCompleteIncidentForm3]}
                                        />
                                        <Spacer bottom={3} />
                                        <CheckBox
                                            name="requireWatchVideoForm3"
                                            title={""}
                                            ref={register({ required: false })}
                                            options={[{ value: true, label: "Require driver to watch entire video." }]}
                                            defaultValues={[requireWatchVideoForm3]}
                                        />
                                        <Spacer bottom={3} />
                                        <Button type="primary">{"Save"}</Button>
                                    </form>
                                </Panel>
                                <Panel>
                                    {/* Employee Note */}
                                    <form onSubmit={handleSubmit(handleEmployeeTemplate)}>
                                        <Spacer bottom={3} />
                                        <Label>Employee Note Template</Label>
                                        <div style={{ display: "flex" }}>
                                            <CKeditorComponent
                                                content={contentEmployeeTemplate}
                                                onChange={(evt) => {
                                                    var content = evt.editor.getData();
                                                    setEmployeeTemplate(content);
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div
                                                style={{ width: "100%", cursor: "pointer" }}
                                                onClick={() => {
                                                    setShowDescription8(!showDescription8);
                                                }}
                                            >
                                                <SpanTag style={{ color: Theme.colors.neutrals.medium }}>
                                                    <Icon.Info
                                                        size="16px"
                                                        color={Theme.colors.neutrals.medium}
                                                        style={{
                                                            verticalAlign: "middle",
                                                            position: "relative",
                                                            top: -1,
                                                        }}
                                                    />{" "}
                                                    Template Variable
                                                </SpanTag>
                                            </div>
                                        </div>
                                        <div>
                                            {showDescription8 && (
                                                <Description>
                                                    <SpanTag>
                                                        <span>[DRIVER_NAME] = "Driver name"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[METRIC] = "Metric"</span>
                                                    </SpanTag>
                                                    <SpanTag>
                                                        <span>[REAPEATED_TIME] = "Reapeted Time"</span>
                                                    </SpanTag>
                                                </Description>
                                            )}
                                        </div>
                                        <Spacer bottom={3} />
                                        <Button type="primary">{"Save"}</Button>
                                    </form>
                                </Panel>
                            </Tabs>
                        </>
                    )}
                </>
            )}
        </>
    );
};
