import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, SubTitle, Spacer, Toggle, TagInput } from "@dspworkplace/ui";
import { useAuth } from "../../../Auth";
import { getCompanyId, showToast } from "../../../Utilities";
import SettingsContext from "../context";
import styled from "styled-components";
import { AccessCheck, IsGranted } from "../../../security";

const AllowDriverDirectChats = () => {
    // @ts-ignore
    const [defaultDeliveryAssociateDirectChat, setDefaultDeliveryAssociateDirectChat] = useState(false);
    const [isDirectChatWithAll, setDirectChatWithAll] = useState(true)
    const [users, setUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const { api } = useAuth();
    const [state, dispatch] = useContext(SettingsContext);
    const { register, handleSubmit, watch } = useForm();


    const getDeliveryAssociateDirectChat = async () => {
        const params = {
            "actions": {
                "response": {
                    "Company": {
                        "custom": {
                            "functionName": "getAllowDeliveryAssociateToChat",
                            "get": "allowDeliveryAssociateToChat",
                            "criteria": {
                                "id": localStorage.getItem("company")
                            }
                        }
                    }
                }
            }
        }
        let companyDirectChat = await api.post('/api/lazy/manage/data', params);
        if (companyDirectChat) {
            setDefaultDeliveryAssociateDirectChat(companyDirectChat.data.data.allowDeliveryAssociateToChat.allowDeliveryAssociateToChat);
            setDirectChatWithAll(companyDirectChat.data.data.allowDeliveryAssociateToChat.directChatWithAllUsers)
            setSelectedUsers(companyDirectChat.data.data.allowDeliveryAssociateToChat?.cannotDirectChatWith)
        }
    }

    const onSubmit = async (data, evt) => {
        let form = evt.target;
        try {
            form.querySelector('button').disabled = true;
            await api.post('/api/lazy/manage/data', {
                "actions": {
                    "response": {
                        "Company": {
                            "custom": {
                                "functionName": "updateAllowDeliveryAssociateToChat",
                                "get": "getAllowDeliveryAssociateToChat",
                                "criteria": {
                                    "companyId" :getCompanyId(),
                                    "allowDeliveryAssociateToChat": data.allowDeliveryAssociateToChat,
                                    "directChatWithAllUsers": data.directChatWithAll,
                                    "cannotDirectChatWith":data.chatUser
                                }
                            }
                        }
                    }
                }
            });
            await showToast({
                type: 'success',
                title: 'Allow Driver Direct Chats',
                content: 'Updates completed'
            });
            form.querySelector('button').disabled = false;
        } catch (e) {
            await showToast({ type: 'error', title: 'Allow Driver Direct Chats', content: JSON.stringify(e) });
            form.querySelector('button').disabled = false;
        }
    };

    function getStateOfWorkHourComplianceNotifyUsers(state) {
        try {
            let workHourComplianceNotifyUsers = Array.isArray(state.company.workHourComplianceNotifyUsers) ? state.company.workHourComplianceNotifyUsers : [];
            return { workHourComplianceNotifyUsers };
        } catch (e) {
            console.error({ getStateForWorkHourComplianceNotifyUsers: e });
            return { workHourComplianceNotifyUsers: [] };
        }
    }

    function formatForTagInputForUser(list, user) {
        try {
            if (list.includes(user.userId)) {
                return { name: user.friendlyName, value: user.userId, selected: true };
            } else {
                return { name: user.friendlyName, value: user.userId };
            }
        } catch (e) {
            console.error({ formatForTagInputForUser: e, list, user });
            return { name: user.friendlyName, value: user.userId };
        }
    }
    
    useEffect(() => {
        const init = async () => {
           await getDeliveryAssociateDirectChat();
            let { data } = await api.post('/api/bot/twilio/employees', {
                companyId: getCompanyId(),
                notEqualRole: 'ROLE_DELIVERY_ASSOCIATE'
            })
            if (data) {
                // setUsers(data?.users)
                let users = data.users;
                let { workHourComplianceNotifyUsers } = getStateOfWorkHourComplianceNotifyUsers(state);
                let tags = [];
                let user;
                for (let i = 0; i < users.length; i++) {
                    user = users[i];
                    tags.push(formatForTagInputForUser(workHourComplianceNotifyUsers, user));
                }
                setUsers(tags);
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Allow Delivery Associate Direct Chats</SubTitle>
            <Spacer bottom={2} />
            <Toggle
                ref={register}
                name="allowDeliveryAssociateToChat"
                singleText="Delivery Associate can Direct Chat with Delivery Associate"
                onChange={() => setDefaultDeliveryAssociateDirectChat(!defaultDeliveryAssociateDirectChat)}
                on={defaultDeliveryAssociateDirectChat}
            />
            <Spacer bottom={5} />
            <div>
                <Spacer bottom={2} />
                <Toggle
                    ref={register}
                    name="directChatWithAll"
                    singleText="Delivery Associate can Direct Chat with Manager"
                    onChange={() => setDirectChatWithAll(!isDirectChatWithAll)}
                    on={isDirectChatWithAll}
                />
                <Spacer bottom={5} />
            </div>
            {isDirectChatWithAll === true && (
                <div>
                    <TagInputContainer>
                        <TagInput
                            label="Cannot direct chat with..."
                            options={users}
                            size="big"
                            name={"chatUser"}
                            ref={register}
                            defaultValues={selectedUsers}
                            // @ts-ignore
                            // placeholder={swapShiftManagers.filter(e => e.selected).length === 0 ? "Default to Station Manager and/or Assistant Station Manager if they exist." : ""}
                        />
                    </TagInputContainer>
                </div>
            )}
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type="primary">Save</Button>
            </IsGranted>
        </form>
    );
}

export default AllowDriverDirectChats;

const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "8px")};
    width: ${props => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div.tagEventPrevent {
        width: ${props => (props.width ? props.width : "612px")};
    }

    ul {
        max-width: ${props => (props.width ? props.width : "612px")};
    }

    ul.big {
        max-width: unset;
        width: auto;
    }

    input {
        width: 100%;
    }
`;
