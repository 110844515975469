import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { CustomDropdown, Dropdown, Icon, Input, Spacer, Theme, Button, SubTitle, TagInput } from "@dspworkplace/ui";
import styled from "styled-components";
import Store from "./Context";
import { useParams } from "react-router-dom";
import { showVehicleImageForm } from "./form";
import { fetchVehicleImage, fetchVehiclesAndDrivers, handlePrintImageVehicle } from "./api";
import { useAuth } from "../../../Auth";
import { SortingData } from "./helper";
import { showErrorToast } from "../../../Utilities";

import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import DatePicker from "../../../components/Calendar/picker";
import moment from 'moment';
import Modal from "../../../components/Modal/index";
import { Header } from "../../../components/Grid/Tools/Filter/Filter";
import FilterBottom from "../../../components/FilterBottom";

const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content:space-between;
    gap: 10px; 10px;
`;

function Filter(props) {
    const { api } = useAuth();
    const { id, name } = useParams();
    const [selectedStation] = useSelectedStation();


    const getFilterValuesFromStorage = () => {
        let filterFromStorage = sessionStorage.getItem("vehicle-mgmt-filter");
        const defaultFilter = { station: selectedStation, imageCategories: "all", driversId: "all", search: "", vehicleId: "all", date: false, aiInspectionNotes: [] }
        try {
            filterFromStorage = JSON.parse(filterFromStorage) || defaultFilter;
        } catch (error) {
            filterFromStorage = defaultFilter
        }
        return defaultFilter
    }


    const { register, handleSubmit, setValue, reset, getValues } = useForm({
        mode: "onChange",
        defaultValues: getFilterValuesFromStorage(),
    });
    const [tempLabel, setTempLabel] = useState('');
    const [state, dispatch] = useContext(Store);
    const dateRef = useRef(null);
    const { open, setOpen } = props;
    const [date, setDate] = useState(false);
    const [val, setVal] = useState({});
    const [tempDate, setTemDate] = useState(false);
    const vehicleImageAiCategory = state.vehicleImageAiCategory;
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const companyId = localStorage.getItem("company");

    const resetFilter = async () => {
        await setValue("vehicleId", 'all');
        await setValue("driversId", 'all');
        await setValue("imageCategories", 'all');
        await setValue("label", '');
        await setValue("aiInspectionNotes", []);
        await dispatch({ type: "RESET_FILETER", payload: {} });
    };

    useEffect(() => {
        resetFilter();
    }, [selectedStation]);

    useEffect(() => {
        if(id != ":id"){
            selectedVehicleId(id);
        }
    }, [id]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const selectedVehicleId = (e) => {
        if (typeof e === 'string') {
            const newValue = (state.vehiclesOpts || []).find(item => item.value == e);
            
            dispatch({
                type: "SELECTED_VEHICLE",
                payload: {
                    vehicleId: newValue?.name || e,
                    id: newValue?.value || e
                },
            });
        } else {
            dispatch({
                type: "SELECTED_VEHICLE",
                payload: e,
            });
        }
    };
    const selectedDriverId = (e) => {
        dispatch({
            type: "SELECTED_DRIVER",
            payload: e,
        });
    };
    const selectedCategoryId = (e) => {
        dispatch({
            type: "SELECTED_CATEGORY",
            payload: e,
        });
    };
    const setLabel = () => {
        dispatch({
            type: "SET_LABEL",
            payload: tempLabel,
        });
        setVal({ ...getValues(), search: tempLabel, label: tempLabel });
        setTimeout(() => { props.onSubmitCallback() }, 1)
    };
    const selectedTimeframe = (e) => {
        dispatch({
            type: "SELECTED_TIMEFRAME",
            payload: e,
        });
    };
    const selectedaiImageId = (e) => {
        dispatch({
            type: "SELECTED_AI_CATEGORY",
            payload: e,
        });
    };

    const submit = async () => {
        sessionStorage.setItem("vehicle-mgmt-filter", JSON.stringify({ ...getValues(), date: date }))
        setVal({ ...getValues(), date: date });
        props.setVal({ ...getValues(), date: tempDate });
        selectedVehicleId(getValues().vehicleId)
        selectedCategoryId(getValues().imageCategories)
        selectedDriverId(getValues().driversId)
        selectedTimeframe(date)
        selectedaiImageId(getValues().aiInspectionNotes)
        setOpen(false)
        setTimeout(() => { props.onSubmitCallback() }, 1)
    }

    useEffect(() => {
        reset(val);
        let i = 0;
        Object.keys(getValues()).forEach((item) => {
            if (getValues()[item] !== "all" && getValues()[item] != "" && item != "search" && item != "station") {
                i++;
            }
        });
       

        props.setBadge(i);
    }, [state, open]);

    const handleFilterSelect = (filter) => {
        console.log("filter>>", filter);
    }

    return (
        <div>
            <form onSubmit={handleSubmit(setLabel)}>
            <StationSelector />
            <Spacer inline right={5} />
                <Input ref={register} size={"medium"} value={tempLabel} name="search" label="Search" onChange={(e) => { setTempLabel(e.currentTarget.value) }}>
                    <Icon.Search onClick={setLabel} />
                </Input>
            </form>
            <Modal
                width={'340px'}
                visible={open}
                title={'Filters'}
                setVisible={() => setOpen(false)}
                drawer={'right'}
                key={'modal-filter'}
                closeOnOverlay={true}
                zindex={999}
                removeHeader={true}
                paddingTopBottomZero={true}
            >
                <FilterContainer style={{ height: "100vh" }}>
                    <div style={{ display: "flex", flexDirection: "column" ,height:"100%" }}>                        
                        <Header>
                            <SubTitle>Filters</SubTitle>
                            <Button type="cancel" onClick={async () => {
                                await sessionStorage.removeItem("vehicle-mgmt-filter"); 
                                await dateRef.current.clear()
                                await setValue('search', '');
                                setVal({ label: '', selectedCategory: 'all', selectedDriver: 'all', vehicleId: "all", date: false })
                                props.setVal({ label: '', selectedCategory: 'all', selectedDriver: 'all', vehicleId: "all", date: false })
                                await dispatch({ type: "RESET_FILETER", payload: {} });
                                setTemDate(false)
                                setDate(false)
                                setTempLabel("")
                                await setValue('aiInspectionNotes', []);
                                await setValue('imageCategories', []);
                                dispatch({ type: "RESET_SELECTED_IMAGE", payload: {} });
                                setTimeout(() => { props.onSubmitCallback() }, 1)
                            }}>
                                Clear
                            </Button>
                        </Header>
                        <div style={{ width: "100%", flex: "1", overflow: "auto"}}>
                        <Dropdown
                            name="vehicleId"
                            label="Vehicles"
                            options={state.vehiclesOpts}
                            size="large"
                            ref={register}
                            defaultValue={getValues().vehicleId || state.vehicleId?.id || "all"}
                            onChange={async (e) => {
                                await setValue("vehicleId", e.value);
                            }}
                            visibleOptionsQty={7}  
                        />
                        <Spacer inline right={3} />
                        <Dropdown
                            name="driversId"
                            label="Drivers"
                            options={state.driversOpts}
                            size="large"
                            ref={register}
                            defaultValue={state.selectedDriver}
                            onChange={async (e) => {
                                await setValue("driversId", e.value);
                            }}
                            visibleOptionsQty={7}
                        />
                        <Spacer top={4} />
                        <TagInput
                            name={"imageCategories"}
                            label="Image Categories"
                            options={state.categoryOpts}
                            defaultValue={state.selectedCategory}
                            size="large"
                            ref={register}
                            onChange={async (e) => {
                                await setValue("imageCategories", e.value);
                            }}
                            visibleOptionsQty={7}
                        />
                        <Spacer top={4} />
                        {vehicleImageAiCategory?.length > 0 && 
                        <>
                        <TagInput
                            label="AI Inspection Notes"
                            options={state.vehicleImageAiCategory}
                            size="large"
                            ref={register}
                            onChange={async (e) => {
                                await setValue("aiInspectionNotes", e);
                            }}
                            name={'aiInspectionNotes'}
                            visibleOptionsQty={7}
                        />
                        <Spacer top={4} />
                        </>
                        }
                        <DatePicker
                            name={"date"}
                            label={"Period (two values required)"}
                            placeholder="MM/DD/YYYY - MM/DD/YYYY"
                            ref={dateRef}
                            onChange={async (date) => {
                                if (date.length > 0) {
                                    const splitDate = date.split(" – ");
                                    const startDate = splitDate[0] + " 00:00";
                                    const endDate = splitDate[1] + " 23:59";
                                    await setDate({ start: moment(startDate).utc().format('YYYY-MM-DD HH:mm'), end: moment(endDate).utc().format('YYYY-MM-DD HH:mm') })
                                    // setTimeout(() => selectedTimeframe(), 1);
                                } else {
                                    await setDate(false)
                                    // setTimeout(() => selectedTimeframe(false), 1);
                                }
                                setTemDate(date);
                            }}
                            // value={date.start+" - "+date.end}
                            defaultRangeValue={tempDate ? [new Date(tempDate.split(" ")[0]), new Date(tempDate.split(" ")[2])] : false}
                            size={"medium"}
                            range={"date"}
                        />
                    </div>
                    <FilterBottom
                        onApplyClick={submit}
                        onFilterSelect={handleFilterSelect}
                    />
                    </div>
                </FilterContainer>
            </Modal>
        </div>
    );
}

export default Filter;
