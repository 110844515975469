import React, {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import {useForm} from "react-hook-form";
import {
    Button,
    Spacer,
    SubTitle,
    Input,
    Dropdown,
    RadioBox,
    CheckBox,
    Toggle,
    TagInput,
    ThirdTitle
} from "@dspworkplace/ui";
import {ScrollyOverlayStyle, ScrollyModalStyle} from "../../components/Modal";
import {Label} from "../../components/UI";
import Loading, {LoadingWrapper} from "../../components/Loading";
import {patternNumericTenDigitsValue} from '../../patterns';
import DatePicker from '../../components/Calendar/picker';
import moment from 'moment';

const modalRoot = document.getElementById('modal-root');

const CORPORATE_STATUS = [
    {name:'Active',value:'active'},
    {name:'Suspended',value:'suspended'},
    {name:'Retired',value:'retired'},
    {name:'Lost',value:'lost'},
    {name:'Stolen',value:'stolen'}
];

let manufacturerOpts = [
    { name: 'Samsung', value: 'Samsung' },
    { name: 'Apple', value: 'Apple' },
    { name: 'Acer', value: 'Acer' },
    { name: 'Asus', value: 'Asus' },
    { name: 'HTC', value: 'HTC' },
    { name: 'Huawei', value: 'Huawei' },
    { name: 'Motorola', value: 'Motorola' },
    { name: 'LG', value: 'LG' },
    { name: 'Sony', value: 'Sony' },
    { name: 'Xiaomi', value: 'Xiaomi' },
    { name: 'Bullitt Group Limited', value: 'BullittGroupLimited'},
    { name: 'CAT', value: 'CAT'},
    { name: 'Genymotion', value: 'Genymotion'},
    { name: 'LGE', value: 'LGE'},
    { name: 'Zebra', value: 'Zebra'},
    { name: 'Other', value: 'Other' },
];

let carrierOpts = [
    { name: 'AT&T', value: 'AT&T' },
    { name: 'Sprint', value: 'Sprint' },
    { name: 'T-Mobile', value: 'T-Mobile' },
    { name: 'Verizon', value: 'Verizon' },
    { name: 'Other', value: 'Other' },
];

const DevicesForm = ({api, device, success, cancel, children, type}) => {
    const [ stations, setStations ] = useState( [] );
    const [deviceName, setDeviceName] = useState('');
    const [allowLoadOutProcess, setAllowLoadOutProcess] = useState(device ? device.allowLoadOutProcess : false);
    const [isPersonalDevice, setIsPersonalDevice] = useState(device ? device.isPersonalDevice : false);

    const TODAY = moment().format('YYYY-MM-DD');

    device = device || { platform: 'Android', corporateStatus:'active',corporateStatusDate:TODAY, allowLoadOutProcess:false};
    device.deviceCondition = (device.deviceCondition) ? device.deviceCondition : [];
    const companyId = localStorage.getItem("company");

    const { register, handleSubmit, errors, getValues, watch, reset, formState:{isSubmitting} } = useForm({
        mode: 'onBlur',
        defaultValues: device
    });

    const values = getValues();
    const required = register({
        required: 'Required field'
    });

    const onSubmit = data => {
        success( data );
    };

    const isPersonalDeviceChange = () => {
        if(type == 'addDevice'){
            if(isPersonalDevice == false){
                setAllowLoadOutProcess(false);
            }
        }

        setIsPersonalDevice(!isPersonalDevice);
    };

    const errorMessages = {
        number: {
            required: 'This field cannot be empty',
            pattern: 'Wrong format',
            exists: "I'm sorry, we have found an already existing device with the same device name and/or phone number.  Please correct your record(s) and try again.",
        }
    }

    if(device && device.name){
        if(!deviceName)
            setDeviceName(device.name);
    }

    useEffect( ()=> {
        api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    Station: {
                        findBy: {
                            criteria: {
                                company: localStorage.getItem('company'),
                            },
                            get: "station",
                            includes: {
                                0: "id",
                                1: "code",
                            }
                        }
                    }
                }
            }
        }).then(
            response => setStations( response.data.data.station.map( s => ({ name: s.code, value: s.id }) ) )
        )
    }, [] );


    return (
        <ScrollyOverlayStyle>
            <ScrollyModalStyle as='form'  onSubmit={handleSubmit(onSubmit)} width='460px' onClick={evt=>evt.stopPropagation()}>
                <input type={'hidden'} ref={register} name={'id'} />
                <header>
                    <SubTitle>Device Details</SubTitle>
                    <Button type='cancel' onClick={cancel}>Cancel</Button>
                </header>
                {!stations.length ? (
                    <LoadingWrapper>
                        <Loading/>
                    </LoadingWrapper>
                ) : (
                    <>
                        {children && <Spacer bottom={3}>{children}</Spacer>}
                        <ThirdTitle>Device Information</ThirdTitle>
                        <Spacer bottom={3}/>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Dropdown
                                ref={required}
                                name='station'
                                label='Station'
                                size='small'
                                Required={true}
                                valid={values.station && !errors.station}
                                error={errors.station && errors.station.message}
                                options={stations}
                            />
                        </Spacer>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Input
                                name='name'
                                label='Name'
                                size='small'
                                Required={true}
                                valid={values.name && !errors.name}
                                error={errors.name && errors.name.message}
                                ref={register({
                                    required: 'Required field',
                                    validate: {
                                        exists: async value => {
                                            setDeviceName(value);
                                            return true
                                        }
                                    }
                                })}
                            />
                        </Spacer>
                        <Spacer right={0} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Input
                                ref={register}
                                name='assetTag'
                                label='Asset Tag ID'
                                size='small'
                                Required={false}
                                valid={values.assetTag && values.assetTag.length}
                                error={errors.assetTag && errors.assetTag.message}
                            />
                        </Spacer>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top', width: 160}}>
                            <Label>MDM Client</Label>
                            <Spacer bottom={3}/>
                            <RadioBox
                                ref={register}
                                name='mdmClient'
                                options={[
                                    { label: 'Yes', value: 1 },
                                    { label: 'No',  value: 0 },
                                ]}
                                Required={false}
                                valid={values.mdmClient && !errors.mdmClient}
                                error={errors.mdmClient && errors.mdmClient.message}
                            />
                        </Spacer>
                        <Spacer right={0} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Input
                                ref={register}
                                name='username'
                                label='Username'
                                Required={false}
                                valid={values.username && values.username.length}
                                error={errors.username && errors.username.message}
                            />
                        </Spacer>
                        {watch('mdmClient') === '0' && (
                            <Spacer right={0} bottom={3} inline style={{verticalAlign: 'top'}}>
                                <Input
                                    ref={register({
                                        pattern: {
                                            value: /^(1[012]|0[1-9])\/([3210][0-9])\/\d{4}$/,
                                            message: 'Invalid date'
                                        }
                                    })}
                                    name='lastInventoryDate'
                                    label='Last Inventory Date'
                                    size='big'
                                    help='mm/dd/yyyy'
                                    valid={values.lastInventoryDate && !errors.lastInventoryDate}
                                    error={errors.lastInventoryDate && errors.lastInventoryDate.message}
                                />
                            </Spacer>
                        )}
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top', width: 160}}>
                            <Label>Platform</Label>
                            <RadioBox
                                ref={register}
                                name='platform'
                                options={[
                                    { label: 'Android', value: 'Android' },
                                    { label: 'iOS', value: 'ios' },
                                    { label: 'Other', value: 'Other' },
                                ]}
                                Required={false}
                                valid={values.platform && values.platform.length}
                                error={errors.platform && errors.platform.message}
                            />
                        </Spacer>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Input
                                ref={register}
                                name='osVersion'
                                label='OS Version'
                                size='small'
                                Required={false}
                                valid={values.osVersion && values.osVersion.length}
                                error={errors.osVersion && errors.osVersion.message}
                            />
                        </Spacer>
                        <Spacer right={0} bottom={3} inline style={{verticalAlign: 'top'}}>
                                <Input
                                    ref={register}
                                    name='deviceId'
                                    label='Internal DeviceID'
                                    size='small'
                                    Required={false}
                                    disabled={(device.id) ? true : false}
                                />
                        </Spacer>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <TagInput
                                ref={register}
                                name='manufacturer'
                                label='Manufacturer'
                                size='small'
                                Required={false}
                                valid={values.manufacturer && !errors.manufacturer}
                                error={errors.manufacturer && errors.manufacturer.message}
                                options={manufacturerOpts}
                                canCreate={true}
                                singleOption={true}
                            />
                        </Spacer>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Input
                                ref={register}
                                name='model'
                                label='Model'
                                size='small'
                                Required={false}
                                valid={values.model && !errors.model}
                                error={errors.model && errors.model.message}
                            />
                        </Spacer>
                        <Spacer right={0} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Input
                                name='number'
                                label='Phone Number'
                                size='small'
                                ref={register({
                                    required: true,
                                    validate: {
                                        exists: async (value) => {
                                            let res = await api.post('/api/device/check/phone/exist', {
                                                "phone": value,
                                                "name":deviceName,
                                                "id": device.id,
                                                "company":companyId
                                            });
                                            return res.data.status !== true
                                        }
                                    },
                                    pattern: patternNumericTenDigitsValue
                                })}

                                Required={true}
                                error={errors.number && errorMessages.number[errors.number.type]}
                                valid={values.number &&  values.number.length}
                            />
                        </Spacer>
                        <Spacer right={5} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <TagInput
                                ref={register}
                                name='carrier'
                                label='Carrier'
                                size='small'
                                Required={false}
                                valid={values.carrier && !errors.carrier}
                                error={errors.carrier && errors.carrier.message}
                                options={carrierOpts}
                                canCreate={true}
                                singleOption={true}
                            />
                        </Spacer>
                        <Spacer right={0} bottom={3} inline style={{verticalAlign: 'top'}}>
                            <Input
                                ref={register}
                                name='imei'
                                label='IMEI/MEID'
                                Required={false}
                                valid={values.imei && values.imei.length}
                                error={errors.imei && errors.imei.message}
                                autoComplete='off'
                            />
                        </Spacer>
                        <Spacer right={0} bottom={5} inline style={{verticalAlign: 'top'}} top={3}>
                            <Label>Condition</Label>
                            <Spacer bottom={1}/>
                            <CheckBox
                                ref={register}
                                name='deviceCondition'
                                Required={false}
                                valid={values.deviceCondition && values.deviceCondition.length}
                                error={errors.deviceCondition && errors.deviceCondition.message}
                                options={[
                                    { label: 'Good', value: 'Good' },
                                    { label: 'Cracked Screen', value: 'Cracked Screen' },
                                    { label: 'Short Battery', value: 'Short Battery' },
                                    { label: 'Other', value: 'Other' }
                                ]}
                            />
                        </Spacer>
                        <Spacer right={0} bottom={5} inline style={{ verticalAlign: 'top' }} top={3}>
                            <Input
                                name="notes"
                                size="small"
                                label="Notes"
                                ref={register}
                                as="textarea"
                                style={{ width: 450, height: 100 }}
                            />    
                        </Spacer>
                        <Spacer all={0} style={{verticalAlign: 'top'}}>
                            <Toggle
                                ref={register}
                                name='allowLoadOutProcess'
                                on={allowLoadOutProcess}
                                onChange={()=>{setAllowLoadOutProcess(!allowLoadOutProcess)}}
                                singleText='Allow this device to run the load out process'
                            />
                        </Spacer>
                        <Spacer top={3}  style={{verticalAlign: 'top'}}>
                            <Toggle
                                ref={register}
                                name='isPersonalDevice'
                                on={isPersonalDevice}
                                onChange={()=>{isPersonalDeviceChange()}}
                                singleText='Personal Device'
                            />
                        </Spacer>
                        {watch('isPersonalDevice') === false &&
                            <Spacer top={3}  inline style={{verticalAlign: 'top',position:'relative',zIndex:3}}>
                                <Dropdown
                                    label={'Status'}
                                    ref={register({required:'Field required'})}
                                    name='corporateStatus'
                                    options={CORPORATE_STATUS}
                                    size={'small'}
                                    valid={values.corporateStatus && !errors.corporateStatus}
                                    error={errors.corporateStatus && errors.corporateStatus.message}
                                    visibleOptionsQty={5}
                                    onSelect={(opt)=>{
                                        reset({...values,corporateStatusDate:TODAY})
                                    }}
                                />
                                <Spacer left={4} inline>
                                    <DatePicker
                                        name="corporateStatusDate"
                                        label="Status Date"
                                        ref={register}
                                        error={errors.corporateStatusDate && "Field required"}
                                        valid={
                                            !errors.corporateStatusDate &&
                                            values.corporateStatusDate &&
                                            values.corporateStatusDate.length > 0
                                        }
                                    />
                                </Spacer>
                            </Spacer>
                        }

                        <Spacer bottom={3} top={5}>
                            <ThirdTitle>Ownership Information</ThirdTitle>
                        </Spacer>
                        <Spacer right={0} bottom={3} style={{verticalAlign: 'top'}}>
                            <Label>Ownership</Label>
                            <Spacer bottom={1}/>
                            <RadioBox
                                ref={register}
                                name='ownership'
                                options={[
                                    { label: 'Owned',  value: '1' },
                                    { label: 'Rented', value: '0' },
                                ]}
                                Required={false}
                                valid={values.ownership && values.ownership.length}
                                error={errors.ownership && errors.ownership.message}
                            />
                        </Spacer>

                        {watch('ownership') === '0' && (
                            <>
                                <Spacer right={3} bottom={3} style={{verticalAlign: 'top'}} inline>
                                    <Input
                                        ref={register({
                                            required: {
                                                value: false,
                                                message: 'Required field'
                                            },
                                            pattern: {
                                                value: /^(1[012]|0[1-9])\/([3210][0-9])\/\d{4}$/,
                                                message: 'Invalid date'
                                            }
                                        })}
                                        name='rentedStartDate'
                                        label='Rent Start Date'
                                        Required={false}
                                        size='small'
                                        help='mm/dd/yyyy'
                                        valid={values.rentedStartDate && !errors.rentedStartDate}
                                        error={errors.rentedStartDate && errors.rentedStartDate.message}
                                    />
                                </Spacer>
                                <Spacer right={3} bottom={3} style={{verticalAlign: 'top'}} inline>
                                    <Input
                                        ref={register({
                                            required: {
                                                value: false,
                                                message: 'Required field'
                                            },
                                            pattern: {
                                                value: /^(1[012]|0[1-9])\/([3210][0-9])\/\d{4}$/,
                                                message: 'Invalid date'
                                            }
                                        })}
                                        name='rentedEndDate'
                                        label='Rent End Date'
                                        Required={false}
                                        size='small'
                                        help='mm/dd/yyyy'
                                        valid={values.rentedEndDate && !errors.rentedEndDate}
                                        error={errors.rentedEndDate && errors.rentedEndDate.message}
                                    />
                                </Spacer>
                            </>
                        )}
                        {watch('ownership') === '1' && (
                            <Spacer right={0} bottom={3} style={{verticalAlign: 'top'}}>
                                <Input
                                    ref={register({
                                        required: {
                                            value: false,
                                            message: 'Required field'
                                        },
                                        pattern: {
                                            value: /^(1[012]|0[1-9])\/([3210][0-9])\/\d{4}$/,
                                            message: 'Invalid date'
                                        }
                                    })}
                                    name='ownedTermStartDate'
                                    label='Contract Term Date'
                                    Required={false}
                                    help='mm/dd/yyyy'
                                    valid={values.ownedTermStartDate && !errors.ownedTermStartDate}
                                    error={errors.ownedTermStartDate && errors.ownedTermStartDate.message}
                                />
                            </Spacer>
                        )}
                        <Spacer top={5} style={{textAlign: 'right'}}>
                            <Button typeStyle={'primary'} type={'submit'} disabled={Object.keys(errors).length > 0 || isSubmitting}>Save</Button>
                        </Spacer>
                    </>
                )}
            </ScrollyModalStyle>
        </ScrollyOverlayStyle>
    )
};

const hide = ()=> setTimeout( ()=> {
    ReactDOM.unmountComponentAtNode( modalRoot );
}, 1 );

const showDevicesForm = async (opts) => {
    let {children, device, api, type} = opts || {};

    return new Promise( ( resolve, reject ) => {
        const success = data => hide() && resolve( data );
        const cancel = data => hide() && reject( data );

        return ReactDOM.render( (
            <DevicesForm
                api={api}
                device={device}
                success={success}
                cancel={cancel}
                children={children}
                type={type}
            />
        ), modalRoot );
    });
};

export {showDevicesForm};
