import React, {useContext, useEffect} from "react";
import styled from 'styled-components';
import People from "../../../components/People";
import DefaultStore from "./ContextStore";
import Loading from "../../../components/Loading";
import {useAuth} from "../../../Auth";
import {Table, TableData, TableFooter, TableHeader} from "../../../components/Table";
import {Spacer, Theme, ThirdTitle} from "@dspworkplace/ui";
import Empty from '../../../components/Empty';
import {Tag} from "../../../components/UI";
import {FilterWeekDate} from "../../../hooks/useGlobalData";
import Moment from "moment";

const Body  = styled.div`
    display:flex;
    width:100%;
    max-width:100%;

    overflow-x: auto;
`;

const Column = styled(Table)`
    list-style:none;
    padding:0;
    margin:0;
    min-width: 200px;
    max-width: 200px;
    display:flex;
    flex-direction: column;
    border-right:1px dashed #CBD6E2;
    max-height: 60vh;
    overflow-x: ${props => props.subTable ? 'auto' : 'hidden' };
    position: relative;

    &:empty {
        display:none;
    }
`
const List = () => {
    const [state,dispatch] = useContext(DefaultStore);
    const { api } = useAuth();
    const [date] = FilterWeekDate();
    
    const fetchData = async (week,year) => {
        const params={
            "actions": {
                "response": {
                    "Schedule": {
                        "custom": {
                            "functionName" : "getEmployeeBalanceDrivers",
                            "criteria": {"company":localStorage.getItem('company'), "week":week,"year":year},
                            "get": "schedule",
                            "excludes": [ "company", "scheduleDesigns", "shiftTemplates", "drivers", "station", "driverRoutes", "tempDriverRoutes","skills" ],
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "startWeek",
                                "3": "endWeek",
                                "station" : {
                                    "0" :"id",
                                    "1":"name",
                                    2: 'code'
                                },
                                "drivers": {
                                    "0": "id",
                                    "1": "jobTitle",
                                    "2": "isArchive",
                                    "3": "fullName",
                                    "4": "isEnabled",
                                    "user": {"0":"id", "1":"firstName", "2":"image","3":"isEnabled","4":"isArchive","5":"friendlyName","6":"title","userRoles":["id","roleName"]},
                                },
                            }
                        }
                    }
                }
            }
        };
        try {
            let response = await api.post('/api/lazy/manage/data', params);
            dispatch({type:'ADD',payload:response.data.data.schedule})
        } catch(error) {
        }
    }
    useEffect(()=>{
        const week = (Moment(date).day() === 0) ?  Moment(date).add(1, "days").isoWeek() : Moment(date).isoWeek();
        let year = (Moment(date).day() === 0) ? Moment(date).add(1, "days").isoWeekYear() : Moment(date).isoWeekYear();
        if(Moment(date).startOf("week").format('YYYY') != Moment(date).endOf("week").format('YYYY') && week == 1 && year == Moment(date).startOf("week").format('YYYY')) {
            year = parseInt(Moment(date).endOf("week").format('YYYY'));
        }
        fetchData(week, year);
    },[date]);

    let list = state.filter;
    let grouped = [];
    if ( state.groupData ) {
        list = state.filter.filter(s => s.drivers.length > state.limit);
        grouped = state.filter
            .filter(s => s.drivers.length <= state.limit)
            .flatMap(s => {
                s.drivers.forEach(d => {
                    d.schedule = s;
                });
                return s.drivers;
            });
    }

    return (
        <Body>
            {state.isLoading ? (
                <Loading style={{height:'80px',padding:20,margin:'0 auto'}} />
            ) : (
                <>
                    {list.map((item,key)=>{
                        return <ListItem key={key} item={item} />
                    })}
                    {grouped.length ?
                        <ListItem item={{
                            name: 'Irregular',
                            drivers: grouped
                        }}/>
                    : null }
                </>
            )}
        </Body>
    )
}

const ListItem = ({item}) => {
    const [state] = useContext(DefaultStore);

    return (
        <Column>
            <TableHeader
                headers={[
                    {
                        width: '100%',
                        label: (
                            <>
                                {item.name}
                                {state.selectedStation === 'all'
                                    && item.station
                                    && <Spacer inline left={2}>
                                        <Tag small={true} style={{fontFamily: Theme.font.body}}>{item.station.code}</Tag>
                                    </Spacer>}
                            </>
                        ),
                        style: {
                            justifyContent: 'space-between'
                        }
                    }
                ]}
            />
            <li style={{height: '60vh', overflow: 'auto'}}>
                <ul style={{padding: 0}}>
                    {item.drivers.sort((a, b) => a.fullName.localeCompare(b.fullName)).map((driver,key)=>(
                      <>
                        {(driver.isEnabled && driver.isArchive == false && driver.user.isArchive == false) && <TableData key={key} compact={true} width={'100%'}>
                            <People
                                jobTitle={(driver.user.title)?driver.user.title:driver.user.userRoles[0].roleName}
                                name={
                                    state.groupData && driver.schedule ? (
                                        <>
                                            <ThirdTitle as={'span'} style={{fontSize: Theme.font.extraSmall.size}}>{driver.schedule.name}</ThirdTitle>
                                            <Spacer>{driver.fullName}</Spacer>
                                        </>
                                    ) : driver.fullName
                                }
                                img={driver.user.image}
                            />
                        </TableData>}
                      </>
                    ))}
                    {!item.drivers.length && <Empty/>}
                </ul>
            </li>
            <TableFooter sticky={true}>
                <ThirdTitle style={{fontWeight: 'normal'}}>
                    {item.drivers.filter(d=>{ if(d.isEnabled == true && d.isArchive == false && d.user.isArchive == false) return true }).length} Driver{item.drivers.length === 1 ? '' : 's'}
                </ThirdTitle>
            </TableFooter>
        </Column>
    )
}

export default List;
