import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {useHistory} from 'react-router-dom'
import {Button, Icon, Spacer, Theme, ThirdTitle} from '@dspworkplace/ui';
import IncidentsContext, {IncidentsProvider} from "./context";
import API from "./api";
import {DueBadge, getDueStatus, IncidentProgress, IncidentsFilter, IncidentsHeader} from './Common/UI';
import {matchingConfig} from './Common/IncidentsFilter';
import {openReportIncidentForm} from "./Common/ReportIncidentForm";
import {Link, Tag, Wrapper, Status} from "../../components/UI";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../components/Table";
import People from "../../components/People";
import Empty from "../../components/Empty";
import Loading from "../../components/Loading";
import {fullDateFromUTC} from "../../components/TimeFormat";
import {toast} from "../../components/Toasts";
import IncidentTimeline from "./Common/IncidentTimeline";
import {confirm} from "../../components/Confirm";
import {useSelectedStation} from "../../components/StationSelector";
import {loadingDialog} from "../../components/Dialog";
import useSchedulerApi from "../Scheduler/Common/api";
import {useIntersection} from "../../hooks/useIntersect"
import FilterServerResolver from "../../components/Filter/filterResolver.server";
import moment from "moment";
import { Virtuoso } from "react-virtuoso";
import {AccessCheck, IsGranted} from "../../security";

const IncidentTableContainer = styled.div`
    overflow:auto;
    position: absolute;
    min-width:1300px !important;
    width:auto;
    // @media (max-width: 1500px) {
    //     position: absolute;
    // }
`;

const timeZoneName = {
        'ET': 'America/Detroit',
        'CT': 'America/Chicago',
        'MT': 'America/Denver',
        'PT': 'America/Los_Angeles',
        'AKT': 'US/Alaska',
        'HT': 'Pacific/Honolulu',
        'AZ': 'America/Phoenix',
        'CET': 'Europe/Amsterdam'
    };

const IncidentsList = () =>  {
    const [state, dispatch] = useContext(IncidentsContext);
    const [selectedStation] = useSelectedStation();
    const [incident, setIncident] = useState();
    const [initialLoad, setInitialLoad] = useState(false);
    const history = useHistory();

    const { dropLoadoutShift } = useSchedulerApi();

    const setSortBy = field => {
        dispatch({
            type: "SET_SORT_BY",
            payload: {
                field: field,
            },
        });
    };

    const loadList = useCallback((page = 0, filters = {}, initialRender = false) => {
        if (!selectedStation){
            toast({
                type: 'warning',
                title: "To use any section it's necessary to select or add one station !!!",
                useClose: true,
                timeout: false
            });
        }

        return API.fetchList({ ...filters, station: selectedStation, page }).then(
            response => {
                const data = response?.data?.data?.incident.result;
                const total = response?.data?.data?.incident.total || 0;
                const incidentsType = response?.data?.data?.incident.incidentsType || [];
                const canDelete = response?.data?.data?.incident.canDelete;
                data.map(function (item) {
                    let Progress = 0;
                    if (item.incidentType.prefix === "Coa" || item.incidentType.prefix === "Cor") {
                        if (item.isSaved) Progress = 10;
                        if (item.currentPhaseCount > 0) Progress = 50;
                        if (item.driverTaskRead) Progress = 75;
                        if (item.currentPhaseCount == item.phasesCount) Progress = 95;
                        if (item.isReviewd) Progress = 100;
                    } else if (item.incidentType.prefix === "RRS") {
                        Progress = 100;
                    } else if (item.incidentType.prefix === "Eno") {
                        if(item.currentPhaseCount < 1) Progress = 50;
                        if(item.currentPhaseCount > 0) Progress = 100;
                    } else {
                        Progress = Math.round((item.currentPhaseCount / item.phasesCount) * 100);
                    }
                    item.progress = Progress;
                    return item;
                });
                dispatch({
                    type: 'SET_INCIDENTS',
                    payload: {
                        incident : data,
                        incidentType: incidentsType,
                        totalIncidents:total,
                        page,
                        filter : {'station':selectedStation,'userStatus':state.filters.userStatus},
                        incidentData: data,
                        canDelete: canDelete,
                    }
                })
                if (initialRender) {
                    setInitialLoad(true)
                }
            },
            error => {
                if ( error.response )
                    toast({
                        type: 'error',
                        title: 'Error',
                        content: error.response.data.message
                    })
            }
        );
    }, [dispatch, selectedStation]);

    useEffect(() => {
        const existingIncidentFilters = JSON.parse(sessionStorage.getItem('incidentFilters'));
        const filterObj = new FilterServerResolver(existingIncidentFilters || state.filters)
        loadList(0, filterObj, true);

        return () => API.cancel();
    }, [loadList]);

    const LoaderRef = React.useRef(null);
    const entry = useIntersection(LoaderRef,{
        threshold : 0.10
    })
    const isLoadingVisible = !!entry?.isIntersecting

    useEffect(()=>{
        if(isLoadingVisible && state.loaded && state.totalIncidents > 0 && state.incidents.length < state.totalIncidents){
            const filterObj = new FilterServerResolver(state.filters,matchingConfig)
            loadList(state.page+1,filterObj)
        }
        //return () => API.cancel();
    },[isLoadingVisible])

    const report = evt => {
        openReportIncidentForm().then(
            ({data: incident, action}) => {
                if (!incident)
                    return false;

                const hide = loadingDialog();

                API.startIncident(incident).then(
                    async(response) =>{
                        if(response.data.data.result.incidentType.token == 'Dro'){
                            // if droproute then delete
                            if(response.data.data.result.driverRoute && response.data.data.result.driverRoute.id){
                                let a = await API.incidentDroprouteAutofill(response.data.data.result.id);
                                await dropLoadoutShift({
                                    driverId: response.data.data.result.driverRoute.id,
                                    incidentId:response.data.data.result.id
                                });
                            }
                        } else if(response.data.data.result.incidentType.token == 'Pic'){
                            // if droproute then delete
                            if(response.data.data.result.driverRoute && response.data.data.result.driverRoute.id){
                                let a = await API.incidentPickuprouteAutofill(response.data.data.result.id);
                            }
                        }

                        action === 'next'
                        ? history.push(`/incidents/${response.data.data.result.id}`)
                        : toast({
                            type: 'success',
                            title: 'Incident successfully reported',
                            content: <Link style={{color: 'inherit'}} to={`/incidents/${response.data.data.result.id}`}>View incident</Link>,
                            timeout: 8000
                        }).then(loadList)},
                    error => {
                        if ( error.response )
                            toast({
                                type: 'error',
                                title: 'Error',
                                content: error.response.data.message
                            })
                    }
                ).then(hide)
            }
        );
    };

    const archiveIncident = async(incident) => {
        let confirmation = await confirm(`Are you sure you want to delete this incident permanently?`);
        if (confirmation) {
            await API.archiveIncident(incident.id);
            dispatch({
                type: 'REMOVE_INCIDENT',
                payload: incident
            });
            toast({
                title: 'Incident Deleted Successfully',
                type: 'success',
                timeout: 8000,
                useIcon: true,
                useClose: true
            });
        }
    };

    return (
        <Wrapper style={{ position: 'relative', marginRight: '20px' }}>
            <Spacer bottom={5} top={5}>
                <IncidentsFilter initialLoad={initialLoad} />
            </Spacer>
            <IncidentTableContainer>
                <Table>
                    <TableHeader
                        headers={[
                            { width: "88px", label: "Station" },
                            { width: "160px", label: "ID" },
                            { width: "204px", label: "Incident" },
                            { width: "180px", sortBy: "driver", label: "Driver" },
                            { width: "180px", sortBy: "assignTo", label: "Assigned To" },
                            { width: "192px", sortBy: "progress", label: "Progress" },
                            { width: "auto", sortBy: "due", label: "Due" },
                        ]}
                        compact={true}
                        sortedBy={state.sortBy}
                        sortByFunc={setSortBy}
                    />
                    {!state.loaded && <Loading style={{ width: "80px", display: "block", margin: "20px auto" }} />}

                    {state.loaded && !state.incidents.length && <div style={{"minHeight": "55vh"}}><Empty /></div>}
                    {state.incidents && state.incidents.length > 0 && <Virtuoso
                        style={{ height: "60vh" }}
                        data={state.incidents}
                        totalCount={state.incidents.length}
                        itemContent={(k, i) => {
                            i.dueStatus = getDueStatus(i.nextDueDate.timestamp * 1000);
                            return (
                                <TableRow key={k} style={{ backgroundColor: ((i.isDraft && i.isDraft == "1") ? "#f9f5ef" : "") }}>
                                    <TableData width="88px">
                                        <Tag>{i.station.code}</Tag>
                                    </TableData>
                                    <TableData width="160px">
                                        <div>
                                            {(i.isDraft && i.isDraft == "1") && <Status current={'warning'}>Draft</Status>}
                                            {(i.isDraft && i.isDraft == "1") && <Spacer inline left={1}></Spacer>}
                                            <Link
                                                title={i.incidentType.name}
                                                to={i.incidentType.prefix == "RRS" ? "" : `/incidents/${i.id}`}
                                            >
                                                {i.incidentId}
                                            </Link>
                                            {i.poorMetric && <Spacer inline left={1}>{" - " + i.poorMetric}</Spacer>}
                                            <br />
                                            {i.station.timezone != null && (
                                                <small>
                                                    {i.incidentDate &&
                                                        moment
                                                            .utc(i.incidentDate.timestamp * 1000)
                                                            .tz(timeZoneName[i.station.timezone])
                                                            .format(" MMM D, Y - h:mma ")}{" "}
                                                    - {i.station.timezone}
                                                </small>
                                            )}
                                        </div>
                                    </TableData>
                                    <TableData width="204px">
                                        <div>
                                            <Link to={i.incidentType.prefix == "RRS" ? "" : `/incidents/${i.id}`}>
                                                {i.title}
                                            </Link>
                                            <br />
                                            <small>{i.incidentType.name}</small>
                                        </div>
                                    </TableData>
                                    <TableData width="180px">
                                        <People
                                            name={i.driver.fullName}
                                            jobTitle={i.driver.jobTitle}
                                            profile={i.driver.profile}
                                        />
                                    </TableData>
                                    {i.assignTo.name != 1 ? (
                                        <TableData width="180px">
                                            <People
                                                name={i.assignTo.name}
                                                jobTitle={i.assignTo.jobTitle}
                                                img={i.assignTo.profile}
                                            />
                                        </TableData>
                                    ) : (
                                        <TableData width="180px"></TableData>
                                    )}
                                    <TableData width="192px">
                                        <IncidentProgress
                                            phases={i.phasesCount}
                                            current={i.currentPhaseCount}
                                            description={i.currentPhaseDescription}
                                            prefix={i.incidentType.prefix}
                                            isReviewd={i.isReviewd}
                                            driverTaskRead={i.driverTaskRead}
                                            isSaved={i.isSaved}
                                            isDraft={(i.isDraft && i.isDraft == "1") ? true : false}
                                        />
                                    </TableData>
                                    <TableData
                                        width="160px"
                                        style={{ flexDirection: "column", alignItems: "baseline" }}
                                    >
                                        <DueBadge status={i.dueStatus} isCompleted={i.isCompleted} />
                                        <Spacer bottom={1} />
                                        <small>{fullDateFromUTC(i.nextDueDate.timestamp * 1000)}</small>
                                    </TableData>
                                    <TableData width="auto" style={{ marginLeft: "auto" }}>
                                        <Icon.TimeLine
                                            size="20px"
                                            color={Theme.colors.info.border}
                                            onClick={() => setIncident(i)}
                                        />
                                        <Spacer inline right={3} />
                                        {i.incidentType.prefix != "RRS" && (
                                            <>
                                                <Link to={i.incidentType.prefix == "RRS" ? "" : `/incidents/${i.id}`}>
                                                    <Icon.Advance
                                                        size="20px"
                                                        color={Theme.colors.info.border}
                                                        onClick={() => { }}
                                                    />
                                                </Link>
                                                <Spacer inline right={3} />
                                            </>
                                        )}
                                        {state.canDelete && <IsGranted feature={AccessCheck.features.INCIDENTS} ops={AccessCheck.OPS.D}>
                                            <Icon.Times
                                                size="20px"
                                                color={Theme.colors.info.border}
                                                onClick={() => archiveIncident(i)}
                                            />
                                        </IsGranted>}
                                    </TableData>
                                </TableRow>
                            );
                        }}
                    />}
                    <TableFooter sticky={true}>
                        <ThirdTitle style={{ fontWeight: "normal" }}>
                            {state.incidents.length} of {state.incidents.length} incidents
                        </ThirdTitle>
                        <Button type="primary" size="small" onClick={report}>
                            Report Incident
                        </Button>
                    </TableFooter>
                </Table>
            </IncidentTableContainer>
            {/* <IncidentTableContainer>
                <Table>
                    <TableHeader
                        headers={[
                            { width: '88px', label: 'Station' },
                            { width: '160px', label: 'ID' },
                            { width: '204px', label: 'Incident' },
                            { width: '144px', sortBy: 'driver', label: 'Driver' },
                            { width: '144px', sortBy: 'assignTo', label: 'Assigned To' },
                            { width: '192px', label: 'Progress' },
                            { width: 'auto', sortBy: 'due', label: 'Due' },
                        ]}
                        compact={true}
                        sortedBy={state.sortBy}
                        sortByFunc={setSortBy}
                    />
                    {!state.loaded && <Loading style={{ width: '80px', display: 'block', margin: '20px auto' }} />}

                    {state.loaded && !state.incidents.length && <Empty />}

                    {state.incidents.map((i, k) => {
                        i.dueStatus = getDueStatus(i.nextDueDate.timestamp * 1000);
                        return (
                            <TableRow key={k}>
                                <TableData width='88px'>
                                    <Tag>{i.station.code}</Tag>
                                </TableData>
                                <TableData width='160px'>
                                    <div>
                                        <Link title={i.incidentType.name} to={i.incidentType.prefix == 'RRS' ? '' : `/incidents/${i.id}`}>{i.incidentId}</Link>
                                        <br />
                                        {i.station.timezone != null && <small>{i.incidentDate && moment.utc(i.incidentDate.timestamp * 1000).tz(timeZoneName[i.station.timezone]).format(" MMM D, Y - h:mma ")} - {i.station.timezone}</small>}
                                    </div>
                                </TableData>
                                <TableData width='168px'>
                                    <div>
                                        <Link to={i.incidentType.prefix == 'RRS' ? '' : `/incidents/${i.id}`}>{i.title}</Link>
                                        <br />
                                        <small>{i.incidentType.name}</small>
                                    </div>
                                </TableData>
                                <TableData width='180px'>
                                    <People
                                        name={i.driver.fullName}
                                        jobTitle={i.driver.jobTitle}
                                        profile={i.driver.profile}
                                    />
                                </TableData>
                                {i.assignTo.name != 1 ?
                                    <TableData width='180px'>
                                        <People
                                            name={i.assignTo.name}
                                            jobTitle={i.assignTo.jobTitle}
                                            img={i.assignTo.profile}
                                        />
                                    </TableData> :
                                    <TableData width='180px'>

                                    </TableData>}
                                <TableData width='192px'>
                                    <IncidentProgress
                                        phases={i.phasesCount}
                                        current={i.currentPhaseCount}
                                        description={i.currentPhaseDescription}
                                        prefix={i.incidentType.prefix}
                                        isReviewd={i.isReviewd}
                                        driverTaskRead={i.driverTaskRead}
                                        isSaved={i.isSaved}
                                    />
                                </TableData>
                                <TableData width='160px' style={{ flexDirection: 'column', alignItems: 'baseline' }}>
                                    <DueBadge status={i.dueStatus} isCompleted={i.isCompleted} />
                                    <Spacer bottom={1} />
                                    <small>{fullDateFromUTC(i.nextDueDate.timestamp * 1000)}</small>
                                </TableData>
                                <TableData width='auto' style={{ marginLeft: 'auto' }}>
                                    <Icon.TimeLine
                                        size='20px'
                                        color={Theme.colors.info.border}
                                        onClick={() => setIncident(i)}
                                    />
                                    <Spacer inline right={3} />
                                    {i.incidentType.prefix != 'RRS' && (
                                        <>
                                            <Link to={i.incidentType.prefix == 'RRS' ? '' : `/incidents/${i.id}`}>
                                                <Icon.Advance
                                                    size='20px'
                                                    color={Theme.colors.info.border}
                                                    onClick={() => { }}
                                                />
                                            </Link>
                                            <Spacer inline right={3} />
                                        </>
                                    )}
                                    <Icon.Times
                                        size='20px'
                                        color={Theme.colors.info.border}
                                        onClick={() => archiveIncident(i)}
                                    />
                                </TableData>
                            </TableRow>
                        );
                    })}

                    <TableRow ref={LoaderRef}>
                        {state.loaded && state.totalIncidents > 0 && state.incidents.length < state.totalIncidents && <Loading style={{ width: '40px', margin: '12px auto', display: 'block' }} />}
                    </TableRow>

                    <TableFooter sticky={true}>
                        <ThirdTitle style={{ fontWeight: 'normal' }}>{state.incidents.length} of {state.totalIncidents} incidents</ThirdTitle>
                        <Button type='primary' size='small' onClick={report}>Report Incident</Button>
                    </TableFooter>
                </Table>
            </IncidentTableContainer> */}
            {incident && <IncidentTimeline visible={true} toggle={setIncident} incident={incident} />}
        </Wrapper>
    );
};

const App = () => {
    return (
        <IncidentsProvider>
            <IncidentsHeader/>
            <IncidentsList/>
        </IncidentsProvider>
    )
};

export default App;
