import moment from "moment";

export const generateMultiEditReqBody = (selectedRows, data, message) => {
    let paramsData = [...selectedRows];
    const selectValue = [];

    paramsData.forEach((item) => {
        const id = item.id;
        const newRecord = {
            ...item,
            ...data,
            shiftId: data.shiftType,
            shiftType: data.extra
        }

        const oldRecord = {
            ...item
        };

        let startTime = newRecord.utcStartTime
            || newRecord.startTime;

        let endTime = newRecord.utcEndTime
            || newRecord.endTime;

        let shiftId = newRecord.shiftId
            || newRecord.typeId
            || newRecord.shiftType.id;

        let routeStatus = newRecord.routeStatus;
        let isVoluntary = newRecord.isVoluntary;
        let backupStatus = newRecord.backupStatus;
        let onCall = newRecord.onCall;
        const manualApproveDriverRequest = (newRecord.autoApproveDriverRequest === true) ? newRecord.manualApproveDriverRequest : false;
        const addEditTempObj = {
            method: "addTemp",
            routeId: id,
            isNew: true,
            isTemp: oldRecord.isTemp || true,
            hasAlert: true,
            newDriverId: newRecord.driverId,
            oldDriverId: oldRecord.driverId,
            newDate: newRecord.shiftDate,
            oldDate: oldRecord.shiftDate,
            routeStatus: routeStatus,
            isVoluntary: isVoluntary,
            startTime: startTime,
            endTime: endTime,
            shiftId: shiftId,
            timezoneOffset: new Date().getTimezoneOffset(),
            shiftInvoiceType: newRecord.shiftInvoiceType,
            isEditTrain: newRecord.isEditTrain,
            isEditDuty: newRecord.isEditDuty,
            autoApproveDriverRequest: newRecord.autoApproveDriverRequest,
            manualApproveDriverRequest: manualApproveDriverRequest,
            item: {
                qty: newRecord.qty,
                note: newRecord.note,
                newNote: newRecord.newNote
            },
            backupStatus: backupStatus,
            onCall: onCall,
            message: message
        };

        if (newRecord.isNew) {
            addEditTempObj.method = "editTemp";
            addEditTempObj.id = newRecord.tempDriverRouteId || newRecord.id;
            addEditTempObj.shift = {
                startTime: newRecord.startTime,
                endTime: newRecord.endTime,
                shiftType: newRecord.shiftId,
                shiftInvoiceType: newRecord.shiftInvoiceType
            };
        }

        if (item.deleted) {
            selectValue.push({
                method: 'deletedShift',
                routeId: item.routeId,
                shiftInvoiceType: data.shiftInvoiceType,
                shiftType: data.shiftType,
                startTime: data.utcStartTime,
                endTime: data.utcEndTime,
            })
        } else if (item.isSecondShift) {
            const secondShift = {
                ...data,
                isEdit: true,
                startTime: moment.utc(data.startTime, "H.m").format("HH:mm"),
                endTime: moment.utc(data.endTime, "H.m").format("HH:mm"),
                shiftNote: data.note,
            }
            selectValue.push({
                method: 'secondShift',
                data: secondShift,
                id: item.routeId,
                shiftDate: item.shiftDate,
                isEdit: true,
                timezoneOffset: new Date().getTimezoneOffset(),
            })
        } else if (item.isDuty || item.isTrain) {
            const isTrain = item.isTrain;
            const newShift = {
                ...data,
                isTrain: isTrain ? true : false,
                isDuty: isTrain ? false : true,
                startTime: moment.utc(data.startTime, "H.m").format("HH:mm"),
                endTime: moment.utc(data.endTime, "H.m").format("HH:mm"),
            }
            if ([6, 5].includes(data.extra.category)) {
                selectValue.push({
                    method: 'trainAndDuty',
                    data: newShift,
                    id: item.routeId,
                    shiftDate: item.shiftDate,
                    isEdit: true,
                    timezoneOffset: new Date().getTimezoneOffset(),
                    isTrain: newShift.isTrain || false,
                    isDuty: newShift.isDuty || false,
                    driverId: item.driverId || false,
                })
            } else {
                selectValue.push({
                    method: 'trainAndDuty',
                    routeId: item.id,
                    shiftId: newShift.extra.id,
                    isEditTrain: isTrain,
                    isEditDuty: !isTrain,
                    shiftInvoiceTypeId: newShift.shiftInvoiceType,
                    startTime: moment.utc(newShift.startTime, "H.m").format("HH:mm"),
                    endTime: moment.utc(newShift.endTime, "H.m").format("HH:mm"),
                    timezoneOffset: new Date().getTimezoneOffset(),
                })
            }
        } else if (item.isRescuer) {
            const rescueShift = {
                ...data,
                isEdit: true,
                startTime: moment.utc(data.startTime, "H.m").format("HH:mm"),
                endTime: moment.utc(data.endTime, "H.m").format("HH:mm"),
                shiftNote: data.note,
            }
            selectValue.push({
                method: 'rescueShift',
                data: rescueShift,
                id: item.routeId,
                shiftDate: item.shiftDate,
                isEdit: true,
                timezoneOffset: new Date().getTimezoneOffset(),
            })
        } else {
            selectValue.push({ ...addEditTempObj })
        }
    })
    return selectValue;
}

export const generateMultipleDriverSkillsReqBody = (drivers = [], shiftTypeId) => {
    return ((drivers || []).filter(item => item?.driverId != "0.0.0") || []).map(driver => {
        return {
            driverId: driver?.driverId,
            shiftId: shiftTypeId,
            isTemp: true
        }
    })
}