import React, {useState, useCallback, useEffect} from "react";
import {Icon} from "@dspworkplace/ui";
import styled from "styled-components";
import Tooltip from '../../../../components/Tooltip/tooltip';
import {DataGrid} from '../../../../components/Grid';
import {EditorCombobox, EditorComboboxColor, EditorNumber, EditorDatePicker} from '../../../../components/Grid/Editors'
import {toast} from '../../../../components/Toasts'
import {ToggleGroupFormatter} from 'react-data-grid';

import { confirm as confirmPortal } from "../../../../components/Confirm";
import {PriorityCol} from "./gridRenders";
import {GridAction} from "../../../../components/Grid/formatters";
import TextModal from "../../../../components/Grid/Editors/TextModal/TextModal";
import DropDownModal from "../../../../components/Grid/Editors/DropDownModal/DropDownModal";
import { VMTaskStatusSelector } from "../../../../domain/vehicle_maintenance_task/state/VehicleMaintenanceTaskStatus";
import { VMTaskPriority } from "../../../../domain/vehicle_maintenance_task/state/VehicleMaintenanceTaskPriority";
import { useRecoilCallback } from "recoil";
import { VehicleStatusState } from "../../../../domain/vehicle/state/VehicleStatusState";
import { VehicleAmzStatusState } from "../../../../domain/vehicle/state/VehicleAmzStatusState";
import { GetVehicleLookup } from "../../../../domain/vehicle/api/getVehicles";
import { VehicleMaintenancePlanInterface } from "../../../../domain/vehicle_maintenance_plan/api";
import useGridApi from "../../../../api/useGridApi";
import {
    GetVehiclePlanTasks
} from "../../../../domain/vehicle_maintenance_task/api/GetVehiclePlanTasks";
import {UpdateVehiclePlanTasks} from "../../../../domain/vehicle_maintenance_task/api/UpdateVehiclePlanTasks";
import {VehicleChangeStatus} from "../../../../domain/vehicle/api/VehiclechangeStatus";
import { VehicleUploadImages } from "../../../../domain/vehicle/api/VehicleUploadImages";
import {VehicleUploadDocuments} from "../../../../domain/vehicle/api/VehicleUploadDocuments";
import {VehicleChangeMileage} from "../../../../domain/vehicle/api/VehiclechangeMileage";

import { openTaskForm } from './Common/CreateTaskForm';
import { openUploadTaskFileForm } from './Common/UploadTaskFileForm';
import { openUploadTaskImageForm } from './Common/UploadTaskImageForm';
import { openTimeline } from '../../../../components/Timeline';
import moment from "moment";
import {CreateVehiclePlanTasks, DeleteVehicleTask} from "../../../../domain/vehicle_maintenance_task/api/CreateVehiclePlanTasks";
import { useSelectedStation } from "../../../../components/StationSelector/StationPicker";
import {useAuth} from "../../../../Auth";
import {openPlanForm} from "../../VehicleManagement/PlanForm";
import StatusColored from "../../../../components/Form/Combobox/Options/statusColored";
import { fetchDrivers } from "../../../Employees/api";
import {VMPlanType} from "../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlanType";
import {VMPlanCategory} from "../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlanCategory";

const Wrapper = styled.div`
    min-height: 100%;
    //max-width: 850px;
    width: 100%;
`;

const TaskListPage = () => {
    return (
        <Wrapper>
            <TaskList />
        </Wrapper>
    );
};

const parseRowToAPIRequest = (row,station) => {
    return {
        station_id: station,
        status: row.status,
        priority: row.priority,
        notes: row.notes,
        category_id: row?.category?.id,
        vehicle_id: row?.vehicle?.id,
        pmMileage: parseFloat(row.pmMileage),
        pm_date: row.pmDate,
        vendor_id: row?.vendorId,
        location: row.location,
        amzFleetToolStatus: row.amzFleetToolStatus,
        estimateCost: parseFloat(row.estimateCost)
    }
}

const getColumns = ({handleDeleteTask,lookups,openTimeline,handleUploadImage,handleUploadDocuments,customGroup,handleEditTask,driversList,vehiclesList}) =>{
    const priorityList = lookups.taskPriority.reduce((acc, priority) => {
        acc[priority.value] = priority;
        return acc;
    }, {});

    const statusList = lookups.taskStatus.reduce((acc,status)=>{
        acc[status.value] = status
        return acc
    },{})

    return [
        {
            key:"vehicleId",
            name: "Vehicle ID",
            minWidth: 140,
            sortable: true,
            groupable: true,
            filterable: true,
            filterMultiple: true,
            validValues: vehiclesList,
            groupFormatter:({row})=>{
                return customGroup == 'vehicleId' && row.childRows[0].vehicle ?<div class="rdg-group-cell-content"><Tooltip content={row.childRows[0].vehicle.vin.substr(row.childRows[0].vehicle.vin.length - 6)} direction={'bottom'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{row.childRows[0].vehicle.vehicleId}<ToggleGroupFormatter row={row} isExpanded={row.isExpanded} /></span></Tooltip></div>:<></>;
            },
            formatter:({row})=>{
                return <Tooltip content={row.vehicle.vin} direction={'bottom'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{row.vehicle.vehicleId}</span></Tooltip>
            }
        },
        {
            name: "Ticket Status",
            key: "status",
            sortable: true,
            filterable: true,
            filterMultiple:true,
            width:90,
            editor: EditorCombobox,
            validValues: lookups.taskStatus,
            formatter: ({row}) => {
                return statusList[row.status].label
            },
            editable: row => row.status !== "complete" ? true : false
        },
        {
            name: "Priority",
            key: "priority",
            cellClass: 'centered',
            sortable: true,
            filterable: true,
            filterMultiple:true,
            groupable: true,
            minWidth: 80,
            editor: EditorComboboxColor,
            groupFormatter:({row, column})=>{
                return customGroup == 'priority' && row.childRows[0].priority ? <div class="rdg-group-cell-content"><Tooltip content={column?.validValuesKey?.[row.childRows[0].priority]?.label.toUpperCase()} direction={'top'}>
                <div style={{display: "inline-block", width: "25px"}}><StatusColored showLabel={false} row={{label: row.childRows[0].priority, color: column?.validValuesKey?.[row.childRows[0].priority]?.color || null}} /></div><ToggleGroupFormatter row={row} isExpanded={row.isExpanded} />
                </Tooltip></div> :<></>;
            },
            formatter: PriorityCol,
            validValues: lookups.taskPriority,
            validValuesKey: priorityList,
            editable: row => (row.status !== "archived") ? true : false
        },
        {
            key:"type",
            name: "Type",
            sortable: true,
            filterable: true,
            recoilOptions: VMPlanType,
            isWatch:true,
            minWidth: 90,
            maxWidth: 150,
            formatter:({row})=>{
                let type = (row.category !== null) ? row.category?.parent?.label : '-';
                return <Tooltip content={type} direction={'left'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{type}</span></Tooltip>
            }
        },
        {
            key:"category",
            name: "Category",
            sortable: true,
            minWidth: 150,
            maxWidth: 200,
            filterable: true,
            recoilOptions: (type) => VMPlanCategory(type?.value),
            formatter:({row})=>{
                let category = (row.category !== null) ? row.category?.label : '-';
                return <Tooltip content={category} direction={'left'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{category}</span></Tooltip>
            }
        },
        {
            key:"notes",
            name: "Notes",
            sortable: true,
            minWidth: 200,
            editor: TextModal,
            formatter:({row})=>{
                return <Tooltip content={row.notes} direction={'left'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{row.notes}</span></Tooltip>
            }
        },
        {
            key:"createdAt",
            name: "Created",
            sortable: true,
            width: 140,
            formatter:({row}) => {
                return moment.utc(row.createdAt).local().format('MM/DD/YYYY')
            }   
        },
        {
            key:"updatedById",
            name: "Created By",
            sortable: false,
            filterable: true,
            filterMultiple: false,
            width: 140,
            formatter:({row}) => {
                if (row?.autoGenerated === true) return "System";
                if(row?.updatedBy && row?.updatedBy?.friendlyName) {
                    return row?.updatedBy?.friendlyName;
                }
                return ' - ';
            },
            validValues: driversList
        },
        {
            key:"vehicle.currentMileage",
            name: "Vehicle Mileage",
            minWidth:80,
            formatter:({row})=>{
              return row.vehicle.currentMileage
            },
            editor: EditorNumber
        },
        {
            key:"pmMileage",
            name: "PM Due Mileage",
            minWidth:80,
            editor: EditorNumber,
            editable:(row) => row.category?.isLastMileServiceRequired,
            formatter:({row}) => {
                return row.pmMileage ? row.pmMileage : "-"
            }
        },
        {
            key:"pmDate",
            name: "PM Due Date",
            width: 142,
            editable:(row) => row.category?.isLastServiceDateRequired,
            editor: EditorDatePicker,
            formatter:({row}) => {
                return row.pmDate ? moment(row.pmDate).local().format('ddd, MMM Do') : "-"
            }
        },
        {
            key:"vehicle.status",
            name: "Van Status",
            width:120,
            editor: EditorCombobox,
            formatter:({row})=>{
                return row.vehicle.status
            },
            editorOptions: {
                editOnClick: true
            },
            validValues: lookups.vehicleStatus,
            editable: row => (row.status !== "archived") ? true : false
        },
        {
            key:"amzFleetToolStatus",
            name: 'AMZ Status',
            editor: EditorCombobox,
            width:140,
            editorOptions: {
                editOnClick: true,
            },
            validValues: lookups.vehicleAmzStatus
        },
        {
            key:"vendor",
            name: "Vendor",
            minWidth: 140,
            formatter:({row})=>{
                let vendor = row.vendor !== null ? row.vendor?.name : "-";
                return <Tooltip content={vendor} direction={'left'}><span style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',display:'block'}}>{vendor}</span></Tooltip>
            }
        },
        {
            key:"estimateCost",
            name: "Cost",
            editor: EditorNumber
        },
        {
            key:"incidents",
            name: "Associated Incident",
            width: 200,
            formatter: ({ row }) => {
                let incident = (row.incidents) ? row.incidents.map((i) => i.label).join(' ,') : ''
                return <Tooltip content={incident} direction={'bottom'}><span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>{incident}</span></Tooltip>;
            }
        },
        /**{
            key:"vehicle.currentMileage",
            name: "Vehicle Mileage",
            minWidth:80,
            formatter:({row})=>{
              return row.vehicle.currentMileage
            },
            editor: EditorNumber
        },
        {
            key:"documents",
            name: "Documents",
            width: 80,
            formatter: ({ row }) => {
                return (<Tooltip key={'Upload File'} content={'Upload File'} direction={'bottom'}>
                            <Icon.Document style={{ height: '16px', width: '60px' }} onClick={() => { handleUploadDocuments(row) }} />
                        </Tooltip>);
            }
        },
        {
            key:"images",
            name: "Images",
            width: 80,
            formatter: ({ row }) => {
                return (<Tooltip key={'Upload Images'} content={'Upload Images'} direction={'bottom'}>
                            <Icon.Image style={{ height: '16px', width: '60px' }} onClick={() => { handleUploadImage(row) }} />
                        </Tooltip>);
            }
        },
        {
            key:"lastMileage",
            name: "Mileage on last service date",
            sortable: true
        },*/
        /*{
            key:"lastMileage",
            name: "Mileage on last service date",
            sortable: true
        },*/
       {
            key: 'actions',
            name: '',
            width: 40,
            frozen: true,
            cellClass: 'fix-to-the-right',
            formatter:({row})=><GridAction options={(row.status == "archived") ?[
                {title: 'Timeline', icon: Icon.TimeLine,onClick: ()=>{openTimeline({vehicle_maintenance_task_id:row.id})}},
                // {title: 'Delete', icon: Icon.Times,onClick: ()=>{handleDeleteTask(row)}},
            ]:[
                {title: 'Edit', icon: Icon.Edit, onClick: ()=>{handleEditTask(row)}},
                {title: 'Timeline', icon: Icon.TimeLine,onClick: ()=>{openTimeline({vehicle_maintenance_task_id:row.id})}},
                {title: 'Archive', icon: Icon.Times,onClick: ()=>{handleDeleteTask(row)}},
                {title: 'Upload File', icon: Icon.Document,onClick: ()=>{handleUploadDocuments(row)}}
            ]} />
        }
    ]
}

const TaskList = () => {
    const taskState = useState<VehicleMaintenancePlanInterface[]>([]);
    const [selectedStation] = useSelectedStation();
    const [customGroup, setCustomGroup] = useState("vehicleId");
    const { api } = useAuth();
    const defaultFiltersSelected = {
        filters: {
            priority: ["critical", "urgent", "soon", "unknown"],
            status: ["pending", "assigned", "open"],
            updatedById: 'all'
        },
        fieldLabels: { status: "Ticket Status", priority: "Priority", updatedById:"Created By" },
    };

    const [isLoading, setIsLoading] = useState(false);
    const [storeFilters, setStoreFilters] = useState({});
    const [dataCount, setDataCount] = useState(0);
    const [driversList, setDriversList] = useState([]);
    const [vehiclesList, setVehiclesList] = useState([]);
    // @ts-ignore
    const { data, status, setSorter, setFilters, filters, Update, Delete, Add, setPageInfo, pageInfo, setGroupByFilter} = useGridApi({
        load: GetVehiclePlanTasks,
        state: taskState,
        default: {
            sorter: ["createdAt"],
            filter: "priority=critical,urgent,soon,unknown&status=pending,assigned,open",
            groupByFilter: ["vehicleId"],
        },
    });

    const lookups = useRecoilCallback(({snapshot}) => () => {
        const taskStatus = snapshot.getLoadable(VMTaskStatusSelector).getValue()
        const taskPriority = snapshot.getLoadable(VMTaskPriority).getValue()
        const vehicleStatus = snapshot.getLoadable(VehicleStatusState).getValue()
        const vehicleAmzStatus = snapshot.getLoadable(VehicleAmzStatusState).getValue()
        return {taskStatus, taskPriority, vehicleStatus, vehicleAmzStatus}
    },[])
    
    const fetchDriverList = async () => {
        const result = await fetchDrivers(api, selectedStation);
        const drivers = [{ label: 'All', value: 'all'},{ label: 'System', value: 'system' }];
        if (result.drivers.driver.length > 0) {
            result.drivers.driver.map((driver) => {
                drivers.push({"label": driver.name, "value": driver.userId });
            });
        }
        setDriversList(drivers);
    }
    useEffect(() => {
        //Get station drivers
        fetchDriverList();
        //Get station vehicles
        GetVehicleLookup({station_id: selectedStation}).then(response => {
            setVehiclesList(response.data.map((vehicle)=>({
                value: vehicle.id,
                label: vehicle.vehicleId
            })))
        })
    }, [selectedStation]);

    const handleUploadImage = async (row) => {
        const formResult = await openUploadTaskImageForm(row);
        if (formResult) {
            Update(VehicleUploadImages({id: row.id, images: formResult}),null ,row,row, `Error to update IMages from task ${row.id}`,true)
        }
        return true;
    }
    const handleUploadDocuments = async (row) => {
        const formResult = await openUploadTaskFileForm(row);
        if (formResult) {
            Update(VehicleUploadDocuments({id: row.id, documents: formResult}),null ,row,row, `Error to update Documents from task ${row.id}`,true)
        }
        return true;
    }

    const handleDeleteTask = async (row) => {
        if(row?.autoGenerated && row?.status != 'pending'){
            toast({
                type: "error",
                title: `Invalid Operation`,
                content: `You can not delete a task auto generated by the system`
            })
            return
        }
        // let confirmation = await confirmPortal(`Confirm you want to delete this Task ?`);
        const confirmation = await window.confirm(
            "Are you sure you would like to archive this task?"
        );
        if (!confirmation) {
            return false;
        }
        if (confirmation){
            //Delete(DeleteVehicleTask, row, `Error to delete the task "${row.name}"`)
            const params = {
                station_id: selectedStation,
                vehicle_id: row.vehicle?.id,
                category_id: row.category?.id,
                priority: row?.priority,
                status: "archived",
            };   
            Update(UpdateVehiclePlanTasks(row.id, params), null, row, row, `Error to archive task ${row.id}`);
            return true;
        }

        return true;
    }

    const handlePrint = async (data, groupBy) => {
        const paramsNew = {
            "actions":{
                "response":{
                    "Vehicle":{
                        "custom":{
                            "functionName":"printMaintainanceTask",
                            "get":"printMaintainanceTask",
                            "criteria":{
                                "companyId":localStorage.getItem("company"),
                                'data': data,
                                'groupBy': groupBy,
                                "driverId":localStorage.getItem("driverId"),
                                'filters': filters
                            }
                        }
                    }
                }
            }
        };

        let result = await api.post("/api/lazy/manage/data", paramsNew).then(()=>{
            toast({
                type: "success",
                title: `Print Maintenance TaskTracker`,
                content: `You can get this pdf from your workplace_bot chat in few minutes`
            })
        });
    }
    const handleExport = async (data) => {
        const paramsNew = {
            "actions":{
                "response":{
                    "Vehicle":{
                        "custom":{
                            "functionName":"exportMaintainanceTask",
                            "get":"exportMaintainanceTask",
                            "criteria":{
                                "companyId":localStorage.getItem("company"),
                                'data': data,
                                'filters': filters
                            }
                        }
                    }
                }
            }
        };

        let result = await api.post("/api/lazy/manage/data", paramsNew);

        let element = document.createElement("a");
        element.setAttribute("href", result.data.data.exportMaintainanceTask);
        element.setAttribute(
            "download",
            result.data.data.exportMaintainanceTask
        );
        element.setAttribute("target", "_blank");
        // target='_blank'
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        
    }

    const handleNewTask = async () => {
        const formResult = await openTaskForm(null, false, vehiclesList, lookups())
        if(!formResult) return
        
        const params = {
            vehicleId: formResult.vehicleId.value,
            priority: formResult.priority.value,
            categoryId: formResult.category.value,
            quote: parseFloat(formResult.quote),
            estimate_cost: parseFloat(formResult.estimateCost),
            pm_date: formResult.pm_date,
            pm_mileage: parseFloat(formResult.pm_mileage),
            notes: formResult.notes,
            location: formResult.location,
            amzFleetToolStatus: formResult.amzFleetToolStatus && formResult.amzFleetToolStatus.value,
            incidents: formResult.incidents.join(','),
            vendorId: (formResult.vendor?.value) ?? null,
            vanStatus: formResult.vanStatus.value
        }

        Add(CreateVehiclePlanTasks,params,`Error to create new manual task`)
    }

    const handleEditTask = async (row) => {
        const formResult = await openTaskForm(row, false, vehiclesList, lookups())
    
        if(!formResult) return
        const params = {
            station_id: selectedStation,
            vehicle_id: (formResult.vehicleId.value) ?? formResult.vehicleId,
            incidents: (formResult.incidents.join(',')) ?? [],
            amzFleetToolStatus: (formResult.amzFleetToolStatus && formResult.amzFleetToolStatus.value) ?? formResult.amzFleetToolStatus,
            category_id: (formResult.category.value) ?? formResult.category,
            priority: (formResult.priority.value) ?? formResult.priority,
            vendor_id: (formResult.vendor?.value) ? formResult.vendor?.value : (formResult?.vendor) ? formResult?.vendor : null,
            location: formResult.location,
            quote: (formResult.quote != '') ? parseFloat(formResult.quote) : 0,
            estimateCost: (formResult.estimateCost != '') ? parseFloat(formResult.estimateCost) : 0,
            notes: (formResult.notes) ?? null,
            pm_date: (formResult.pm_date) ?? null,
            pmMileage: parseFloat((formResult.pm_mileage) ?? 0),
            vanStatus: formResult.vanStatus.value,
            status: formResult.ticketStatus.value
        }
        Update(UpdateVehiclePlanTasks(row.id,params),null,row,row, `Error to update task ${row.id}`)
    }


    const TaskColumn = getColumns({handleDeleteTask,lookups:lookups(),openTimeline,handleUploadImage,handleUploadDocuments,'customGroup':customGroup,handleEditTask,driversList,vehiclesList});

    const handleSort = (props) => {
        setDataCount(0);
        pageInfo.page = 0;
        setPageInfo(pageInfo.page, pageInfo.limit);
        // Change order by according sorted column by
        if (customGroup.length > 0) {
            let direction = props.fields[0]?.direction !== undefined ? props.fields[0]?.direction : "DESC";
            setGroupByFilter({ fields: [{ columnKey: customGroup[0], direction: direction }] });
        } else {
            setGroupByFilter({ fields: [] });
        }
        let clone = storeFilters;
        clone.page = 0;
        setFilters(clone);
        setSorter(props);
    };

    const handleEditorChange = async (props) => {
        const {value,row,column,index} = props

        if(column.key === 'vehicle.status'){
        //     const result = await confirmPortal(`Do want to assign "${column.name}" to "${value}" on the vehicle ${row.vehicle.status}?`)
        //     if(!result) return
            Update(VehicleChangeStatus({vehicle_id: row.vehicle.id, status: row.vehicle.status}),index,row,row, `Error to update ${column.name} from task ${row.id}`,false)
            return
        }
        if (column.key === 'vehicle.currentMileage') {
        //     const result = await confirmPortal(`Do want to assign "${column.name}" to "${value}" on the vehicle ${row.vehicle.vehicleId}?`)
        //     if(!result) return
            Update(VehicleChangeMileage({vehicle_id: row.vehicle.id, mileage: row.vehicle.currentMileage}),index,row,row, `Error to update ${column.name} from task ${row.id}`,false)
            return
        }
       
        if (column.key == 'status' && value == 'complete' && row.planItem) {
            // const result = await confirmPortal(`Do you want to create new Task?`);
            // if(result){
            //     api.post("api/v2/vehicle/maintenance/task/createTask", {id:row.id, vehicleID:row.vehicle.id}).then((res) => Add( (res) => Promise.resolve(res) , res,`Error to create new manual task`) );
            // }
            if (row.res) {
                let res = row.res;
                Add( (res) => Promise.resolve(res) , res,`Error to create new manual task`);
            }
        }
        // const result = await confirmPortal(`Do want to assign "${column.name}" to "${value}" on the task ${row.id}?`)
        // if(!result) return
        const rowToUpdate = parseRowToAPIRequest(row,selectedStation)
        Update(UpdateVehiclePlanTasks(row.id,rowToUpdate),index,row,row, `Error to update ${column.name} from task ${row.id}`)

        //const confirm = await confirmPortal(confirmationMessage);

    }

    const handleFilter = (props) => {
        props.vehicleId = (props.vehicleId && props.vehicleId != '')?props.vehicleId.join(',').toString():'';
        if(props.type && props.type.value == "") delete props.type;
        if(props.category && props.category.value == "") delete props.category;
        setFilters(props);
    }
    const setCustomRowClass = (row) => {
        if(row.status === "complete"){
            return 'complete';
        }
        return null;
    }
    const isAtBottom = ({ currentTarget }: React.UIEvent<HTMLDivElement>): boolean => {
        {/*let scrollLeft;
        if (scrollLeft !== currentTarget.scrollLeft) {
            return false;
        }*/}
        if (currentTarget.scrollTop + currentTarget.offsetHeight > currentTarget.scrollHeight - 10) {
            return true;
        }
        return false;
    }

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (isLoading && !isAtBottom(event)) return;

        if (isAtBottom(event) && dataCount != taskState[0].length) {
            let page = false;
            if (filters != "") {
                const paramsArray = filters.split("&").map((param) => {
                    const [key, value] = param.split("=");
                    return { key, value };
                });

                for (let i = 0; i < paramsArray.length; i++) {
                    if (paramsArray[i].key === "page") {
                        page = true;
                    }
                }
            }

            setDataCount(taskState[0].length);
            setIsLoading(true);

            // it's for filter
            let clone = storeFilters;
            if (page) {
                clone.page = pageInfo.page + 1;
                setPageInfo(pageInfo.page + 1, pageInfo.limit);
            } else {
                clone.page = 1;
                setPageInfo(1, pageInfo.limit);
            }
            setFilters(clone);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const element = document.getElementsByClassName("dsp-header-show-text")
        if (element?.[0]?.style?.gridTemplateColumns) {
            const columns = element?.[0]?.style?.gridTemplateColumns.split(" ") || []
            columns[customGroup.length > 0 ? 1 : 0] = "0px";
            element[0].style.gridTemplateColumns = columns.join(" ");
        }
    }, [status])

    return (
        <DataGrid
            title={"Maintenance TaskTracker"}
            columns={TaskColumn}
            status={status}
            rows={data}
            onSort={handleSort}
            onEditorChange={handleEditorChange}
            onFilter={handleFilter}
            onAdd={handleNewTask}
            onPrint={handlePrint}
            onExport={handleExport}
            rowHeight={46}
            headerRowHeight={70}
            groupBy={["vehicleId"]}
            setCustomGroup={setCustomGroup}
            setCustomRowClass={setCustomRowClass}
            defaultFiltersSelected={defaultFiltersSelected}
            onScroll={handleScroll}
            storeFilters={setStoreFilters}
            setGroupByFilter={setGroupByFilter}
        />
    );
};

export default TaskListPage;
