import React, {createContext, useReducer} from 'react';
import IncidentsReducer from './reducer';

const IncidentsContext = createContext({});

const IncidentsProvider = ({children}) => {
    const useIncidentsState = useReducer(
        IncidentsReducer,
        {
            // list page
            views: [
                { text: 'Incidents', id: '1', data: {dueTimes: [], dateRange: {}, search: ''} },
                { text: 'Tier Infractions', id: '2', data: {dueTimes: [], dateRange: {}, search: 'tier'} },
                { text: 'Other', id: '3', data: {dueTimes: ['24h'], dateRange: {}, search: 'other'} },
            ],
            currentView: { text: 'Incidents', id: '1', data: {dueTimes: [], dateRange: {}, search: ''} },
            filters: {userStatus:'5',incidentType:'0'},
            filterData: {
                stations: [],
                incidentTypes: [{ name: 'All', value: '0',selected:true}],
                dueTimes: [
                    {name: 'Draft', value: 'draft'},
                    {name: 'Past Due - Late', value: 'late'},
                    {name: 'Now', value: 'now'},
                    {name: 'Next 24h', value: '24h'},
                    {name: 'Later', value: 'later'},
                ],
            },
            sortBy: [
                {
                    field: "due",
                    direction: 1,
                }
            ],
            incidents: [],
            incidentData: [],
            // form view
            incident: undefined,
            incidentTypeId: undefined,

            // common
            loaded: false,
            page: 0,
            totalIncidents: 0,
            canDelete: false
        }
    );

    return (
        <IncidentsContext.Provider value={useIncidentsState}>
            {children}
        </IncidentsContext.Provider>
    )
};

export {IncidentsProvider};
export default IncidentsContext;
