import axios from "axios";
import { getCompanyId } from "../../Auth";
import { engine } from "../../Utilities/index";
import { DriverSchemaInclude, IncidentTypeSchema } from "./schema";
import moment from "moment";
// import { alert } from "../../components/Alert";

const CancelToken = axios.CancelToken;
let canceler;
let cancelerFetchFormOptions;

const API = {
    current: undefined,

    call(params, endpoint = "/api/lazy/manage/data" , cancelToken = null) {
        API.current = engine()
            .post(endpoint, params, {
                cancelToken: (cancelToken != null) ? cancelToken : new CancelToken(function executor(c) {
                    canceler = c;
                }),
            })
            .finally(() => (API.current = undefined));

        return API.current;
    },

    cancel() {
        if (canceler) {
            canceler('Canceling the request');
        }
        else return false;
    },

    fetchList(params = {}) {
        return API.call({
            actions: {
                response: {
                    Incident: {
                        custom: {
                            functionName: "getIncidentByCompany",
                            get: "incident",
                            criteria: {
                                company: getCompanyId(),
                                timezoneOffset: new Date().getTimezoneOffset(),
                                ...params,
                            },
                        },
                    },
                },
            },
        });
    },

    getIncidentPastHistoryData(type, id, stationId, incidentId, token, reasons, driverId) {
        return API.call({
            actions: {
                response: {
                    Incident: {
                        custom: {
                            functionName: "getIncidentPastHistoryData",
                            get: "incident",
                            criteria: {
                                companyId: getCompanyId(),
                                type: type,
                                id: id,
                                stationId: stationId,
                                incidentId: incidentId,
                                token: token,
                                reasons: reasons,
                                driverId: driverId,
                            },
                        },
                    },
                },
            },
        });
    },

    archiveIncident(incidentId) {
        return API.call({
            actions: {
                response: {
                    Incident: {
                        custom: {
                            functionName: "removeIncidentById",
                            get: "incident",
                            criteria: {
                                id: incidentId,
                            },
                        },
                    },
                },
            },
        });
    },

    fetchFormOptions({ station, driver, date, driverRoute }) {
        let params = {
            actions: {
                response: {
                    IncidentType: {
                        custom: {
                            functionName: "getIncidentTypeByCompany",
                            get: "incidentTypes",
                            criteria: {
                                company: getCompanyId(),
                                isInWeb: true,
                            },
                            includes: Object.keys(IncidentTypeSchema),
                            orderBy: {
                                name: "ASC",
                            },
                        },
                    },
                },
            },
        };

        if (station) {
            params.actions.response.Driver = {
                custom: {
                    get: "drivers",
                    functionName: "getDrivers",
                    criteria: {
                        station: station,
                    },
                    includes: DriverSchemaInclude,
                },
            };
            params.actions.response.Station = {
                findBy: {
                    criteria: {"id":station},
                    get: "stationTimezone",
                    includes: ["timezone"],
                },
            };
        }

        if (station && driver && date) {
            params.actions.response.DriverRoute = {
                custom: {
                    get: "driverRoutes",
                    functionName: "getDriverRouteForIncident",
                    criteria: {
                        station: station,
                        driver: driver,
                        dateCreated: moment(date).format("YYYY-MM-DD"),
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                    includes: {
                        0: "id",
                        1: "startTime",
                        2: "endTime",
                        shiftType: ["name", "color", "textColor"],
                    },
                },
            };
        } else if (driverRoute) {
            params.actions.response.DriverRoute = {
                custom: {
                    get: "driverRoutes",
                    functionName: "getDriverRouteForIncident",
                    criteria: {
                        station: station,
                        driver: driver,
                        dateCreated: moment(date).format("YYYY-MM-DD"),
                        driverRoute: driverRoute,
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                    includes: {
                        0: "id",
                        1: "startTime",
                        2: "endTime",
                        shiftType: ["name", "color", "textColor"],
                    },
                },
            };
        }

        return API.call(params,"/api/lazy/manage/data",new CancelToken(function executor(c) {
            cancelerFetchFormOptions = c;
        }));
    },
    cancelFetchFormOptions() {
        if (cancelerFetchFormOptions) {
            cancelerFetchFormOptions('Canceling the request of fetchFormOptions');
        }
        else return false;
    },

    getDroprouteIncidentType(params) {
        return API.call({
            actions: {
                response: {
                    IncidentType: {
                        custom: {
                            functionName: "getIncidentTypeByCompany",
                            get: "incidentTypes",
                            criteria: {
                                company: getCompanyId(),
                                isInWeb: true,
                            },
                            includes: ["id", "token"],
                            orderBy: {
                                name: "ASC",
                            },
                        },
                    },
                },
            },
        });
    },

    startIncident(params) {
        let dateTime = moment.utc(params.date).format("YYYY-MM-DD") + " " + params.time + ":00";

        return API.call({
            actions: {
                response: {
                    Incident: {
                        custom: {
                            functionName: "addReportIncident",
                            get: "result",
                            criteria: {
                                company: getCompanyId(),
                                station: params.station,
                                driver: params.driver,
                                incidentType: params.incidentType,
                                dateTime: dateTime,
                                driverRoute: params.driverRoute,
                                createdDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                updatedDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                            },
                        },
                    },
                },
            },
        });
    },

    updateIncident(dateTime, id) {
        return API.call({
            actions: {
                response: {
                    Incident: {
                        custom: {
                            functionName: "updateReportIncident",
                            get: "result",
                            criteria: {
                                id: id,
                                dateTime: dateTime,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                        },
                    },
                },
            },
        });
    },

    fetchIncidentParams(id) {
        return {
            actions: {
                response: {
                    IncidentPhase: {
                        custom: {
                            functionName: "getIncidentData",
                            get: "result",
                            criteria: {
                                company: getCompanyId(),
                                incidentId: id,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                        },
                    },
                },
            },
        };
    },

    fetchIncidentTimelineParams(id) {
        return {
            actions: {
                response: {
                    IncidentSubmitedPhase: {
                        custom: {
                            functionName: "fetchIncidentTimeline",
                            get: "incidentSubmittedPhase",
                            criteria: {
                                incident: id,
                            },
                            includes: {
                                0: "id",
                                1: "submittedAt",
                                incidentPhase: ["title"],
                                submitedBy: ["friendlyName"],
                            },
                        },
                    },
                },
            },
        };
    },

    fetchIncident({ incId, phase = 0, timeline = false }) {
        let id = incId;
        let params = API.fetchIncidentParams(id);

        if (timeline)
            params.actions.response = {
                ...params.actions.response,
                ...API.fetchIncidentTimelineParams(id).actions.response,
            };

        return API.call(params);
    },

    fetchIncidentTimeline(id) {
        return API.call(API.fetchIncidentTimelineParams(id));
    },

    fetchDynamicOptions(fieldId, value) {
        return API.call({
            actions: {
                response: {
                    IncidentPhase: {
                        custom: {
                            functionName: "getDynamicOptions",
                            get: "result",
                            criteria: {
                                id: fieldId,
                                conditionParameter: value,
                            },
                        },
                    },
                },
            },
        });
    },
    savePhase(phase, incidentId, token, triggers, save, isCreateCorrectiveAction) {
        return API.call({
            actions: {
                response: {
                    IncidentPhase: {
                        custom: {
                            functionName: "savePhase",
                            get: "result",
                            criteria: {
                                incidentId: incidentId,
                                data: phase,
                                save: save,
                                isCreateCorrectiveAction: isCreateCorrectiveAction
                            },
                        },
                    },
                },
            },
            workflow: [
                {
                    token: token,
                    category: "incident",
                    incidentId: incidentId,
                    triggers: triggers,
                },
            ],
        });
    },
    checkChildIncident(incidentId) {
        return API.call({
            actions: {
                response: {
                    Incident: {
                        findBy: {
                            get: "result",
                            criteria: {
                                company: getCompanyId(),
                                parentIncident: incidentId,
                            },
                            includes: {
                                0: "id",
                            },
                        },
                    },
                },
            },
        });
    },
    checkDropTypeInStation(incidentId, stationId, incidentDate, type) {
        return API.call({
            actions: {
                response: {
                    Incident: {
                        custom: {
                            functionName: "checkDropTypeInStation",
                            get: "result",
                            criteria: {
                                incidentId: incidentId,
                                stationId: stationId,
                                type: type,
                                incidentDate: incidentDate,
                            },
                        },
                    },
                },
            },
        });
    },

    printPhaseDetail(incidentId, token, triggers) {
        return API.call({
            actions: {
                response: {
                    IncidentPhase: {
                        custom: {
                            functionName: "printPhaseDetail",
                            get: "result",
                            criteria: {
                                incidentId: incidentId,
                                token: token,
                            },
                        },
                    },
                },
            },
            workflow: [
                {
                    token: token,
                    category: "incident",
                    incidentId: incidentId,
                    triggers: triggers,
                },
            ],
        });
    },

    updateSavePhase(phase, incidentId, token, triggers, isCreateCorrectiveAction) {
        return API.call({
            actions: {
                response: {
                    IncidentPhase: {
                        custom: {
                            functionName: "editPhase",
                            get: "result",
                            criteria: {
                                incidentId: incidentId,
                                data: phase,
                                isCreateCorrectiveAction: isCreateCorrectiveAction,
                            },
                        },
                    },
                },
            },
            workflow: [
                {
                    token: token,
                    category: "incident",
                    incidentId: incidentId,
                    triggers: triggers,
                },
            ],
        });
    },

    incidentDroprouteAutofill(incidentId) {
        return API.call({
            actions: {
                response: {
                    IncidentPhase: {
                        custom: {
                            functionName: "incidentDroprouteSaveFirsttime",
                            get: "result",
                            criteria: { incidentId: incidentId },
                        },
                    },
                },
            },
        });
    },

    incidentPickuprouteAutofill(incidentId) {
        return API.call({
            actions: {
                response: {
                    IncidentPhase: {
                        custom: {
                            functionName: "incidentPickuprouteSaveFirsttime",
                            get: "result",
                            criteria: { incidentId: incidentId },
                        },
                    },
                },
            },
        });
    },

    incidentDynamicFunction(entity, functionName, criteria) {
        return API.call({
            actions: {
                response: {
                    [entity]: {
                        custom: {
                            functionName: functionName,
                            get: "result",
                            criteria: criteria,
                        },
                    },
                },
            },
        });
    },

    checkDropOrPickupInStation(station, inputDate) {
        return API.call({
            actions: {
                response: {
                    Incident: {
                        custom: {
                            functionName: "checkDropOrPickupInStation",
                            get: "result",
                            criteria: {
                                station: station,
                                date: inputDate,
                            },
                        },
                    },
                },
            },
        });
    },
};

export default API;
